import React, { useState } from "react";
import './CardDropdown.css';

/**
 * @author Paula Melo
 * @description Componente que presenta una tarjeta desplegable hacia abajo para mostrar informacion asociada.
 * @param int id - Identificador que se asocia al elemento renderizado
 * @param string name - Titulo de la tarjeta
 * @param children children 
 * @param Array options - Arreglo de objetos para presentar el desplegable de opciones
 * @since 2024-08-23
 * @version 1.1.0
 * @returns 
 */

const CardDropdown = ({ id, name, children, options }) => {
    const [iconSeleccionado, setIconSeleccionado] = useState(false);

    const handleIconClick = () => {
        setIconSeleccionado(!iconSeleccionado);
    };

    return (
        <div className="component-container">
            <div className={`carddropdown-card-fluid ${iconSeleccionado ? 'colorCardServices' : ''}`}>
                <div className="carddropdown-title">
                    <span className={`${iconSeleccionado ? 'selectedText' : ''}`} style={{ fontWeight: 400, fontSize: 18 }} >{name}</span>
                </div>
                <div className="carddropdown-buttons-container">
                    <div className={`carddropdown-icon-ellipsis ${iconSeleccionado ? 'carddropdown-icon-ellipsisBg' : ''}`} data-bs-toggle="dropdown" aria-expanded="false" >
                        <i className="fa-solid fa-ellipsis"></i>
                        <ul className="dropdown-menu carddropdown-optionsgroup" >
                            {options.map((item) => (
                                (
                                    <>
                                        <li className="carddropdown-item">
                                            <button className="btn d-flex justify-content-center align-items-center" title="crear" onClick={() => item.handleFunction(id)}>
                                                <span className="dropdown-item-icon" style={item.color && {color: item.color}}><i className={`fa-regular ${item.icon}`}></i></span>
                                                <span className="dropdown-item-title" style={item.color && {color: item.color}}>{item.name}</span>
                                            </button>
                                        </li>

                                    </>
                                )
                            ))
                            }
                        </ul>
                    </div>

                    <div className="carddropdown-chevron iconCardChevron" style={{ backgroundColor: iconSeleccionado ? '#F0B669' : '' }} onClick={handleIconClick}>
                        <i className="fa-solid fa-chevron-right" style={{ transform: iconSeleccionado ? 'rotate(90deg)' : 'none' }}></i>
                    </div>
                </div>
            </div>
            {/* Lista secundaria (renderizada) */}

            <div className={`subContainer-cardDropDown ${iconSeleccionado ? 'show' : 'hide'}`}>
                
                {children}
                
            </div>
        </div>


    );
};
export default CardDropdown;