import './BusinessClosedPeriods.css'
import RightModal from '../../atoms/RightModal/RightModal';
import ButtonPrimary from '../../atoms/Buttons/ButtonPrimary';
import CardEditAndDelete from '../../atoms/Cards/CardEditAndDelete';
import emptyState from '../../../emptyState';
import { useState } from 'react'
import { useTranslation } from 'react-i18next';
import EmptyState from '../../Migrar/componentes/emptyState/EmptyState';

const BusinessClosedPeriods = () => {
    const [t] = useTranslation("global");
    const [data, setData] = useState([
        // {
        //     id: 1,
        //     title: "Card 1",
        //     subtitle: "Subtitle 1"
        // },
        // {
        //     id: 2,
        //     title: "Card 2",
        //     subtitle: "Subtitle 2"
        // },
        // {
        //     id: 3,
        //     title: "Card 3",
        //     subtitle: "Subtitle 3"
        // }
    ]);
    const [openModalEdit, setOpenModalEdit] = useState(false);
    const [openModalDelete, setOpenModalDelete] = useState(false);

    return (
        <div className="app container">
            <div className="d-flex justify-content-between">
                <section className="section-title-page">
                    <h3 className="title-page">{t('closedPeriods.title')}</h3>
                    <p className="light">{t('closedPeriods.subtitle')}</p>
                </section>
                <div className="button-add">
                    <ButtonPrimary
                        label={t('closedPeriods.addNew')}
                        onClick={() => { }}
                    />
                </div>
            </div>
            <div className="row">
                {
                    data.length > 0 ?
                        data.map((item, index) => 
                            <CardEditAndDelete
                                key={index}
                                title={item.title} 
                                text={'item.text'}
                                subtitle={item.subtitle}
                                navigate={"customers"} 
                                showButtonEdit={true}
                                showButtonDelete={true}
                                onFunctionEdit={() => setOpenModalEdit(true)}
                                onFunctionDelete={() => setOpenModalDelete(true)}
                                ButtonText1={t("permissions.edit")} 
                                ButtonText2={t("permissions.delete")}
                            />
                        )
                        :
                        <EmptyState
                            title=""
                            text={t('closedPeriods.emptyText')}
                            buttonText={t("closedPeriods.addClosedPeriod")}
                            ImageSource={emptyState.closedPeriods}
                            onPressAction={() => setOpenModalEdit(true)}
                            icon={"fa-plus"}
                        />
                }
                <RightModal
                    visible={openModalEdit}
                    title={t("closedPeriods.editClosedPeriod")}
                    setOptionsVisible={setOpenModalEdit}
                    //TODO: Poner body y footer del modal. Verificar que cambie segun edición y creación (se crea en otra tarea, esperar el merge)
                />
            </div>
        </div>
    )
}

export default BusinessClosedPeriods
