import { AppContext } from "../../../../context/AppContext";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import { useFetch } from "../../../../hooks/useFecth";
import { useTranslation } from "react-i18next";
import HandlerApp from "../../../../utils/handlerApp";
import ButtonLink from "../../../atoms/Buttons/ButtonLink";
import ViewField from "../../../Migrar/componentes/ViewField";
import ButtonOutline from "../../../atoms/Buttons/ButtonOutline";
import AppointmentStatus from "../../../atoms/timeLine/AppointmentStatus";


const AppointmentDetailScreen = () => {
    const api                       = useFetch();
    const { appoinmentId: id }      = useParams();
    const handlerApp                = HandlerApp();
    const nav                       = useNavigate();
    const [t]                       = useTranslation("global");
    const endpoint                  = `customers/${id}/appointments/detail`;
    const { app } = useContext(AppContext);

    // permisos
    const [permissions, setPermissions] = useState([]);
    const [data, setData] = useState([]);

    useEffect(() => {
        setPermissions(app.permissions[endpoint])
    }, [app])
    
    useEffect(() => {
        sendRequest();
    }, [])
    const handleCancel = (e) => {
        e.preventDefault();
        nav(-1);
    }

    const sendRequest = () => {
        handlerApp.setLoader(true);
        api.get(endpoint)
        .then(response => {
            if (response.success) {
                setData(response.data);
                console.log(response.data);
                handlerApp.setLoader(false);
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    return (
        data[0]&&
        <div className="app container">
            <div className="d-flex justify-content-between">
                <section className="section-title-page d-flex align-items-center">
                    <ButtonLink label={<i className="fa-regular fa-arrow-left"></i>} onClick={handleCancel}/>
                    <h3 className="title-page ps-3">{t('appointments.detail')}</h3>
                </section>
                <div className="d-flex">
                        {/* TODO: Poner función */}
                    <ButtonOutline label={t('appointments.refound')} onClick={() => { }} /> 
                </div>
            </div>
            <div className="container bold-container-content mt-4">
                <div className="col-9 d-flex">
                    <div className="col-md-6">
                        <div className="col-md-11 mb-3">
                            {/* TODO: Validar si se debe leer así */}
                            <ViewField label={t("appointments.service")} value={data[0]?.services[0].bookingdetail_concept} /> 
                        </div>

                        <div className="col-md-11 mb-3">
                            <ViewField label={t("appointments.user")} value={data[0]?.customer_name} />
                        </div>
                        <div className="col-md-11">
                            <ViewField label={t("appointments.date")} value={data[0]?.day} />
                        </div>
                        <div className="col-md-11">
                            <ViewField label={t("appointments.time")} value={data[0]?.time} />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="col-md-12 mb-3">
                            <ViewField label={t("appointments.value")} value={handlerApp.formatCurrency(data[0]?.booking_total)} /> 
                        </div>

                        <div className="col-md-12 mb-3">
                            <ViewField label={t("appointments.payment")} value={data[0]?.booking_payment_method} />
                        </div>
                        <div className="col-md-12">
                            {/* TODO: mostrar desde donde viene */}
                            <ViewField label={t("appointments.from")} value={'Instagram'} /> 
                        </div>
                        <div className="col-md-12">
                            {/* TODO: Consumir el numero real */}
                            <ViewField label={t("appointments.card")} value={'**** **** **** 6789'} /> 
                        </div>
                    </div>
                </div>
                
                <AppointmentStatus
                    states={data[0]?.time_line} 
                />
            </div>
        </div>
    )
}

export default AppointmentDetailScreen;