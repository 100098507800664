import './UploadGallery.css';
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

/**
 * @author Daniel Bolivar
 * @version 1.0.0
 * @since 19-08-2024
 * @param {String} string - name nombre de campo
 * @param {Array} Array - value valor por default.
 * @param {Callback} callback - setValue funcion que permite setear el valor del campo en el formulario
 * @param {String} string - title, mensaje que se muestra como titulo.
 * @param {integer} int - limite de ficheros que pueden ser cargados.
 * @returns 
 */
const UploadGallery = ({name, value, setValue, title, limit})=>{
    const [ t ]                 = useTranslation('global');
    const inputFile             = useRef();
    const [images, setImages]   = useState([]);

    const len = useMemo(() => images.length, [images]);

    useEffect(()=>{
        const imagesValue = value.map(img => ({ name: img.image_name, src: img.image_url }));
        setImages(imagesValue);
    },[value])

    useEffect(()=>{
        setValue(name, images);
    },[images]);

    const handleClickAttach = useCallback((e) => {
        e.preventDefault();
        inputFile.current.click();
    }, []);

    const handleUpload = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        const filesImages = e.target.files;
        if (filesImages.length + images.length > limit) {
            Swal.fire({ title: '', text: t("0090"), icon: 'error' });
            return;
        }
        processImages(filesImages);
    }, [images]);

    const processImages = async (filesImages) => {
        let newImages = [...images];
        const imagePromises = [];

        for (let i = 0; i < filesImages.length; i++) {
            const image = filesImages[i];
            imagePromises.push(buildImage(image, image.name));
        }

        try {
            const processedImages = await Promise.all(imagePromises);
            setImages([...newImages, ...processedImages]);
        } catch (error) {
            Swal.fire({ title: '', text: t("0091"), icon: 'error' });
        }
    };

    const buildImage = (image, name) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            const picture = new Image();

            fileReader.onload = () => {
                picture.src = fileReader.result;
            };

            fileReader.onerror = reject;
            fileReader.readAsDataURL(image);

            picture.onload = () => {
                // Aquí podemos ajustar el tamaño de la imagen (compresión)
                const canvas = document.createElement('canvas');
                const maxWidth = 800; // Definir un tamaño máximo
                const scaleSize = maxWidth / picture.width;
                canvas.width = maxWidth;
                canvas.height = picture.height * scaleSize;

                const ctx = canvas.getContext('2d');
                ctx.drawImage(picture, 0, 0, canvas.width, canvas.height);

                const compressedImage = canvas.toDataURL('image/jpeg', 0.7); // Comprimir al 70% calidad JPEG
                resolve({ name, src: compressedImage });
            };
        });
    };

    const handleDeleteImage = useCallback((key) => {
        setImages((prevImages) => prevImages.filter((_, index) => index !== key));
    }, []);

    return(
        <section className="upload-gallery-container">
            <h5 className="upload-gallery-title">{title} - {len}/{limit}</h5>
            <div className="section-uploads">
                {
                    (images.length < 5) &&
                    <div className="upload-gallery-item-void" onClick={handleClickAttach}>
                        <i className="fa-solid fa-plus text"></i>
                    </div>
                }
                {
                    images.map((image, key) => {
                        return <div key={key} className="upload-gallery-item">
                                <img src={image.src} alt={image.name}/>
                                <span className="delete-image" onClick={()=>{handleDeleteImage(key)}}>
                                    <i className="fa-regular fa-trash"></i>
                                </span>
                            </div>
                        }
                    )
                }
            </div>
            <input type="file" name="files[]" ref={inputFile} onChange={handleUpload} hidden multiple accept="image/*"/>
        </section>
    )

}
export default UploadGallery;