import { useEffect, useState, useContext } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import { useFetch } from "../../../hooks/useFecth";
import HandlerApp from "../../../utils/handlerApp";
import { useTranslation } from "react-i18next";
import BoldSelect from "../../../utils/BoldSelect";
import { LocationContext } from "../../../context/LocationContext";
import Header from "../../molecules/Header";
import CardDropdown from "../../atoms/Cards/DropdownCard/CardDropdown";
import InfoClient from "../../Migrar/componentes/InfoClient";
import CentralModal from "../../atoms/CentralModal/CentralModal";
import CentralModalOptions from "../../atoms/CentralModalOptions/CentralModalOptions";
import CategoriesCard from "../../atoms/Cards/Categories/CategoriesCard";
import ButtonPrimary from "../../atoms/Buttons/ButtonPrimary";
import ButtonOutline from "../../atoms/Buttons/ButtonOutline";

/**
 * @author Paula Melo
 * @since 2024-09-09
 * @description Formulario para listar categorias de servicios y ver la categoria seleccionada en un modal. Opciones para ir a editar y eliminar categorias.
 * @version 1.0.0
 * @returns 
 */
const ServicesCategories = () => {
    const endpoint = "services/categories";
    const parent = "Services";
    const [t] = useTranslation("global");
    const title = t('servicesCategories.servicesCategory');
    const prefix = "servicecategory";
    const api = useFetch();
    const nav = useNavigate();
    const handlerApp = HandlerApp();

    const { app } = useContext(AppContext);
    const { location } = useContext(LocationContext);

    const [data, setData] = useState([]);
    const [dataByid, setDataByid] = useState([]);
    const [selectedId, setSelectedId] =useState(0);
    const [menus, setMenus] = useState([]);
    const [dataFilter, setDataFilter] = useState([]);
    const [categoriesInShow, setCategoriesInShow] = useState([]);
    const [permissions, setPermissions] = useState([]);
    const [filterText, setFilterText] = useState('');
    const [showView, setShowView] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [filters, setFilters] = useState({
        location: [],
        type: []
    });

    const options = [
        {
            "name": t("servicesCategories.viewServicesCategory"),
            "icon": "fa-regular fa-eye",
            "handleFunction": (id)=>handleShowView(id)
        },
        {
            "name": t("servicesCategories.updateServicesCategory"),
            "icon": "fa-solid fa-pen",
            "handleFunction": (id)=>{nav("/"+endpoint+"/edit/"+id);}
        },
        {
            "name": t("servicesCategories.deleteServicesCategory"),
            "icon": "fa-solid fa-trash",
            "color": "red",
            "handleFunction": (id)=>selectForDelete(id)
        }
    ];
    
    useEffect(() => {
        sendRequest();
    }, [])
    useEffect(() => {
        setDataFilter(data);
    }, [data])

    useEffect(() => {
        setMenus(app.menus[parent]);
        setPermissions(app.permissions[endpoint])
    }, [app])

    useEffect(() => {
        handleFilter();
    }, [filterText])

    const deleteRow = (id) => {
        handlerApp.setLoader(true);
        api.deleteMethod(endpoint + '/' + id)
        .then(response => {
            if (response.success) {
                handlerApp.showOk(response);
                sendRequest();
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const sendRequest = () => {
        handlerApp.setLoader(true);
        api.get(endpoint)
        .then(response => {
            if (response.success) {
                setFilters({
                    ...filters,
                    type: handlerApp.getFiltersFromData(response.data, 'servicecategory_name'),
                    location: handlerApp.getFiltersFromData(response.data, 'location_name')
                });
                setData(response.data);
                setDataFilter(response.data);
                handlerApp.setLoader(false);
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const handleShowView = (id)=>{
        const myDataByid = data.find(data =>data.servicecategory_id === id);
        setDataByid(myDataByid);
        setShowView(true);
    }
    const selectForDelete = (id)=>{
        if(showView){
            handlerApp.setLoader(true);
            setShowView(false);
        }
        setOpenModal(true);
        setSelectedId(id);
    }
    const handleDelete = (id)=>{
        setOpenModal(false);
        deleteRow(id);
    }
    
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const filteredItems = data?.filter(
        item => item.servicecategory_id && item.servicecategory_id.toString().toLowerCase().includes(filterText.toLowerCase())
            || item.servicecategory_name.toLowerCase().includes(filterText.toLowerCase())
            || item.servicecategory_name_spanish.toLowerCase().includes(filterText.toLowerCase())
            || item.location_name.toLowerCase().includes(filterText.toLowerCase())
            || item.servicecategory_num_products?.toLowerCase().includes(filterText.toLowerCase())
    );

    const handleFilterDataLocation = (value) => {
        const filteredData = data.filter(item => {
            return item.location_name.toLowerCase().includes(value.toLowerCase());
        });
        setDataFilter(filteredData);
    }

    const handleFilterDataCompany = (value) => {
        const filteredData = data.filter(item => {
            return item.servicecategory_name.toLowerCase().includes(value.toLowerCase());
        });
        setDataFilter(filteredData);
    }

    const handleFilter = (value) => {
        const filteredData = data.filter(item => {
            // Filtrar por servicecategory_name o cualquier otro campo que desees
            return item.servicecategory_name.toLowerCase().includes(filterText.toLowerCase());
          });
        setDataFilter(filteredData);
    }

    return (
        <>
        <CentralModalOptions 
            key={dataByid.servicecategory_id}
            id={dataByid.servicecategory_id}
            title={t("servicesCategories.viewServicesCategory")}
            visible={showView}
            setOptionsVisible={setShowView}
            options={options.slice(1,3)}
        >
            <div className="row p-2">
                <div className="w-100 rounded p-3 info-detail mb-3 background-1">
                    <InfoClient 
                        title={t("name")}
                        value={dataByid.servicecategory_name}
                    />
                </div>
                <div className="w-100 rounded p-3 info-detail mb-3 background-1">
                    <InfoClient 
                        title={t("description")}
                        value={dataByid.servicecategory_description}
                    />
                </div>
                <h5>{t("details")}:</h5>
                <div className="w-100 rounded p-3 info-detail mb-3 background-1">
                    <InfoClient 
                        icon="fa-regular fa-eye"
                        title={t("servicesCategories.visibility")}
                        value={(dataByid.servicecategory_visibility)? t("public") : t("private")}
                    />
                </div>
            </div>
        </CentralModalOptions>
        <CentralModal 
            title={t("servicesCategories.deleteConfirmationTitle")}
            visible={openModal}
            setOptionsVisible={setOpenModal}
            body={t("servicesCategories.deleteConfirmationDescription")}
            footer={<div className="modalfooter-buttons w-100">
                <ButtonOutline label={t("nocancel")} onClick={()=>setOpenModal(false)}/>
                <ButtonPrimary label={t("yesdelete")} onClick={()=>handleDelete(selectedId)}/>
            </div>}
        />
        <div className="app container">
            {/* <!-- titulo --> */}
            <Header parent={parent} endpoint={endpoint} title={title} description={t('servicesCategories.description')}/>
            {/* <!-- Filtros --> */}
            <section className="filters">
                <div className="d-flex align-items-center my-4"> 
                    <h4>{t("servicesCategories.list")}</h4>
                    <span className="screen-count-elements mx-3">{dataFilter.length} categories</span>
                </div>
                <div className="row">
                    <div className="col-12 col-md-3">
                        <BoldSelect title={t("locationComponent.location")} name="location_name" onChange={e => handleFilterDataLocation(e.target.value)} value="" options={filters.location}></BoldSelect>
                    </div>
                    <div className="col-12 offset-md-3 col-md-6 d-flex align-items-start">
                        <div className="input-icon input-search mx-2">
                            <input type="text" onChange={e => setFilterText(e.target.value)} value={filterText} placeholder={t("search")}></input>
                            {filterText !== "" && <span className="material-symbols-outlined clear" onClick={e => { setFilterText("") }}>cancel</span>}
                            <span className="material-symbols-outlined">search</span>
                        </div>
                        <div className="col-md-2">
                            <ButtonPrimary 
                                label={t("search")}
                            />
                        </div>
                    </div>
                </div>
            </section>

            <div>
                { data.length !== 0 ?
                    data.map((item, index) =>{
                        let servicesInCategory = item.services;
                        if(servicesInCategory.length !== 0){
                            return(
                                // <div style={index !== dataFilter.length && {zIndex:index-dataFilter.length, position:"relative"}}>
                                <div>
                                    <CardDropdown
                                        key={index}
                                        id={item.servicecategory_id}
                                        name={item.servicecategory_name}
                                        options={options}
                                    >
                                        <div className="p-2">
                                        {
                                            servicesInCategory.map((service, index) =>(
                                                //TODO: Revisar que esta ruta sea correcta para ver servicios
                                                <CategoriesCard 
                                                    key={service.service_id}
                                                    icon={service.service_picture !== '' ? service.service_picture : 'fa-pen'}
                                                    title={service.service_name} 
                                                    navigate={"/services/"+service.service_id}
                                                />
                                            ))
                                        }
                                        </div>
                                    </CardDropdown>
                                </div>
                            )
                        }
                    })
                    :
                    //TODO: Pendiente por implementar emptystate
                    <p>Espacio para el empty state</p>
                }
            </div>
        </div>
        </>
    )
}

export default ServicesCategories;