import "./Customers.css";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import CardInfo from "../../componentes/CardInfo";
import ViewField from "../../componentes/ViewField";
import PropTypes from 'prop-types';
import EmptyState from "../../componentes/emptyState/EmptyState";
import HandlerApp from "../../../../utils/handlerApp";
import SimpleList from "../../../atoms/SimpleList/SimpleList";
import Typography from '@mui/material/Typography';
import RightModal from "../../../atoms/RightModal/RightModal";
import NavLinkView from "../../componentes/navbar/NavLinkView";
import CentralModal from "../../../atoms/CentralModal/CentralModal";
import BodyNoteModal from "../../../atoms/RightModal/BodyNoteModal";
import ButtonPrimary from "../../../atoms/Buttons/ButtonPrimary";
import ButtonOutline from "../../../atoms/Buttons/ButtonOutline";
import ViewMultiField from "../../componentes/ViewMultiField" 
import HeaderViewScreen from "../../HeaderScreen/HeaderViewScreen";
import CardWithButtonRight from "../../../atoms/Cards/Customer/Giftcard/CardWithButtonRight";
import BodyBlockClientModal from "../../../atoms/RightModal/BodyBlockClientModal";
import BodyActiveClientModal from "../../../atoms/RightModal/BodyActiveClientModal";
import BodyDeleteClientModal from "../../../atoms/RightModal/BodyDeleteClientModal";
import BodyReportAttitudModal from "../../../atoms/RightModal/BodyReportActtitudModal";
import BodyInactiveClientModal from "../../../atoms/RightModal/BodyInactiveClientModal";
import { useFetch } from "../../../../hooks/useFecth";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BodyUnlockClientModal from "../../../atoms/RightModal/BodyUnlockClientModal";

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const CustomerOverviewView = ()=>{
    const [t]           = useTranslation("global");
    const title         = t("customers.viewClient")
    const endpoint      = 'customers';
    const nav           = useNavigate();
    const api           = useFetch();
    const handlerApp    = HandlerApp();

    const params        = useParams();
    const id            = params?.id;

    const [optionsVisible, setOptionsVisible] = useState(false);
    const [modalType, setModalType]           = useState(null);
    const [value, setValues]                  = useState(0);
    const [data, setData]                     = useState([]);
    const [notes, setNotes]                   = useState([]);
    const [allergies, setAllergies]           = useState([]);
    const [medication, setMedication]         = useState([]);

    useEffect(()=>{
        sendRequest();
    }, [])

    const createNote = () => {
        setModalType('createNote');
        setOptionsVisible(true);
    };

    const reportAttitude = () => {
        setModalType('reportAttitude');
        setOptionsVisible(true);
    };

    const handleCreateNote = () => {
        sendRequest();
        setOptionsVisible(false);
    };

    const activeClient = () => {
        setModalType('activeClient');
        setOptionsVisible(true);
    };

    const blockClient = () => {
        setModalType('blockClient');
        setOptionsVisible(true);
    };

    const closeModal = () => {
        setOptionsVisible(false);
    };

    const deleteClient = () => {
        setModalType('deleteClient');
        setOptionsVisible(true);
    };

    const inactiveClient = () => {
        setModalType('inactiveClient');
        setOptionsVisible(true);
    };

    const unlockClient = () => {
        setModalType('unlockClient');
        setOptionsVisible(true);
    };

    const actions = [
        {name: t("customers.actionView.edit"), endpoint: `/${endpoint}/create`, icon: "fa-pen"},
        {name: t("customers.actionView.createNote"), function: createNote, icon: "fa-note-sticky"},
        {name: t("customers.actionView.messageClient"), endpoint: `/${endpoint}/categories/create`, icon: "fa-message"},
        {name: t("customers.actionView.callClient"), endpoint: `/${endpoint}/categories/create`, icon: "fa-phone"},
        {name: t("customers.actionView.createGiftcard"), endpoint: `/${endpoint}/categories/create`, icon: "fa-gift-card"},
        {name: t("customers.actionView.createAppointment"), endpoint: `/${endpoint}/categories/create`, icon: "fa-calendar-plus"},
        {name: t("customers.actionView.createSale"), endpoint: `/${endpoint}/categories/create`, icon: "fa-cash-register"},
        {name: t("customers.actionView.addFamily"), endpoint: `/${endpoint}/${id}/create/known`, icon: "fa-user-plus"},
        {name: t("customers.view.reportAttitude"),  function: reportAttitude, icon: "fa-face-zipper"},
        {name: data.customer_active == 1 ? t("customers.view.inactiveClient") : t("customers.view.activeClient"),  function: data.customer_active == 1 ? inactiveClient : activeClient, icon: "fa-play"},
        {name: t("customers.actionView.delete"), function: deleteClient, icon: "fa-trash", red: true},
        {name: data.customer_blocked == 1 ? t("customers.actionView.unlockClient") : t("customers.actionView.blockClient"), function: data.customer_blocked == 1 ? unlockClient : blockClient, icon: "fa-ban", red: true},
    ];

    const nav_actions = [
        {name: t("menuCustomer.overview"),    to: `/customers/${id}/overview`},
        {name: t("menuCustomer.profile"),     to: `/customers/${id}/profile`},
        {name: t("menuCustomer.appointment"), to: `/customer/appointments/${id}`},
        {name: t("menuCustomer.payment"),     to: `/payment-methods/${id}`},
        {name: t("menuCustomer.products"),    to: `/customers/products/${id}`}
    ];

    const handleChange = (event, newValue) => {
        setValues(newValue);
    };

    const sendRequest = ()=>{
        handlerApp.setLoader(true);
        api.get(`customers/${id}/overview`)
        .then(response => {
            if (response.success) {
                let data = response.data[0];
              
                const allergiesData = data?.customer_notes?.filter(item => item.customernote_type === 'Allergies') || [];
                const medicationData = data?.customer_notes?.filter(item => item.customernote_type === 'Medication')|| [];
                const notasData = data?.customer_notes?.filter(item => item.customernote_type === 'Notes')|| [];

                localStorage.setItem('customer_active',data.customer_active);
                localStorage.setItem('customer_blocked',data.customer_blocked);

                setData(data);
                setNotes(notasData);
                setAllergies(allergiesData);
                setMedication(medicationData);
                handlerApp.setLoader(false);
            } else {
                setData([]);
                setNotes([]);
                setAllergies([]);
                setMedication([]);
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const handleCreateNoteClick = () => {
        const formElement = document.getElementById('createNoteForm');
        if (formElement) {
            formElement.requestSubmit();
        }
    };

    const handleReportAttitudeClick = () => {
        const formElement = document.getElementById('reportAttitudeForm');
        if (formElement) {
            formElement.requestSubmit();
        }
    };

    const handleReportAttitude = async (reportData) => {
        closeModal();
        handlerApp.setLoader(true);
        try {
            const dataToSend = {
                customer_actitude: reportData.customer_actitude
            };
                
            const response = await api.put(`customers/${id}/actitude`, dataToSend);
    
            if (response.success) {
                handlerApp.showOk({ message: t('customers.reportSuccess') });
                sendRequest();
            } else {
                handlerApp.handlerResponse(response); 
            }
        } catch (error) {
            handlerApp.showError(t(error.message)); 
        } finally {
            handlerApp.setLoader(false);
            setOptionsVisible(false); 
        }
    };

    const handleActivateClient = async () => {
        closeModal();
        handlerApp.setLoader(true);
        try {
            const payload = {
                customer_active: 1
            };
    
            const response = await api.put(`customers/${id}/active`, payload);
    
            if (response.success) {
                handlerApp.showOk({ message: t('customers.clientActivated') });
                sendRequest();
            } else {
                handlerApp.handlerResponse(response);
            }
        } catch (error) {
            handlerApp.showError(t(error.message));
        } finally {
            handlerApp.setLoader(false);
            setOptionsVisible(false);
        }
    };

    const handleBlockClient = async (blockData) => {
        closeModal();
        handlerApp.setLoader(true);
        try {
            const dataToSend = {
                customer_blocked: 1,
                ...blockData
            };
    
            const response = await api.put(`customers/${id}/blocked`, dataToSend);
    
            if (response.success) {
                handlerApp.showOk({ message: t('customers.blockSuccess') });
                sendRequest();
            } else {
                handlerApp.handlerResponse(response); 
            }
        } catch (error) {
            handlerApp.showError(t(error.message)); 
        } finally {
            handlerApp.setLoader(false);
            setOptionsVisible(false); 
        }
    };

    const handleActivateClientClick = () => {
        const formElement = document.getElementById('activateClientForm');
        if (formElement) {
            formElement.requestSubmit();
        }
    };

    const handleBlockClientClick = () => {
        const formElement = document.getElementById('blockClientForm');
        if (formElement) {
            formElement.requestSubmit();
        }
    };

    const handleDeleteClient = async () => {
        handlerApp.setLoader(true);
        try {
            const response = await api.deleteMethod(`customers/${id}`);
    
            if (response.success) {
                handlerApp.showOk({ message: t('customers.clientDelete') });
                nav("/" + endpoint);
            } else {
                handlerApp.handlerResponse(response);
            }
        } catch (error) {
            handlerApp.showError(t(error.message));
        } finally {
            handlerApp.setLoader(false);
            setOptionsVisible(false);
        }
    };

    const handleDeleteClientClick = () => {
        const formElement = document.getElementById('deleteClientForm');
        if (formElement) {
            formElement.requestSubmit();
        }
    };

    const handleInactivateClient = async () => {
        closeModal();
        handlerApp.setLoader(true);
        try {
            const payload = {
                customer_active: 0
            };
    
            const response = await api.put(`customers/${id}/active`, payload);
    
            if (response.success) {
                handlerApp.showOk({ message: t('customers.clientInactivated') });
                sendRequest();
            } else {
                handlerApp.handlerResponse(response);
            }
        } catch (error) {
            handlerApp.showError(t(error.message));
        } finally {
            handlerApp.setLoader(false);
            setOptionsVisible(false);
        }
    };

    const handleInactivateClientClick = () => {
        const formElement = document.getElementById('inactiveClientForm');
        if (formElement) {
            formElement.requestSubmit();
        }
    };

    const handleUnlockClient = async () => {
        closeModal();
        handlerApp.setLoader(true);
        try {
            const payload = {
                customer_blocked : 0
            };
    
            const response = await api.put(`customers/${id}/unlock`, payload);
    
            if (response.success) {
                handlerApp.showOk({ message: t('customers.clientUnlock') });
                sendRequest();
            } else {
                handlerApp.handlerResponse(response);
            }
        } catch (error) {
            handlerApp.showError(t(error.message));
        } finally {
            handlerApp.setLoader(false);
            setOptionsVisible(false);
        }
    };

    const handleUnlockClientClick = () => {
        const formElement = document.getElementById('unlockClientForm');
        if (formElement) {
            formElement.requestSubmit();
        }
    };



    return(
        <div className="app container">
            <HeaderViewScreen title={title} actions={actions}/>

            <NavLinkView actions={nav_actions} active={0}/>

            <section className="d-flex row col-12 m-auto">
                <div className="col-12">
                    <div className="col-6 mb-3 mt-3">
                        {data.customer_blocked === 1 && (
                            <ViewMultiField label={t("blockedUserComponent.reasonBlockage")} value={data.customer_blocked_description} />
                        )}
                    </div>
                </div>
                <div className="col-12 row">
                    <div className="col-lg-3 col-md-3 col-sm-5">
                        <CardInfo
                            title={t("customers.totalAppoitmentOverview")}
                            value={data.customer_appointments !== undefined && data.customer_appointments !== NaN ? Math.round(data.customer_appointments) : "0"}
                        />
                    </div>

                    <div className="col-lg-3 col-md-3 col-sm-5">
                        <CardInfo
                            title={t("customers.showRateOverview")}
                            value={data.customer_showrate !== undefined && data.customer_showrate !== NaN ? handlerApp.formatPercent(data.customer_showrate) : "0"}
                        />
                    </div>

                    <div className="col-lg-3 col-md-3 col-sm-5">
                        <CardInfo
                            title={t("customers.overviewFov")}
                            value={data.customer_fov !== undefined && data.customer_fov !== NaN ? Math.round(data.customer_fov) : "0"}
                            description={"Week"}
                        />
                    </div>

                    <div className="col-lg-3 col-md-3 col-sm-5">
                        <CardInfo
                            title={t("customers.overviewLtv")}
                            value={data.customer_ltv !== undefined && data.customer_ltv !== NaN ? handlerApp.formatCurrency(data.customer_ltv) : "0"}
                        />
                    </div>

                    <div className="col-lg-3 col-md-3 col-sm-5">
                        <CardInfo
                            title={t("customers.overviewAvgTicket")}
                            value={data.customer_avg_ticket !== undefined && data.customer_avg_ticket !== NaN ? handlerApp.formatCurrency(data.customer_avg_ticket) : "0"}
                        />
                    </div>

                    <div className="col-lg-3 col-md-3 col-sm-5">
                        <CardInfo
                            title={t("customers.totalNumberTransactionsOverview")}
                            value={data.customer_transactions !== undefined && data.customer_transactions !== NaN ? Math.round(data.customer_transactions) : "0"}
                        />
                    </div>

                    <div className="col-lg-3 col-md-3 col-sm-5">
                        <CardInfo
                            title={t("customers.overViewCompleted")}
                            value={data.customer_completed !== undefined && data.customer_completed !== NaN ? handlerApp.formatPercent(data.customer_completed) : "0"}
                        />
                    </div>

                    <div className="col-lg-3 col-md-3 col-sm-5">
                        <CardInfo
                            title={t("customers.overViewCancelled")}
                            value={data.customer_cancelled !== undefined && data.customer_cancelled !== NaN ? handlerApp.formatPercent(data.customer_cancelled) : "0"}
                        />
                    </div>

                    <div className="col-lg-3 col-md-3 col-sm-5">
                        <CardInfo
                            title={t("customers.noShowOverview")}
                            value={data.customer_no_show !== undefined && data.customer_no_show !== NaN ? handlerApp.formatPercent(data.customer_no_show) : "0"}
                        />
                    </div>

                    <div className="col-lg-3 col-md-3 col-sm-5">
                        <CardInfo
                            title={t("customers.reBookingOverview")}
                            value={data.customer_rebooking !== undefined && data.customer_rebooking !== NaN ? handlerApp.formatPercent(data.customer_rebooking) : "0"}
                        />
                    </div>

                    <div className="col-lg-3 col-md-3 col-sm-5">
                        <CardInfo
                            title={t("customers.timeBookingOverview")}
                            value={data.customer_timebooking !== undefined && data.customer_timebooking !== NaN ? Math.round(data.customer_timebooking) : "0"}
                        />
                    </div>
                </div>
                <div className="col-md-12 mt-3 mb-4">
                    <h5 className="check-title">{t("customers.importantInformation")}</h5>
                </div>
                <div className="col-md-5">
                    <div className="col-md-12 mb-3">
                        <ViewField label={"No-show"} value={data.customer_no_show_quantity !== null ? data.customer_no_show_quantity : 0} />
                    </div>
                    <div className="col-md-12 mb-3">
                        <ViewField label={"Cancel"} value={data.customer_cancelled_quantity !== null ? data.customer_cancelled_quantity : 0} />
                    </div>
                </div>
                
                <div className="row">
                    <div className="col-md-6 mt-3 mb-6">
                        {(
                            data?.booking_next?.length > 0 && 
                            <>
                                <h5 className="check-title">{t("customers.comingAppoitment")}</h5>
                                <CardWithButtonRight
                                    title={data.booking_next?.[0]?.bookingdetail_concept}
                                    image={data.booking_next?.[0]?.user_photo}
                                    textTop={[
                                        <span>{t("calendar.date")}</span>, 
                                        <span className="fw-bold"> {handlerApp.getFormatDate(data.booking_next?.[0]?.booking_datetime)}</span> 
                                    ]}
                                    textBottom={[
                                        <span>{t("userBarbersComponent.barber")}</span>,
                                        <span className="fw-bold"> {data.booking_next?.[0]?.userbarber_fullname}</span>
                                    ]}
                                    status={handlerApp.getStatusClass(data.booking_next[0].booking_state)}
                                    statusValue={data.booking_next[0].booking_state}
                                    handleView={() => { nav(`/customer/appointments/${id}/view/${data.booking_next[0].booking_id}`) }}
                                />
                            </>
                        )}
                    </div>
                    <div className="col-md-6 mt-3 mb-6">
                        {(
                            data?.booking_last?.length > 0 && 
                            <>
                                <h5 className="check-title">{t("customers.lastAppoitment")}</h5>
                                <CardWithButtonRight
                                    title={data.booking_last?.[0]?.bookingdetail_concept}
                                    image={""}
                                    textTop={[
                                        <span className="captionRegular12">{t("calendar.date")}</span>,
                                        <span className="captionSemiBold12"> {handlerApp.getFormatDate(data.booking_last?.[0]?.booking_datetime)}</span>
                                    ]}
                                    textBottom={[
                                        <span className="captionRegular12">{t("userBarbersComponent.barber")}</span>,
                                        <span className="captionSemiBold12"> {data.booking_last?.[0]?.userbarber_fullname}</span>
                                    ]}
                                    status={handlerApp.getStatusClass(data.booking_last[0].booking_state)}
                                    statusValue={data.booking_last[0].booking_state} 
                                    handleView={() => { nav(`/customer/appointments/${id}/view/${data.booking_last[0].booking_id}`) }}
                                />
                            </>
                        )}
                    </div>
                </div>

                {
                    data?.booking_next?.length > 0 || data?.booking_last?.length > 0 &&
                    <div className="col-md-4">
                        <div className="col-md-12 mb-3">
                            <ButtonPrimary label={t("notes.seeMore")} onClick={()=> nav(`/customer/appointments/${id}`)}/>
                        </div>
                    </div>
                }
               
                <div className="col-md-12 col-md-12 mt-3 mb-4">
                    <h5 className="check-title">{t("notes.titleCustomerNotes")}</h5>
                  
                    <Box className="background" sx={{ width: '100%' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                <Tab label={t("notes.title")} {...a11yProps(0)} className="title-notes"/>
                                <Tab label={t("notes.noteMedication")} {...a11yProps(1)} className="title-notes"/>
                                <Tab label={t("notes.noteAllergies")} {...a11yProps(2)} className="title-notes"/>
                            </Tabs>
                        </Box>
                        
                        <CustomTabPanel value={value} index={0}>
                            <Box style={{width:"100%"}}>
                                {
                                    (notes.length > 0) ? 
                                        notes.map((i, index) => (
                                            <SimpleList 
                                                name={i.barber_fullname}
                                                subname={handlerApp.getFormatDate(i.customernote_created_at)}
                                                image={i.user_photo}
                                                text={i.customernote_note}
                                                last={index === data.length -1 }
                                            />
                                        ))
                                        :
                                        <EmptyState
                                            text={t("customers.notAvailableNotes")}
                                            buttonText={t("notes.create")}
                                            ImageSource={"/img/empty/notes.png"}
                                            onPressAction={createNote}
                                            icon={"fa-plus"}
                                        />
                                }
                            </Box>
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={1}>
                            <Box sx={{ width: '100%' }}>
                                {
                                    (medication.length > 0) ? 
                                        medication.map((i, index) => (
                                            <SimpleList 
                                                name={i.barber_fullname}
                                                subname={handlerApp.getFormatDate(i.customernote_created_at)}
                                                image={i.user_photo}
                                                text={i.customernote_note}
                                                last={index === data.length -1 }
                                            />
                                        ))
                                        :
                                        <EmptyState
                                            text={t("customers.notAvailableNotes")}
                                            buttonText={t("notes.create")}
                                            ImageSource={"/img/empty/notes.png"}
                                            onPressAction={createNote}
                                            icon={"fa-plus"}
                                        />
                                }
                            </Box>
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={2}>
                        <Box sx={{ width: '100%' }}>
                                {
                                    (allergies.length > 0) ? 
                                        allergies.map((i, index) => (
                                            <SimpleList 
                                                name={i.barber_fullname}
                                                subname={handlerApp.getFormatDate(i.customernote_created_at)}
                                                image={i.user_photo}
                                                text={i.customernote_note}
                                                last={index === data.length -1 }
                                            />
                                        ))
                                        :
                                        <EmptyState
                                            text={t("customers.notAvailableNotes")}
                                            buttonText={t("notes.create")}
                                            ImageSource={"/img/empty/notes.png"}
                                            onPressAction={createNote}
                                            icon={"fa-plus"}
                                        />
                                }
                            </Box>
                        </CustomTabPanel>
                    </Box>
                </div>
                
                {
                    data?.customer_notes?.length > 0 &&
                    <div className="col-4 col-lg-3 col-sm-4">
                        <ButtonPrimary label={t("notes.create")} onClick={createNote}/>
                    </div>
                }
                
                
            </section>

            {modalType === 'createNote' && (
                <RightModal
                    title={t("customers.actionView.createNote")} 
                    body={<BodyNoteModal onCreate={handleCreateNote} customerId={id}/>} 
                    visible={optionsVisible} 
                    setOptionsVisible={setOptionsVisible} 
                    footer={<ButtonPrimary label={t('create')} onClick={handleCreateNoteClick}/>}
                />
            )}

            {modalType === 'reportAttitude' && (
                <CentralModal
                    title={t("customers.view.reportAttitude")} 
                    body={<BodyReportAttitudModal onSubmit={handleReportAttitude} />} 
                    visible={optionsVisible} 
                    setOptionsVisible={setOptionsVisible} 
                    footer={<ButtonPrimary label={t('report')} onClick={handleReportAttitudeClick} />}
                />
            )}

            {modalType === 'blockClient' && (
                <RightModal 
                    title={t("customers.actionView.blockClient")} 
                    body={<BodyBlockClientModal onSubmit={handleBlockClient} />} 
                    visible={optionsVisible} 
                    setOptionsVisible={setOptionsVisible} 
                    footer={<ButtonPrimary label={t('block')} onClick={handleBlockClientClick}/>}
                />
            )}

            {modalType === 'activeClient' && (
                <CentralModal 
                    title={t("customers.view.activeClient")} 
                    body={<BodyActiveClientModal onSubmit={handleActivateClient} />} 
                    visible={optionsVisible} 
                    setOptionsVisible={setOptionsVisible} 
                    footer={[
                        <ButtonOutline label={t('customers.view.noContinue')} onClick={closeModal}  />,
                        <p className="me-3"></p>,
                        <ButtonPrimary label={t('customers.view.yesActive')} onClick={handleActivateClientClick} />
                    ]}
                />
            )}

            {modalType === 'deleteClient' && (
                <CentralModal 
                    title={t("customers.view.deleteClient")} 
                    body={<BodyDeleteClientModal onSubmit={handleDeleteClient} closeModal={closeModal}/>} 
                    visible={optionsVisible} 
                    setOptionsVisible={setOptionsVisible} 
                    footer={[
                        <ButtonOutline label={t('customers.view.noContinue')} onClick={closeModal}  />,
                        <p className="me-3"></p>,
                        <ButtonPrimary label={t('customers.view.yesDelete')} onClick={handleDeleteClientClick} />
                    ]}
                />
            )}

            {modalType === 'inactiveClient' && (
                <CentralModal 
                    title={t("customers.view.inactiveClient")} 
                    body={<BodyInactiveClientModal onSubmit={handleInactivateClient} />} 
                    visible={optionsVisible} 
                    setOptionsVisible={setOptionsVisible} 
                    footer={[
                        <ButtonOutline label={t('customers.view.noContinue')} onClick={closeModal}  />,
                        <p className="me-3"></p>,
                        <ButtonPrimary label={t('customers.view.yesInactive')} onClick={handleInactivateClientClick} />
                    ]}
                />
            )}

            {modalType === 'unlockClient' && (
                <CentralModal 
                    title={t("customers.actionView.unlockClient")} 
                    body={<BodyUnlockClientModal onSubmit={handleUnlockClient} />} 
                    visible={optionsVisible} 
                    setOptionsVisible={setOptionsVisible} 
                    footer={[
                        <ButtonOutline label={t('customers.view.noContinue')} onClick={closeModal}  />,
                        <p className="me-3"></p>,
                        <ButtonPrimary label={t('customers.view.yesUnlock')} onClick={handleUnlockClientClick} />
                    ]}
                />
            )}
        </div>
    )
}

export default CustomerOverviewView;