

import './NavbarFilter.css';
import HandlerApp from "../../../../utils/handlerApp";
import { NavLink } from "react-router-dom";
import { useState } from "react";

/**
 * @author Victor Duran
 * @since 2024-09-21
 * @description Componente tipo nabvar que me permite filtrar y visualizar información 
 * @param {Object} actions | Opciones que me permiten visualizar el nabvar y realizar el filtro por cada una de ellas
 * @param {Array} setDataFiltered | arreglo de objetos que me permite guardar la data filtrada 
 * @param {Array} data | Arreglo de objetos que contiene toda la data que obtenemos de BD
 * @param {String} field | Campo por el cual se va a realizar el filtro
 * @returns 
 */
const NavbarFilter = ({actions,data,setDataFiltered,field}) =>{

    const handlerApp = HandlerApp();
    
    const [selected, setSelected] = useState(0);
    const [description, setDescription] = useState("");

    const handleFiltered = (value,i,description) =>{
        setSelected(i)
        setDescription(description);
        if(i != 0){
            let filteredData = data.filter(item => item[field].toUpperCase() === value.toUpperCase());
            setDataFiltered(filteredData);
        }else{
            setDataFiltered(data);
        }
    }

    return(
        <section className="section_nav-header">    
            <nav className="child-menu-filter">
                { actions?.map((x,i) =>{
                    return(
                        <NavLink key={i} className={`${selected  == i? 'active-top' : ''} `}  onClick={() => handleFiltered(x.value, i, x.description)}>
                            {x.name}
                        </NavLink>  
                    )                  
                })}
            </nav>
            {
                (description) && 
                <p className='p2ParagraphRegular16'>
                    {description}
                </p>
            }
        </section>
    )

}
export default NavbarFilter;