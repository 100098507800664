import React, { useEffect, useState } from "react";
import '../assets/CardComponentPay.css';
import VisaIcon from '../assets/visaMethod.png';
import DefaultIcon from '../assets/masterCardMethod.png';
import MasterCardIcon from '../assets/masterCardMethod.png';

const CardComponentPay = ({ name, numberCard, type, handleFunction, id}) => {
    const [cardImage, setCardImage] = useState(DefaultIcon); 

    useEffect(() => {
        switch (type.toLowerCase()) {
            case 'master card':
                setCardImage(MasterCardIcon);
                break;
            case 'visa':
                setCardImage(VisaIcon);
                break;
            default:
                setCardImage(DefaultIcon);
        }
    }, [type]);

    // Función para enmascarar el número de la tarjeta
    const maskCardNumber = (cardNumber) => {
        const cardNumberStr = cardNumber.toString(); // Convertir a cadena por si acaso
        if (cardNumberStr.length === 16) {
            return '**** **** **** ' + cardNumberStr.slice(-4);
        }
        return cardNumberStr;
    };


    return (
        <div className="pt-2">
            <div key={id} className="card-pay">
                <div>
                    <div className="d-flex">
                        <div className="ms-3">
                            <img src={cardImage} alt="Card Type" />
                        </div>
                        <div className="ms-2">
                            <span className="tittleCardPay me-2">{name}</span>
                            <span className="numberCard">{maskCardNumber(numberCard)}</span>
                        </div>
                    </div>
                </div>
                {/* Muestra el ícono solo si handleFunction está definido */}
                {handleFunction && (
                    <div className="card-icons">  
                        <i className="fa-regular fa-trash" onClick={() => handleFunction(id)}></i>
                    </div>
                )}
            </div>
        </div>
    );
};

export default CardComponentPay;