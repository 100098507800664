import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import './BodyReportAttitudModal.css'
import HandlerApp from "../../../utils/handlerApp";
import { AppContext } from "../../../context/AppContext";
import { useFetch } from "../../../hooks/useFecth";
import CustomSelect from "./CustomSelect";

const BodyReportAttitudModal = ({ onSubmit }) => {
    const [t] = useTranslation("global");
    const [attitudeType, setAttitudeType] = useState("");
    const api = useFetch();
    const handlerApp = HandlerApp();
    const { app } = useContext(AppContext);


    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit({ customer_actitude: attitudeType });
    };



    return (
        <form id="reportAttitudeForm" onSubmit={handleSubmit}>
            <div className="form-group mt-2">
                <div className="select-container">
                    <CustomSelect 
                        value={attitudeType} 
                        onChange={setAttitudeType} 
                    />
                </div>
            </div>
        </form>
    );
};

export default BodyReportAttitudModal;