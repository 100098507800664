import { useEffect, useState } from "react";

/**
 * @author Paula Melo 
 * @description (Ajustado) Componente para utilizar switch dentro de los formularios para activar una opcion (true - false)
 * @param String labelLeft - Texto que acompaña el input en la parte izquierda
 * @param String labelRigth - Texto que acompaña el input en la parte derecha
 * @param String id - identificador del elemento a modificar para el form
 * @param String name - name del elemento a modificar para el form
 * @param callback onChange - Funcion que modifica el estado del input
 * @version 1.1.0
 * @since 2024-08-23
 */

const SwitchObject = ({labelLeft, labelRight, id, name, value, onChange}) =>{
    const [val, setVal] = useState(value);

    useEffect(()=>{
        setVal((value === 1) ? true : false);
    },[value])

    const handleCheck = (e)=>{
        setVal(e.target.checked);
        onChange(e);
    }

    return(
        <div className="contai-checkbox">
            <p className="flex justify-content-center mx-1">{labelLeft}</p>
            <input type="checkbox" id={id} name={name} className="mi-checkbox" checked={val} onChange={handleCheck} />
            <label htmlFor={id} className="custom-checkbox"></label>
            <p className="flex justify-content-center mx-1">{labelRight}</p>
        </div>
    )
}
export default SwitchObject;