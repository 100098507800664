import React, { useContext, useEffect, useState } from "react";
import './BodyNoteModal.css'
import InputText from "../../Migrar/componentes/Inputs/InputText/InputText";
import HandlerApp from "../../../utils/handlerApp";
import BoldSelectValidated from "../../../utils/BoldSelectValidated";
import HandlerCountriesStatesCities from "../../../utils/handlerCountriesStatesCities";
import { useForm } from "react-hook-form";
import { useFetch } from "../../../hooks/useFecth";
import { AppContext } from "../../../context/AppContext";
import { useTranslation } from "react-i18next";

/**
 * @author Vicente Bolivar
 * @version 1.0.0
 * @description body para modal de detalles de facturacion en la pantalla billing and invoices
 * @param isSubmit Estado iniciado en false y cambia para llamar la funcion que enviar el formulario handleSubmit 
 * @param updateModal funcion para reenviar send request y setear el estado de isSubmit
 * @param setIsModalVisible funcion para cerrar el modal
*/

const BodyBillingModal = ({isSubmit, updateModal, setIsModalVisible}) => {
    const endpoint                          = "settings/billing-details";
    const [t]                               = useTranslation("global");
    const handlerApp                        = HandlerApp();
    const api                               = useFetch();
    const { app }                           = useContext(AppContext);
    const [country, setCountry]             = useState("");
    const [state, setState]                 = useState("");
    const [city, setCity]                   = useState("");
    const [countries, setCountries]         = useState([]);
    const [states, setStates]               = useState([]);
    const [cities, setCities]               = useState([]);
    const [permissions, setPermissions]     = useState([]);
    const handlerLocations                  = HandlerCountriesStatesCities();
    const { register, handleSubmit, formState: { errors, isValid }, setValue, control } = useForm()


    const [formData, setFormData] = useState({
        settingbilling_business_name: "",
        settingbilling_firstname: "",
        settingbilling_lastname: "",
        settingbilling_address: "",
        country_code: "",
        state_code: "",
        city_code: "",
        settingbilling_zipcode: "",
    });


    useEffect(() => {
        setPermissions(app.permissions[endpoint])
    }, [app])

    useEffect(() => {
        if (isSubmit){
            if(isValid){
                handleSubmit(handleSubmitForm)();
                setIsModalVisible(false)
            }
            else{
                handleSubmit(handleSubmitForm)();
            }
        }
    }, [isSubmit])

    useEffect(() => {
        getParams();
    }, [])

    useEffect(() => {
        if (country != null) {
            getState();
        }
    }, [country])

    useEffect(() => {
        if (state != null) {
            getCities()
        }
    }, [state])
    
    
    const sendRequest = () => {
        handlerApp.setLoader(true);
        api.get(endpoint)
        .then(response => {
            if (response.success) {
                let data = response.data[0];
                filterData(data);
                handlerApp.setLoader(false);
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const filterData = (data) => {
        setCountry(data.country_code);
        setState(data.state_code);
        let form = handlerApp.filterDataForm(data, formData, setValue);
        setFormData(form);
    }

    const getParams = ()=>{
        handlerApp.setLoader(true);
        api.get(`/settings/billing-details/params`)
        .then(response => {
            if (response.success) {
                setCountries(handlerApp.getResultFromObject(response.data, "countries"))
                sendRequest();
            } else {
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.setLoader(false);
            handlerApp.showError(t(error.message));
        })
    }

    const handleSubmitForm = (data) => {
        handlerApp.setLoader(true);
        api.post(endpoint, data)
           .then(response => {
              if (response.success) {
                 handlerApp.showOk(response);
                 handlerApp.setLoader(false);
                 updateModal();

              } else {
                 handlerApp.handlerResponse(response);
              }
           })
           .catch(error => {
              handlerApp.showError(t(error.message));
              handlerApp.setLoader(false);
           });
     };

    const handleForm = (e, name = null, dependency = null) => {
        let key = (name !== null) ? name : e.target.name;
        setFormData({
            ...formData,
            [key]: e.target.value
        });
        setValue(key, e.target.value);
        if (dependency !== null) {
            dependency(e.target.value);
        }
    }

    // countries
    const handleSelectCountry = (val) => {
        if (val != null) {
            setCountry(val);
            setStates([]);
            setCities([]);
        }
    }
    // states
    const handleSelectState = (val) => {
        setState(val);
        setCities([]);
    }
    // cities
    const handleSelectCity = (val) => {
        setCity(val);
    }
    
    const getCities = () => {
        handlerLocations.getCities(country,state)
        .then(data=>{
            setCities(data);
        })
    }

    const getState = ()=>{
        handlerLocations.getStates(country)
        .then(data=>{
            setStates(data);
        })
    }


    return (
         <form id="billingForm">
            <div className="form-group">
                <span className="settingsDescription">{t('billing.editBillingDetailsDescription')}</span>
                    <div className="form-group mt-3">
                        <InputText
                            label={t("billing.businessName")}
                            name="settingbilling_business_name"
                            errors={errors}
                            rules={{ required: true, maxLength: 130 }}
                            register={register}
                            control={control}
                            onChange={(e) => handleForm(e)}
                        />
                    </div>
                    <div className="form-group d-flex">
                        <div className="me-3">
                            <InputText
                                label={t("personComponent.firstName")}
                                name="settingbilling_firstname"
                                errors={errors}
                                rules={{ required: true, maxLength: 30 }}
                                register={register}
                                control={control}
                                onChange={(e) => handleForm(e)}
                            />
                        </div>
                        <div>
                            <InputText
                                label={t("lastName")}
                                name="settingbilling_lastname"
                                errors={errors}
                                rules={{ required: true, maxLength: 30 }}
                                register={register}
                                control={control}
                                onChange={(e) => handleForm(e)}
                            />
                        </div>
                    </div>
                    <div>
                        <InputText
                            label={t("address")}
                            name="settingbilling_address"
                            errors={errors}
                            rules={{ required: true, maxLength: 120 }}
                            register={register}
                            control={control}
                            onChange={(e) => handleForm(e)}
                        />
                    </div>
                    <div>
                        <BoldSelectValidated
                            title={t('country')}
                            value={formData.country_code}
                            name="country_code"
                            onChange={handleForm}
                            dependency={handleSelectCountry}
                            options={countries}
                            register={register}
                            errors={errors}
                            required={false}
                        />
                    </div>
                    <div>
                        <BoldSelectValidated
                            title={t('state')}
                            value={formData.state_code}
                            name="state_code"
                            onChange={handleForm}
                            options={states}
                            register={register}
                            errors={errors}
                            required={true}
                            dependency={handleSelectState}
                        />
                    </div>
                    <div className="form-group d-flex">
                        <div className="me-3 text-check">
                            <BoldSelectValidated
                                title={t('city')}
                                value={formData.city_code}
                                name="city_code"
                                onChange={handleForm}
                                options={cities}
                                register={register}
                                errors={errors}
                                required={true}
                                dependency={handleSelectCity}
                            />
                        </div>
                        <div>
                            <InputText
                                label={t("zipCode")}
                                name="settingbilling_zipcode"
                                register={register}
                                errors={errors}
                                rules={{  maxLength: 8 }}
                                control={control}
                                onChange={(e) => handleForm(e)}
                            />
                        </div>
                    </div>
                </div>
        </form>
    );
};

export default BodyBillingModal;