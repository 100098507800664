

const InfoClient = ({icon, title, value})=>{
    return(
        <section className="info-client d-flex align-items-center">
            <i className={`fa-regular ${icon} icon-bg`}></i>
            <div className="mx-3">
                <dt className="p3ParagraphSemiBold14">{title}</dt>
                { (value) ? <dd className="p3ParagraphRegular14">{value}</dd> : <dd className="p3ParagraphRegular14">N/A</dd> }
            </div>
        </section>
    )
}
export default InfoClient;