import { Skeleton } from "@mui/material";
import { NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const Aside = ({loaderMenu, menu}) => {
    const [t, i18n] = useTranslation("global");
    const [valMenu, setValMenu] = useState([]);
    const [language, setLanguage] = useState(i18n.language);

    useEffect(()=>{
        const handleLanguageChange = (lng) => {
            setLanguage(lng);
        };
        i18n.on('languageChanged', handleLanguageChange);
    },[])

    useEffect(()=>{
        if(menu !== undefined){
            setValMenu(menu);
        }
    },[menu])

    if (loaderMenu === true){
        return (
            <div className="bgAside-dark" >
                <Skeleton variant="rounded" sx={{ bgcolor: 'white' }} height={40} />
                <Skeleton variant="rounded" sx={{ bgcolor: 'white' }} height={40} />
                <Skeleton variant="rounded" sx={{ bgcolor: 'white' }} height={40} />
                <Skeleton variant="rounded" sx={{ bgcolor: 'white' }} height={40} />
                <Skeleton variant="rounded" sx={{ bgcolor: 'white' }} height={40} />
            </div>
        )
    }

    const removeSpaces = (inputValue)=>{
        return inputValue.replace(/ /g, "")
    }

    return (
        <div className="bgAside-dark" >
            <ul className="menu-list" id="menu-list">
                {
                    /* normal action */
                    // TODO: No se elimina para usar como enlace directo
                }
                {/* <li className="menu-item">
                    <NavLink to="/">
                        <div>
                            <svg width="21" height="19" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.75 15.125C1.75 16.1797 2.57031 17 3.625 17H19.875C20.1875 17 20.5 17.3125 20.5 17.625C20.5 17.9766 20.1875 18.25 19.875 18.25H3.625C1.86719 18.25 0.5 16.8828 0.5 15.125V1.375C0.5 1.0625 0.773438 0.75 1.125 0.75C1.4375 0.75 1.75 1.0625 1.75 1.375V15.125ZM13.4297 11.2188C13.1953 11.4531 12.7656 11.4531 12.5312 11.2188L9.21094 7.89844L5.30469 11.8438C5.07031 12.0781 4.64062 12.0781 4.40625 11.8438C4.17188 11.6094 4.17188 11.1797 4.40625 10.9453L8.78125 6.57031C9.01562 6.33594 9.44531 6.33594 9.67969 6.57031L13 9.89062L18.1562 4.69531C18.3906 4.46094 18.8203 4.46094 19.0547 4.69531C19.2891 4.92969 19.2891 5.35938 19.0547 5.59375L13.4297 11.2188Z" fill="#3165CC"/>
                            </svg>
                        </div>
                        <span className="menu-item-name">{t('home')}</span>
                    </NavLink>
                </li>
                {/* acordion actions */}
                {
                    (valMenu.length > 0) &&
                        valMenu.map( (title, index)=>(
                            (title.childrens.length > 1) ?
                                <li key={"parent-" + index} className="menu-item collapsable">
                                    <a key={"anchor-" + index} className="collapsed" data-bs-toggle="collapse" href={"#collapse-" + title.menu_parent} role="button" aria-expanded="false" aria-controls={"collapse-" + title.menu_parent}>
                                        { title.menu_icon_library == 'material-icons' && <>
                                            <span className="material-symbols-outlined">{title.menu_icon}</span>
                                        </>}
                                        { title.menu_icon_library == 'svg' && <>
                                            <div dangerouslySetInnerHTML={{__html: title.menu_icon}}/>
                                        </>}
                                        { title.menu_icon_library == 'fontawesome' && <>
                                            <i className={`fa-solid ${title.menu_icon}`}></i>
                                        </>}
                                        {(language === "es") ? <span className="menu-item-name">{title.menu_parent_spanish}</span>:<span className="menu-item-name">{title.menu_parent}</span>}
                                    </a>
                                    <div className="collapse" id={"collapse-" + title.menu_parent}>
                                        <ul>
                                            {
                                                title.childrens.map((song, num) => (
                                                    <li className="menu-item" key={"child-" + num}>
                                                        <NavLink to={song.menu_children_action} >
                                                            { title.menu_children_icon_library == 'material-icons' && <>
                                                                <span className="material-symbols-outlined">{song.menu_children_icon}</span>
                                                            </>}
                                                            { title.menu_children_icon_library == 'svg' && <>
                                                                <div dangerouslySetInnerHTML={{__html: song.menu_children_icon}}/>
                                                            </>}
                                                            { title.menu_children_icon_library == 'fontawesome' && <>
                                                                <i className={`fa-solid ${song.menu_children_icon}`}></i>
                                                            </>}
                                                            {(language === "es") ? song.menu_children_spanish:song.menu_children}
                                                        </NavLink>
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                </li>
                            :
                            <li key={"parent-" + index} className="menu-item">
                                <NavLink key={"anchor-" + index} to={title.childrens[0]?.menu_children_action} >
                                    { title.menu_icon_library == 'material-icons' && <>
                                        <span className="material-symbols-outlined">{title.menu_icon}</span>
                                    </>}
                                    { title.menu_icon_library == 'svg' && <>
                                        <div dangerouslySetInnerHTML={{__html: title.menu_icon}}/>
                                    </>}
                                    { title.menu_icon_library == 'fontawesome' && <>
                                        <i className={`fa-solid ${title.menu_icon}`}></i>
                                    </>}
                                    {(language === "es") ? <span className="menu-item-name">{title.menu_parent_spanish}</span>:<span className="menu-item-name">{title.menu_parent}</span>}
                                </NavLink>
                            </li>
                        ))
                }
            </ul>
        </div>
    )
}
export default Aside;