import React, { useContext, useEffect, useState } from "react";
import InputText from "../../componentes/Inputs/InputText/InputText";
import HandlerApp from "../../../../utils/handlerApp";
import BoldInputPhone from "../../../atoms/Inputs/BoldInputPhone";
import BoldUploadImage from "../../BoldUploadImage/BoldUploadImage";
import HeaderEditScreen from "../../HeaderScreen/HeaderEditScreen";
import BoldSelectValidated from "../../../../utils/BoldSelectValidated";
import { useForm } from "react-hook-form";
import { Divider } from "@mui/material";
import { useFetch } from "../../../../hooks/useFecth";
import { AppContext } from "../../../../context/AppContext";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

const CustomerFamilyForm = ()=>{
    
    const [t]           = useTranslation("global");
    const api           = useFetch();
    const handlerApp    = HandlerApp();
    const nav           = useNavigate();
    
    const endpoint      = "customers/knowns";
    const parent        = "Customers";
    const title         = t('customers.createFamily');
    const description   = t('customers.descriptionScreen');
    const params        = useParams();
    const id            = params?.id;
    const idParent      = params?.idParent;
    
    const {app}                             = useContext(AppContext);
    const [data, setData]                   = useState([]);
    const [permissions, setPermissions]     = useState([]);
    const [selectedValue, setSelectedValue] = useState([]);
    const [relationship, setRelationship]   = useState([]);
    
    const { register, handleSubmit, formState: { errors }, setValue, control } = useForm()

    const [form, setForm] = useState({
        customer_id: null,
        customer_name: "",
        customer_lastname: "",
        customer_phone: "",
        customer_email: "",
        customer_parent: idParent,
        customerknown_relationship: "",
        customer_image: ""
    });

    useEffect(() => {
        setPermissions(app.permissions[endpoint])
    }, [app])

    useEffect(() => {
        getParams();
        setValue("customer_parent", parseInt(idParent));
        setValue("customer_id", null);
    }, [])

    const getParams = ()=>{
        handlerApp.setLoader(true);
        api.get(`${endpoint}/params`)
        .then(response => {
            if (response.success) {
                setRelationship(handlerApp.getResultFromObject(response.data, 'customerknown_relationship'))
                handlerApp.setLoader(false);
            } else {
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.setLoader(false);
            handlerApp.showError(t(error.message));
        })
    }

    const handleSubmitForm = (data) => {
        handlerApp.setLoader(true);
        api.post(endpoint, data)
        .then(response => {
            if (response.success) {
                handlerApp.showOk(response);
                handlerApp.setLoader(false);
                nav("/customers");
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }

        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }
    
    const handlePhone = (value, name)=>{
        setValue(name,value);
    }

    const handleImages = (e)=>{
        setValue('customer_image',e);
    }

    const handleSelectChange = (value) => {
        setSelectedValue(value);
    };

    return(
        <div className="app container">
            <HeaderEditScreen title={title} description={description} />
            <Divider />
            <form onSubmit={handleSubmit( handleSubmitForm )}>
                <div className="mt-5">
                    <BoldUploadImage handleImages={handleImages} value={[]} icon={<i className="fa-regular fa-user" style={{ fontSize: 35, marginBottom: 15 }}></i>} />
                </div>
                <div className="mt-5 mb-5">
                    <div className="col-lg-4 col-md-6 mt-5 mb-2">
                        <BoldInputPhone 
                            name="customer_phone"
                            label={t('phone')} 
                            register={register} 
                            errors={errors.fuser}
                            onChange={handlePhone}
                        />
                    </div>
                    <div className="col-lg-4 col-md-6  mb-2">
                        <BoldSelectValidated 
                            title={t("customers.relationship")} 
                            name="customerknown_relationship" 
                            options={relationship}
                            onChange={handleSelectChange}
                            register={register}
                            errors={errors}
                            required={true}
                        />
                    </div>
                    <div className="col-lg-4 col-md-6  mb-2">
                        <InputText 
                            name="customer_name"
                            label={t("personComponent.firstName")} 
                            errors={errors}
                            register={register}
                            control={control}
                            rules={{required:true}}
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-2">
                        <InputText 
                            name="customer_lastname"
                            label={t("personComponent.surname")}
                            errors={errors}
                            register={register}
                            control={control}
                            rules={{required:true}}
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-2">
                        <InputText 
                            name="customer_email"
                            label={t("email")}
                            errors={errors}
                            register={register}
                            control={control}
                            rules={{required:true}}
                        />
                    </div>
                </div>
                <Divider />
                <div className="row d-flex justify-content-end">
                    <div className="d-flex justify-content-end mt-3">
                        <button className="btn btn-primary-yellow-1 col-1" type="submit">{t('create')}</button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default CustomerFamilyForm;