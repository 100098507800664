import { useEffect, useState, useContext } from "react";
import InputText from "../../componentes/Inputs/InputText/InputText";
import HandlerApp from "../../../../utils/handlerApp";
import HeaderViewScreen from "../../HeaderScreen/HeaderViewScreen";
import "./ServicesChargesScreen.css";
import { useForm } from "react-hook-form";
import { useFetch } from "../../../../hooks/useFecth";
import { AppContext } from "../../../../context/AppContext";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

/**
 * @author Vicente Bolivar
 * @version 1.0.0
*/

const ServicesChargesFrom = () => {
    const endpoint      = "settings/services-charges";
    const params        = useParams();
    const id            = params?.id;
    const parent        = "Settings";
    const [t]           = useTranslation("global");
    const title         = (id != undefined) ? t('serviceCharges.update') : t('serviceCharges.textButton');
    const api           = useFetch();
    const nav           = useNavigate();
    const handlerApp    = HandlerApp();
    const [selectedSettings, setSelectedSettings]   = useState('');
    const [selectedRate, setSelectedRate]           = useState(null);
    const { register, handleSubmit, formState: { errors }, setValue, control } = useForm();

    const [selectedCheckboxes, setSelectedCheckboxes] = useState({
        charge_item_selected_products: false,
        charge_item_selected_services: false,
        charge_apply_checkout_automatic: false,
    });

    const [formData, setFormData] = useState({
        charge_name: "",
        charge_full_sale_value: "",
        charge_only_selected_items: "",
        charge_item_selected_products: "",
        charge_item_selected_services: "",
        charge_apply_checkout_automatic: "",
        charge_type: "",
        charge_percentage_value: "",
        charge_fixed_value: "",
        charge_apply_type:"",
    });

    useEffect(() => {
        if (id) {  
            sendRequest();
        }
    }, [id]); 

    const sendRequest = () => {
        handlerApp.setLoader(true);
        api.get(`${endpoint}/${id}`)
        .then(response => {
            if (response.success) {
                let data = response.data[0];
                handlerApp.setLoader(false);

                setValue("charge_name", data.charge_name || "");
                setValue("charge_full_sale_value", data.charge_full_sale_value || "");
                setValue("charge_only_selected_items", data.charge_only_selected_items || "");
                setValue("charge_item_selected_products", !!data.charge_item_selected_products);
                setValue("charge_item_selected_services", !!data.charge_item_selected_services);
                setValue("charge_apply_checkout_automatic", !!data.charge_apply_checkout_automatic);
                setValue("charge_type", data.charge_type || "");
                setValue("charge_percentage_value", data.charge_percentage_value ? parseFloat(data.charge_percentage_value) : "");
                setValue("charge_fixed_value", data.charge_fixed_value ? parseFloat(data.charge_fixed_value) : "");

                setSelectedRate(data.charge_type || "");
                setSelectedCheckboxes({
                    charge_item_selected_products: !!data.charge_item_selected_products,
                    charge_item_selected_services: !!data.charge_item_selected_services,
                    charge_apply_checkout_automatic: !!data.charge_apply_checkout_automatic,
                });
                setSelectedSettings(
                    data.charge_full_sale_value ? "charge_full_sale_value" : "charge_only_selected_items"
                );
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        });
    };
    
    const processFormData = (data) => {
        return {
            ...data,
            charge_type: selectedRate,
            charge_full_sale_value: selectedSettings === "charge_full_sale_value" ? 1 : 0,
            charge_only_selected_items: selectedSettings === "charge_only_selected_items" ? 1 : 0,
            charge_item_selected_products: selectedCheckboxes.charge_item_selected_products ? 1 : 0,
            charge_item_selected_services: selectedCheckboxes.charge_item_selected_services ? 1 : 0,
            charge_apply_checkout_automatic: selectedCheckboxes.charge_apply_checkout_automatic ? 1 : 0,
            charge_percentage_value: data.charge_percentage_value ? parseFloat(data.charge_percentage_value) : 0,
            charge_fixed_value: data.charge_fixed_value ? parseFloat(data.charge_fixed_value) : 0,
        };
    };

    const handleSubmitForm = (data) => {

        const processedData = processFormData(data);

        handlerApp.setLoader(true);
        api.post(endpoint, processedData)
        .then(response => {
            if (response.success) {
                handlerApp.showOk(response);
                handlerApp.setLoader(false);
                nav("/" + endpoint);
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        });
    };

    const handleUpdateForm = (data) => {
        const processedData = processFormData(data);

        handlerApp.setLoader(true);
        api.put(`${endpoint}/${id}`, processedData)
        .then(response => {
            if (response.success) {
                handlerApp.showOk(response);
                handlerApp.setLoader(false);
                nav("/" + endpoint);
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        });
    };


    const handleChange = (section, id) => (event) => {
        if (section === 'settings') {
            setSelectedSettings(id);
        } else if (section === 'rate') {
            setSelectedRate(id);
            setFormData((prevData) => ({
                ...prevData,
                charge_type: id
            }));
        } 
    };


    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setSelectedCheckboxes((prev) => ({
            ...prev,
            [name]: checked,
        }));
    };


    return (
        <div className="app container">
            <div className="d-flex justify-content-between">
                <HeaderViewScreen title={title} actions={""}/>
            </div>
            <span className="light descriptionServiceCharges">{t("serviceCharges.addServiceDescription")}</span>

            <form onSubmit={handleSubmit((id === undefined) ? handleSubmitForm : handleUpdateForm)}>
                <div className="row mt-4">
                    <div className="col-md-6">
                        <div className="settings-section border p-3 mb-3">
                            <h5>{t('serviceCharges.basicInfo')}</h5>
                            <InputText
                                label={t("name")}
                                name="charge_name"
                                errors={errors}
                                rules={{ required: true, maxLength: 80 }}
                                register={register}
                                control={control}
                            />
                        </div>

                        <div className="settings-section border p-3 mb-3">
                            <h5>{t('serviceCharges.settings')}</h5>
                            <p className="settingsDescription">{t('serviceCharges.settingsDescriptionCreate')}</p>
                            <div className="settings-option">
                                <div className="option-text-settings">
                                    <span>{t('serviceCharges.applyService')}</span>
                                    <div className="form-check mb-2 mt-2">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="charge_full_sale_value"
                                            id="charge_full_sale_value"
                                            checked={selectedSettings === "charge_full_sale_value"}
                                            onChange={handleChange('settings', "charge_full_sale_value")}
                                        />
                                        <label className="form-check-label labelDescription" htmlFor="charge_full_sale_value">
                                            {t('serviceCharges.fullSale')}
                                        </label>
                                    </div>
                                    <div className="form-check mb-2 mt-2">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="charge_only_selected_items"
                                            id="charge_only_selected_items"
                                            checked={selectedSettings === "charge_only_selected_items"}
                                            onChange={handleChange('settings', "charge_only_selected_items")}
                                        />
                                        <label className="form-check-label labelDescription" htmlFor="charge_only_selected_items">
                                            {t('serviceCharges.onlySelected')}
                                        </label>
                                    </div>
                                    {/* Renderiza InputText solo si "flatRate" está seleccionado */}
                                    {selectedSettings === "charge_only_selected_items" && (
                                    <div>
                                        <div className="ms-4">
                                            <input 
                                                type="checkbox" 
                                                className="form-check-input me-2" 
                                                name="charge_item_selected_services"
                                                id="charge_item_selected_services" 
                                                checked={selectedCheckboxes.charge_item_selected_services}
                                                onChange={handleCheckboxChange}
                                            />
                                            <label className="labelDescription" htmlFor="charge_item_selected_services">{t('permissions.services')}</label>
                                        </div>
                                        <div className="ms-4">
                                            <input 
                                                type="checkbox" 
                                                className="form-check-input me-2" 
                                                name="charge_item_selected_products" 
                                                id="charge_item_selected_products" 
                                                checked={selectedCheckboxes.charge_item_selected_products}
                                                onChange={handleCheckboxChange}
                                            />
                                            <label className="labelDescription" htmlFor="charge_item_selected_products">{t('productComponent.products')}</label>
                                        </div>
                                    </div>
                                    )}
                                </div>
                            </div>
                            <div className="settings-option">
                                <div className="option-text-settings">
                                    <span>{t('serviceCharges.additionalOptions')}</span>
                                </div>
                            </div>
                            <div>
                                <input 
                                    type="checkbox" 
                                    className="form-check-input me-2" 
                                    id="charge_apply_checkout_automatic" 
                                    name="charge_apply_checkout_automatic" 
                                    checked={selectedCheckboxes.charge_apply_checkout_automatic}
                                    onChange={handleCheckboxChange}
                                />
                                <label className="labelDescription" htmlFor="charge_apply_checkout_automatic">{t('serviceCharges.automatically')}</label>
                            </div>
                        </div>

                        <div className="settings-section border p-3 mb-3">
                            <h5>{t('serviceCharges.rate')}</h5>
                            <p className="settingsDescription">{t('serviceCharges.rateDescription')}</p>
                            <div className="settings-option">
                                <div className="option-text-settings">
                                    <span>{t('serviceCharges.rateType')}</span>
                                    <div className="form-check mb-2 mt-2">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="charge_type"
                                            id="charge_fixed_value"
                                            checked={selectedRate === "fixed"}
                                            onChange={handleChange('rate', "fixed")}
                                        />
                                            <label className="form-check-label labelDescription" htmlFor="charge_fixed_value">
                                                {t('serviceCharges.flatRate')}
                                            </label>
                                        </div>
                                        {/* Renderiza InputText solo si "flatRate" está seleccionado */}
                                        {selectedRate === "fixed" && (
                                            <div className="ms-4">
                                                <InputText
                                                    label={t("serviceCharges.enterFlatRate")}
                                                    name="charge_fixed_value"
                                                    errors={errors}
                                                    rules={{ required: true, pattern: /^\d+(\.\d+)?$/i }}
                                                    register={register}
                                                    control={control}
                                                />
                                            </div>
                                        )}
                                        <div className="form-check mb-2 mt-2">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="charge_type"
                                                id="charge_percentage_value"
                                                checked={selectedRate === "percentage"}
                                                onChange={handleChange('rate', "percentage")}
                                            />
                                            <label className="form-check-label labelDescription" htmlFor="charge_percentage_value">
                                                {t('serviceCharges.percentage')}
                                            </label>
                                        </div>
                                        {/* Renderiza otro InputText solo si "percentage" está seleccionado */}
                                        {selectedRate === "percentage" && (
                                            <div className="ms-4">
                                                <InputText
                                                    label={t("serviceCharges.percentage")}
                                                    name="charge_percentage_value"
                                                    errors={errors}
                                                    rules={{ required: true, min: 0, max: 100, pattern: /^\d+(\.\d+)?$/i }}
                                                    register={register}
                                                    control={control}
                                                />
                                            </div>
                                        )}
                                        <div className="form-check mb-2 mt-2">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="charge_type"
                                                id="both"
                                                checked={selectedRate === "both"}
                                                onChange={handleChange('rate', "both")}
                                            />
                                            <label className="form-check-label labelDescription" htmlFor="both">
                                                {t('serviceCharges.both')}
                                            </label>
                                        </div>
                                        {/* Renderiza ambos InputText si "both" está seleccionado */}
                                        {selectedRate === "both" && (
                                            <>
                                                <div className="d-flex ms-4">
                                                    <div className="me-2 option-text-settings">
                                                        <InputText
                                                            label={t("serviceCharges.enterFlatRate")}
                                                            name="charge_fixed_value"
                                                            errors={errors}
                                                            rules={{ required: true, pattern: /^\d+(\.\d+)?$/i }}
                                                            register={register}
                                                            control={control}
                                                        />
                                                    </div>
                                                    <div className="option-text-settings">
                                                        <InputText
                                                            label={t("serviceCharges.percentage")}
                                                            name="charge_percentage_value"
                                                            errors={errors}
                                                            rules={{ required: true, min: 0, max: 100, pattern: /^\d+(\.\d+)?$/i }}
                                                            register={register}
                                                            control={control}
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row d-flex justify-content-end">
                    <div className="d-flex justify-content-end me-5">
                        {id === undefined && <button className="btn btn-primary-yellow-1" type="submit">{t('create')}</button>}
                        {id && <button className="btn btn-primary-yellow-1" type="submit">{t('update')}</button>}
                    </div>
                </div>
            </form>
        </div>
        
    ) 
}

export default ServicesChargesFrom;