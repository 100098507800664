import React from "react";
import "./SimpleList.css";
import ImageNotFound from "../../../assets/img/image-not-found.webp";

const SimpleList = ( { image, name, subname, text, last } ) =>{

    return(
        <section className="simplelist-container">
            <section className="simplelist-card">

                <div className="simplelist-image">
                    {
                        (image === undefined || image === "" || image === null) ? 
                            <img src={ImageNotFound} />
                        :
                        <img src={image} />
                    }
                </div>
                <div className="simplelist-body">
                    <div className="simplelist-title">
                        <p className="simplelist-text-name">{name}</p>
                        <span>/</span>
                        <span>{subname}</span>
                    </div>
                    <p className="simplelist-text">{text}</p>
                </div>
                
                
            </section>
            {
                (last === false) &&
                <div className="width-divider"></div>
            }
        
        </section>
    );
}
export default SimpleList;