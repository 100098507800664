import React from 'react'
import './TransactionDetailModal.css'
import { useTranslation } from 'react-i18next';
import HandlerApp from '../../../utils/handlerApp';
import InfoClient from '../../Migrar/componentes/InfoClient';

/**
 * @author Vanessa Bernal
 * @version 1.0.0
 * @description Componente que muestra la informacion de una transaccion
 * @params data - Datos de la transaccion para ser mostrados. Recibe los parametros:
 * name: nombre del cliente
 * phone: telefono del cliente
 * email: correo electronico del cliente
 * date: fecha de la transaccion
 * type: tipo de transaccion
 * value: valor de la transaccion
 * card_type: tipo de tarjeta
 * last4: ultimos 4 digitos de la tarjeta
 * transaction_id: id de la transaccion
 * aprove_id: id de la aprobacion
*/

const TransactionDetailModal = ({ data }) => {

    const [t] = useTranslation("global");
    const handlerApp = HandlerApp();

    return (
        <div className='background-transaction container-modal'>
            <h4 className='title-font-transaction'>{t('modalDetailPaymentMethods.information')}</h4>
            <div className='container-data-transaction background-container-data'>
                <InfoClient
                    icon='fa-user'
                    title={t('modalDetailPaymentMethods.user')}
                    value={data.name}
                />
                <InfoClient
                    icon='fa-phone'
                    title={t('modalDetailPaymentMethods.phone')}
                    value={data.phone}
                />
                <InfoClient
                    icon='fa-envelope'
                    title={t('modalDetailPaymentMethods.email')}
                    value={data.email}
                />
                <InfoClient
                    icon='fa-calendar-days'
                    title={t('modalDetailPaymentMethods.date')}
                    value={data.date.format('DD/MM/YYYY')}
                />
                <InfoClient
                    icon='fa-calendar-check'
                    title={t('modalDetailPaymentMethods.type')}
                    value={data.type}
                />
                <InfoClient
                    icon='fa-hashtag'
                    title={t('modalDetailPaymentMethods.value')}
                    value={handlerApp.formatCurrency(data.value)}
                />
                <InfoClient
                    icon='fa-credit-card'
                    title={data.card_type == 'Debit' ?t('modalDetailPaymentMethods.debit'):t('modalDetailPaymentMethods.credit')}
                    value={'**** **** **** ' + data.last4}
                />
                <InfoClient
                    icon='fa-hashtag'
                    title={t('modalDetailPaymentMethods.transactionId')}
                    value={data.transactionId}
                />
                <InfoClient
                    icon='fa-hashtag'
                    title={t('modalDetailPaymentMethods.aproveId')}
                    value={data.aproveId}
                />
            </div>
        </div>
    )
}

export default TransactionDetailModal
