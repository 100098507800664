import React from "react";
import './CardEditAndDelete.css';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

/**
 * @author Vicente Bolivar
 * @version 1.0.0
 * @param string title - titulo de la card
 * @param string subtitle - subtitulo de la card
 * @param string text - texto segundario para la tarjeta si lo necesita
 * @param string navigate - se pasa ruta de navegacion en caso de que deba navegar
 * @param boolean showButtonEdit - estado para mostar boton 1
 * @param boolean showButtonDelete - estado para mostar boton 2
 * @param callback onFunction1 - funcion para boton 1 de ser el caso
 * @param callback onFunction2 - funcion para boton 2 de ser el caso
 * @param string ButtonText1 - Texto del boton 1
 * @param string ButtonText2 - Texto del boton 2
*/

const CardEditAndDelete = ({ title, subtitle, text, navigate, onFunctionEdit, onFunctionDelete, ButtonText1, ButtonText2, showButtonEdit = false, showButtonDelete = false }) => {
    const nav = useNavigate();
    const [t] = useTranslation("global");

    const handleFunctionEdit = () => {
        if (onFunctionEdit) {
            onFunctionEdit(); 
        } else if (navigate) {
            nav(navigate); 
        }
    };

    const handleFunctionDelete = () => {
        if (onFunctionDelete) {
            onFunctionDelete();
        } else if (navigate) {
            nav(navigate); 
        }
    };

    return (
        <section className='cardContainer cardBg my-2'>
            <section className='bodyContainerText'>
                <p className="titleTextCard">{title}</p>
                <p className="descriptionTextCard">{subtitle}</p>
                <p className="descriptionTextCard">{text}</p>
            </section>
            {/* Botón 1 */}
            {showButtonEdit && (
                <button 
                    className='buttonOutline w-auto me-2 pb-1 pt-1' 
                    onClick={handleFunctionEdit}
                >
                    <i className="fa-regular fa-pen pe-2"></i>
                    <span className="w-text">{ButtonText1}</span>
                </button>
            )}
            {/* Botón 2 */}
            {showButtonDelete && (
                <button 
                    className='buttonOutline w-auto me-3 pb-1 pt-1' 
                    onClick={handleFunctionDelete}
                >
                    <i className="fa-regular fa-trash pe-2"></i>
                    <span className="w-text">{ButtonText2}</span>
                </button>
            )}
        </section>
    );
};

export default CardEditAndDelete;