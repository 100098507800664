
const Footer = () => {

    return (
        <>
            <div className="footer-branch d-flex justify-content-between">
                <span className="light text-gray company">
                    Created by <a className="company-branch-link" href="https://webtronick.com" target="_blank">Webtronick LLC</a> © {new Date().getFullYear()}
                </span>
                <ul className="important-links">
                    <li><a href="https://webtronick.com/license/theme" target="_blank"><span className="light text-gray footer-branch">License</span></a></li>
                    <li><a href="https://webtronick.com/" target="_blank"><span className="light text-gray footer-branch">Company</span></a></li>
                </ul>
            </div>
        </>
    )
}

export default Footer;