import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import './BodyNoteModal.css'
import HandlerApp from "../../../utils/handlerApp";
import { AppContext } from "../../../context/AppContext";
import { useFetch } from "../../../hooks/useFecth";

const BodyBlockClientModal = ({onSubmit} ) => {
    const [t] = useTranslation("global");
    const [noteType, setNoteType] = useState("");
    const [description, setDescription] = useState("");
    const api = useFetch();
    const handlerApp = HandlerApp();
    const { app } = useContext(AppContext);


    const handleDescriptionChange = (e) => {
        setDescription(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit({ customer_blocked_description: description });
    };


    return (
        <form id="blockClientForm" onSubmit={handleSubmit}>
            <div className="form-group mt-2">
                    <div className="form-group mt-4">
                        <textarea 
                            id="description" 
                            value={description} 
                            onChange={handleDescriptionChange}
                            className="form-control"
                            rows="12"
                            placeholder={t('taxesComponent.description')}
                            required
                        />
                    </div>
                </div>
        </form>
    );
};

export default BodyBlockClientModal;