import { useState } from "react";
import "./CentralModalOptions.css";


/**
 * @author Paula Melo 
 * @description Componente que muestra la informacion en un modal central y recibe un boton en la parte superior derecha que despliga opciones
 * @param String title - Titulo central de la parte superior
 * @param children children
 * @param array options - arreglo de objetos 
 * @param boolean visible - determina si se muestra o no el modal
 * @param callback setVisible - callback para cambiar el estado de visible
 * @version 1.1.0
 * @since 2024-08-28
 */

const CentralModalOptions = ({id, title, children, options, visible, setOptionsVisible}) =>{

    const [isActive, setIsActive] = useState('false');

    const showOptions = ()=>{
        setIsActive(true);
    }
    return(
        visible &&
        <div className="centralmodalOptions-overlay">
            <section className="centralmodalOptions-container">
                <div className="centralmodalOptions-header">
                    <button className="centralmodalOptions-button" onClick={()=>{setOptionsVisible(!visible)}}> 
                        <i className="fa-regular fa-xmark"></i>                    
                    </button>
                    <h3 className="centralmodalOptions-title">{title}</h3>
                    {
                        options &&
                        <div className="dropdown">
                            <button className="btn centralmodalOptions-button" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <i className="fa-regular fa-bars"></i>
                            </button>
                            <ul className="dropdown-menu centralmodalOptions-optionsgroup">
                                {
                                    options.map(x=>(
                                        <li className="p-2 centralmodalOptions-option">
                                            <button className="dropdown-item-group" onClick={()=>x.handleFunction(id)}>
                                                <span className="dropdown-item-icon">
                                                    <i style={x.color && {color: x.color}} className={x.icon}></i>
                                                </span>
                                                <span style={x.color && {color: x.color}} className="dropdown-item-title">{x.name}</span> 
                                            </button>
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                    }   
                </div>
                <div className="centralmodalOptions-body">
                    {children}
                </div>
            </section>
            
        </div>
    );
}
export default CentralModalOptions;