import { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "../../assets/css/BoldFilesUpload.css";
import "./UploadFile.css";

/**
 * @author Daniel Bolivar
 * @version 1.0.0
 * @param callback returnImages - callback para recibir los archivos cargados 
 * @param boolean multiple - recibe true si puede recibir mas de un archivo y false si se limita solo a uno
 * @param file value - Archivo a cargado
 * @param accepts string - archivos aceptados para carga. Example: .xlsx, .xls
*/

const UploadFile = ({ returnFiles, multiple, value, accepts }) => {

    const uploadSection = useRef();
    const boldInput = useRef();

    const [t, i18n] = useTranslation("global");

    const [upload, setUpload] = useState(null);

    useEffect(() => {
    }, [value]);

    useEffect(() => {
        returnFiles(upload);
    }, [upload]);

    const handleOnDragOver = (e) => {
        e.preventDefault();
        uploadSection.current.className = "section-bold-upload-files drag-over";
        return false;
    };

    const handleOnDragLeave = (e)=>{
        e.preventDefault();
        if(uploadSection.current.classList.contains('drag-over')){
            uploadSection.current.className = "section-bold-upload-files";
        }
        return false;
    }

    const handleOnDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const files = e.dataTransfer.files;
        setUpload(e.dataTransfer.files[0]);
    }

    const handleClickAttach = (e) => {
        e.preventDefault();
        boldInput.current.click();
    }

    const onChangeUploads = (e) => {
        e.preventDefault();
        setUpload(e.target.files[0]);
    }

    return (
        <>
            <div className="container-uploads">
                <div ref={uploadSection} className="section-bold-upload-files " onDragOver={handleOnDragOver} onDrop={handleOnDrop} onDragLeave={handleOnDragLeave}>
                    {upload === null &&
                        <div className="inner-upload">
                            <button
                                className="background-button-white"
                                type={"submit"}
                                onClick={handleClickAttach}
                            >
                                <div className="d-flex justify-content-center align-items-center">
                                    <i className="fa-solid fa-file-arrow-up me-3 icon-button-upload"></i>
                                    <span className="icon-button-upload">{t('customers.clientsImport.selectFile')}</span>
                                </div>
                            </button>
                            
                            <span className="title-upload-files">
                                {t('dragTemplate')} CSV - Máx. 1MB
                            </span>
                        </div>
                    }
                </div>
                <input ref={boldInput} onChange={onChangeUploads} hidden type="file" name="uploadFiles" multiple={multiple} id="bold-upload-files" accept={accepts}></input>
            </div>
        </>
    )
}


export default UploadFile;