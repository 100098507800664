import React from 'react';

/**
 * @author Vicente Bolivar
 * @version 1.0.0
 * @description Componente general para emptyState
 * @param title Prop para el título del EmptyState
 * @param text Prop para el texto de descripción
 * @param buttonText Prop para el texto del botón
 * @param ImageSource Prop para la fuente de la imagen
 * @param onPressAction Prop para la acción del botón
 * @param type Prop para el tipo de imagen (svg o img)
 * @param styles Prop para clases personalizadas
 * @param icon Prop para icon personalizado de la libreria fontAwesome
 * @description https://webtronick.atlassian.net/wiki/spaces/WEB/pages/155582469/Componentes+Web
*/

const EmptyState = ({ title, text, buttonText, ImageSource, onPressAction, styles, icon = "fa-plus", type = "img"}) => {

    return (
        <div className={"d-flex justify-content-center align-items-center flex-column"} style={styles}>
            <div className='fw-600 fs-title-empty h1headline36'>
                {title}
            </div>
            {type === "svg" ? (
                <ImageSource width={320} height={200} fill={"#ffffff"} />
            ) : (
                <img className="empty-state-image pt-4" src={ImageSource} alt="No data" />
            )}
            <p className="empty-state-text text-center">{text}</p>
            {onPressAction && ( 
                <button 
                    className="btn btn-primary-yellow-1 m-1" 
                    type="button"
                    onClick={onPressAction}
                >
                    <i className={`fa-regular ${icon}`}></i>
                    <span className="font-weight-bold ms-2">{buttonText}</span>
                </button>
            )}
        </div>
    );
}

export default EmptyState;
