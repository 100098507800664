import HandlerApp from "../../../../utils/handlerApp";
import RightModal from "../../../atoms/RightModal/RightModal";
import EmptyState from "../../../Migrar/componentes/emptyState/EmptyState";
import NavLinkView from "../../../Migrar/componentes/navbar/NavLinkView";
import CentralModal from "../../../atoms/CentralModal/CentralModal";
import ButtonPrimary from "../../../atoms/Buttons/ButtonPrimary";
import BodyNoteModal from "../../../atoms/RightModal/BodyNoteModal";
import ButtonOutline from "../../../atoms/Buttons/ButtonOutline";
import HeaderViewScreen from "../../../Migrar/HeaderScreen/HeaderViewScreen";
import CardWithButtonRight from "../../../atoms/Cards/Customer/Giftcard/CardWithButtonRight";
import BodyBlockClientModal from "../../../atoms/RightModal/BodyBlockClientModal";
import BodyActiveClientModal from "../../../atoms/RightModal/BodyActiveClientModal";
import BodyUnlockClientModal from "../../../atoms/RightModal/BodyUnlockClientModal";
import BodyDeleteClientModal from "../../../atoms/RightModal/BodyDeleteClientModal";
import BodyReportAttitudModal from "../../../atoms/RightModal/BodyReportActtitudModal";
import BodyInactiveClientModal from "../../../atoms/RightModal/BodyInactiveClientModal";
import { useFetch } from "../../../../hooks/useFecth";
import { AppContext } from "../../../../context/AppContext";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState, useContext } from "react";

const AppointmentsScreen = () => {
    const [t]           = useTranslation("global");
    const title         = t("customers.viewClient");
    const endpoint      = 'customers';
    const parent        = "Customers";
    const nav           = useNavigate();
    const api           = useFetch();
    const handlerApp    = HandlerApp();
    
    const params        = useParams();
    const id            = params?.id;

    const { app } = useContext(AppContext);

    const [data, setData]                     = useState([]);
    const [permissions, setPermissions]       = useState([]);
    const [menus, setMenus]                   = useState([]);
    const [optionsVisible, setOptionsVisible] = useState(false);
    const [modalType, setModalType]           = useState(null);

    useEffect(() => {
        setMenus(app.menus[parent]);
        setPermissions(app.permissions[endpoint])
    }, [app])

    useEffect(() => {
        sendRequest();
    }, [])

    useEffect(() => {
        if(data?.length === 0){
            const initialData = {
                customer_active: localStorage.getItem('customer_active'),
                customer_blocked: localStorage.getItem('customer_blocked')
            };
            setData(initialData);
        }
    }, [data])

    const createNote = () => {
        setModalType('createNote');
        setOptionsVisible(true);
    };

    const reportAttitude = () => {
        setModalType('reportAttitude');
        setOptionsVisible(true);
    };

    const blockClient = () => {
        setModalType('blockClient');
        setOptionsVisible(true);
    };

    const activeClient = () => {
        setModalType('activeClient');
        setOptionsVisible(true);
    };

    const closeModal = () => {
        setOptionsVisible(false);
    };

    const deleteClient = () => {
        setModalType('deleteClient');
        setOptionsVisible(true);
    };

    const inactiveClient = () => {
        setModalType('inactiveClient');
        setOptionsVisible(true);
    };

    const unlockClient = () => {
        setModalType('unlockClient');
        setOptionsVisible(true);
    };

    const actions = [
        {name: t("customers.actionView.edit"), endpoint: `/${endpoint}/create`, icon: "fa-pen"},
        {name: t("customers.actionView.createNote"), function: createNote, icon: "fa-note-sticky"},
        {name: t("customers.actionView.messageClient"), endpoint: `/${endpoint}/categories/create`, icon: "fa-message"},
        {name: t("customers.actionView.callClient"), endpoint: `/${endpoint}/categories/create`, icon: "fa-phone"},
        {name: t("customers.actionView.createGiftcard"), endpoint: `/${endpoint}/categories/create`, icon: "fa-gift-card"},
        {name: t("customers.actionView.createAppointment"), endpoint: `/${endpoint}/categories/create`, icon: "fa-calendar-plus"},
        {name: t("customers.actionView.createSale"), endpoint: `/${endpoint}/categories/create`, icon: "fa-cash-register"},
        {name: t("customers.actionView.addFamily"), endpoint: `/${endpoint}/${id}/create/known`, icon: "fa-user-plus"},
        {name: t("customers.view.reportAttitude"),  function: reportAttitude, icon: "fa-face-zipper"},
        {name: data?.customer_active == 1 ? t("customers.view.inactiveClient") : t("customers.view.activeClient"),  function: data?.customer_active == 1 ? inactiveClient : activeClient, icon: "fa-play"},
        {name: t("customers.actionView.delete"), function: deleteClient,  icon: "fa-trash", red: true},
        {name: data?.customer_blocked == 1 ? t("customers.actionView.unlockClient") : t("customers.actionView.blockClient"), function: data?.customer_blocked == 1 ? unlockClient : blockClient, icon: "fa-ban", red: true},
    ];

    const nav_actions = [
        {name: t("menuCustomer.overview"),    to: `/customers/${id}/overview`},
        {name: t("menuCustomer.profile"),     to: `/customers/${id}/profile`},
        {name: t("menuCustomer.appointment"), to: `/customer/appointments/${id}`},
        {name: t("menuCustomer.payment"),     to: `/payment-methods/${id}`},
        {name: t("menuCustomer.products"),    to: `/customers/products/${id}`}
    ];
    
    const sendRequest = () => {
        handlerApp.setLoader(true);
        api.get(`customers/${id}/appointments`)
        .then(response => {
            if (response.success) {
                setData(response.data);
                handlerApp.setLoader(false);
            } else {
                setData([]);
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const handleCreateNote = (noteData) => {
        setOptionsVisible(false);
    };

    const handleCreateNoteClick = () => {
        const formElement = document.getElementById('createNoteForm');
        if (formElement) {
            formElement.requestSubmit();
        }
    };

    const handleReportAttitudeClick = () => {
        const formElement = document.getElementById('reportAttitudeForm');
        if (formElement) {
            formElement.requestSubmit();
        }
    };

    const handleActivateClientClick = () => {
        const formElement = document.getElementById('activateClientForm');
        if (formElement) {
            formElement.requestSubmit();
        }
    };

    const handleBlockClientClick = () => {
        const formElement = document.getElementById('blockClientForm');
        if (formElement) {
            formElement.requestSubmit();
        }
    };

    const handleReportAttitude = async (reportData) => {
        closeModal();
        handlerApp.setLoader(true);
        try {
            const dataToSend = {
                customer_actitude: reportData.customer_actitude
            };
                
            const response = await api.put(`customers/${id}/actitude`, dataToSend);
    
            if (response.success) {
                handlerApp.showOk({ message: t('customers.reportSuccess') });
                sendRequest();
            } else {
                handlerApp.handlerResponse(response); 
            }
        } catch (error) {
            handlerApp.showError(t(error.message)); 
        } finally {
            handlerApp.setLoader(false);
            setOptionsVisible(false); 
        }
    };

    const handleActivateClient = async () => {
        closeModal();
        handlerApp.setLoader(true);
        try {
            const payload = {
                customer_active: 1
            };
    
            const response = await api.put(`customers/${id}/active`, payload);
    
            if (response.success) {
                handlerApp.showOk({ message: t('customers.clientActivated') });
                sendRequest();
                localStorage.setItem('customer_active', 1);
            } else {
                handlerApp.handlerResponse(response);
            }
        } catch (error) {
            handlerApp.showError(t(error.message));
        } finally {
            handlerApp.setLoader(false);
            setOptionsVisible(false);
        }
    };

    const handleBlockClient = async (blockData) => {
        closeModal();
        handlerApp.setLoader(true);
        try {
            const dataToSend = {
                customer_blocked: 1,
                ...blockData
            };
    
            const response = await api.put(`customers/${id}/blocked`, dataToSend);
    
            if (response.success) {
                handlerApp.showOk({ message: t('customers.blockSuccess') });
                localStorage.setItem('customer_blocked', 1);
                sendRequest();
            } else {
                handlerApp.handlerResponse(response); 
            }
        } catch (error) {
            handlerApp.showError(t(error.message)); 
        } finally {
            handlerApp.setLoader(false);
            setOptionsVisible(false); 
        }
    };

    const handleDeleteClient = async () => {
        handlerApp.setLoader(true);
        try {
            const response = await api.deleteMethod(`customers/${id}`);
    
            if (response.success) {
                handlerApp.showOk({ message: t('customers.clientDelete') });
                nav("/" + endpoint);
            } else {
                handlerApp.handlerResponse(response);
            }
        } catch (error) {
            handlerApp.showError(t(error.message));
        } finally {
            handlerApp.setLoader(false);
            setOptionsVisible(false);
        }
    };

    const handleDeleteClientClick = () => {
        const formElement = document.getElementById('deleteClientForm');
        if (formElement) {
            formElement.requestSubmit();
        }
    };

    const handleInactivateClient = async () => {
        closeModal();
        handlerApp.setLoader(true);
        try {
            const payload = {
                customer_active: 0
            };
    
            const response = await api.put(`customers/${id}/active`, payload);
    
            if (response.success) {
                handlerApp.showOk({ message: t('customers.clientInactivated') });
                localStorage.setItem('customer_active', 0);
                sendRequest();
            } else {
                handlerApp.handlerResponse(response);
            }
        } catch (error) {
            handlerApp.showError(t(error.message));
        } finally {
            handlerApp.setLoader(false);
            setOptionsVisible(false);
        }
    };

    const handleInactivateClientClick = () => {
        const formElement = document.getElementById('inactiveClientForm');
        if (formElement) {
            formElement.requestSubmit();
        }
    };

    const handleUnlockClient = async () => {
        closeModal();
        handlerApp.setLoader(true);
        try {
            const payload = {
                customer_blocked : 0
            };
    
            const response = await api.put(`customers/${id}/unlock`, payload);
    
            if (response.success) {
                handlerApp.showOk({ message: t('customers.clientUnlock') });
                localStorage.setItem('customer_blocked', 0);
                sendRequest();
            } else {
                handlerApp.handlerResponse(response);
            }
        } catch (error) {
            handlerApp.showError(t(error.message));
        } finally {
            handlerApp.setLoader(false);
            setOptionsVisible(false);
        }
    };

    const handleUnlockClientClick = () => {
        const formElement = document.getElementById('unlockClientForm');
        if (formElement) {
            formElement.requestSubmit();
        }
    };
  
    return (
        <div className="app container">
            <HeaderViewScreen title={title} actions={actions}/>

            <NavLinkView actions={nav_actions} active={2}/>

            {
                (data.length > 0) ? 
                    data.map((item, index) => (
                        <CardWithButtonRight
                            key={item.booking_id}
                            title={item.services}
                            image={item.user_photo}
                            textTop={[
                                <span className="captionRegular12">{t("calendar.date")}</span>, 
                                <span className="captionSemiBold12"> {handlerApp.getFormatDate(item.day)}</span> 
                            ]}
                            textBottom={[
                                <span className="captionRegular12">{t("userBarbersComponent.barber")}</span>,
                                <span className="captionSemiBold12"> {item.barber}</span>
                            ]}
                            status={handlerApp.getStatusClass(item.booking_state)}
                            statusValue={item.booking_state}
                            handleView={() => { nav(`/customer/appointments/${id}/view/${item.booking_id}`) }}
                        />
                        // <CardWithButtonRight
                        //     key={item.booking_id}
                        //     textTop={item.barber}
                        //     title={item.services}
                        //     textBottom={item.day}
                        //     status={handlerApp.getStatusClass(item.booking_state)}
                        //     statusValue={item.booking_state}
                        //     image={item.user_photo}
                        //     showImage={item.user_photo ? true : false}
                        //     handleView={() => { nav(`/customer/appointments/${id}/view/${item.booking_id}`) }}
                        // />
                    ))
                    :
                    <EmptyState
                        title={t("appointment.appointments")}
                        text={t("customers.emptyCustomerAppointment")}
                        buttonText={t("customers.actionView.createAppointment")}
                        ImageSource={"/img/empty/customer_appointment.png"}
                        onPressAction={()=>{}}
                        icon={"fa-plus"}
                    />
            }

            {modalType === 'createNote' && (
                <RightModal 
                    title={t("customers.actionView.createNote")} 
                    body={<BodyNoteModal onCreate={handleCreateNote} customerId={data.customer_id}/>} 
                    visible={optionsVisible} 
                    setOptionsVisible={setOptionsVisible} 
                    footer={<ButtonPrimary label={t('create')} onClick={handleCreateNoteClick}/>}
                />
            )}

            {modalType === 'reportAttitude' && (
                <CentralModal 
                    title={t("customers.view.reportAttitude")} 
                    body={<BodyReportAttitudModal onSubmit={handleReportAttitude} />} 
                    visible={optionsVisible} 
                    setOptionsVisible={setOptionsVisible} 
                    footer={<ButtonPrimary label={t('report')} onClick={handleReportAttitudeClick} />}
                />
            )}

            {modalType === 'blockClient' && (
                <RightModal 
                    title={t("customers.actionView.blockClient")} 
                    body={<BodyBlockClientModal onSubmit={handleBlockClient} />} 
                    visible={optionsVisible} 
                    setOptionsVisible={setOptionsVisible} 
                    footer={<ButtonPrimary label={t('block')} onClick={handleBlockClientClick}/>}
                />
            )}

            {modalType === 'activeClient' && (
                <CentralModal 
                    title={t("customers.view.activeClient")} 
                    body={<BodyActiveClientModal onSubmit={handleActivateClient} />} 
                    visible={optionsVisible} 
                    setOptionsVisible={setOptionsVisible} 
                    footer={[
                        <ButtonOutline label={t('customers.view.noContinue')} onClick={closeModal}  />,
                        <p className="me-3"></p>,
                        <ButtonPrimary label={t('customers.view.yesActive')} onClick={handleActivateClientClick} />
                    ]}
                />
            )}

            {modalType === 'deleteClient' && (
                <CentralModal 
                    title={t("customers.view.deleteClient")} 
                    body={<BodyDeleteClientModal onSubmit={handleDeleteClient} closeModal={closeModal}/>} 
                    visible={optionsVisible} 
                    setOptionsVisible={setOptionsVisible} 
                    footer={[
                        <ButtonOutline label={t('customers.view.noContinue')} onClick={closeModal}  />,
                        <p className="me-3"></p>,
                        <ButtonPrimary label={t('customers.view.yesDelete')} onClick={handleDeleteClientClick} />
                    ]}
                />
            )}

            {modalType === 'inactiveClient' && (
                <CentralModal 
                    title={t("customers.view.inactiveClient")} 
                    body={<BodyInactiveClientModal onSubmit={handleInactivateClient} />} 
                    visible={optionsVisible} 
                    setOptionsVisible={setOptionsVisible} 
                    footer={[
                        <ButtonOutline label={t('customers.view.noContinue')} onClick={closeModal}  />,
                        <p className="me-3"></p>,
                        <ButtonPrimary label={t('customers.view.yesInactive')} onClick={handleInactivateClientClick} />
                    ]}
                />
            )}

            {modalType === 'unlockClient' && (
                <CentralModal 
                    title={t("customers.actionView.unlockClient")} 
                    body={<BodyUnlockClientModal onSubmit={handleUnlockClient} />} 
                    visible={optionsVisible} 
                    setOptionsVisible={setOptionsVisible} 
                    footer={[
                        <ButtonOutline label={t('customers.view.noContinue')} onClick={closeModal}  />,
                        <p className="me-3"></p>,
                        <ButtonPrimary label={t('customers.view.yesUnlock')} onClick={handleUnlockClientClick} />
                    ]}
                />
            )}
        </div>
    )
}

export default AppointmentsScreen;