import { useTranslation } from "react-i18next";
import { useFetch } from "../../../../hooks/useFecth";
import HandlerApp from "../../../../utils/handlerApp";
import { useNavigate, useParams } from "react-router-dom";
import HeaderViewScreen from "../../HeaderScreen/HeaderViewScreen";
import { useEffect, useState } from "react";
import ViewField from "../../componentes/ViewField";


const CompaniesView = ()=>{

    const endpoint      = "companies";
    const prefix        = "company";
    const api           = useFetch();
    const handlerApp    = HandlerApp();
    const nav           = useNavigate();
    const [t]           = useTranslation("global");

    const title         = t('companyComponent.company');
    const params        = useParams();
    const id            = params?.id;

    const [data,setData]    = useState([]);

    const actions = [
        {name: t("companyComponent.updateCompany"), endpoint: `/${endpoint}/edit/${id}`, icon: "fa-pen"}
    ];

    useEffect(()=>{
        sendRequest();
    },[])

    const sendRequest = ()=>{
        handlerApp.setLoader(true);
        api.get(endpoint+"/"+id)
        .then(response =>{
            if(response.success){
                handlerApp.setLoader(false);
                let data = response.data[0];
                setData(data);
            }else{
                handlerApp.setLoader(false);
                handlerApp.handlerResponse(response);
            }
        })
        .catch(error=>{
            handlerApp.setLoader(false);
            handlerApp.showError(t(error.message));
        })
    }

    return(
        <div className="app container">
            <HeaderViewScreen title={title} actions={actions}/>
            <section className="info-entity d-flex row w-100 m-auto mt-4">
                <div className="col-md-6">
                    <div className="col-md-12 mb-3">
                        <ViewField label={t("companyComponent.legalName")} value={data.company_name} />
                    </div>
                    <div className="col-md-12 mb-3">
                        <ViewField label={t("name")} value={data.company_short_name} />
                    </div>
                    <div className="col-md-12 mb-3">
                        <ViewField label={t("taxId")} value={data.company_identification} />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="col-md-12 mb-3">
                        <ViewField label={t("website")} value={data.company_website} />
                    </div>
                    <div className="col-md-12 mb-3">
                        <ViewField label={t("phone")} value={data.company_phone} />
                    </div>
                    <div className="col-md-12">
                        <ViewField label={t("country")} value={data.country_code} />
                    </div>
                </div>
                {/* <div className="footer-section-view row">
                    <span className="divisor"></span>
                    <div className="col-md-6">
                        <span className="text-gray light small-text">{t('createdBy')}: {location.location_created_by}</span>
                    </div>
                    <div className="col-md-6">
                        <span className="text-gray light small-text">{t('createdAt')}: {handlerApp.getDate(location.location_created_at)}</span>
                    </div>
                    <div className="col-md-6">
                        {location.location_modified_by !== null && <span className="text-gray light small-text">{t('modifiedBy')}: {location.location_modified_by}</span>}
                    </div>
                    <div className="col-md-6">
                        {location.location_modified_at !== null && <span className="text-gray light small-text">{t('modifiedAt')}: {handlerApp.getDate(location.location_modified_at)}</span>}
                    </div>
                </div> */}
            </section>
        </div>
    )
}
export default CompaniesView;