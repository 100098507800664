import React from "react";
import { useTranslation } from "react-i18next";
import './BodyReportAttitudModal.css'


const BodyInactiveClientModal = ({ onSubmit }) => {
    const [t] = useTranslation("global");

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit({ customer_active: 0 });
    };

    return (
        <form id="inactiveClientForm" onSubmit={handleSubmit}>
            <div className="form-group mt-2">
                <p>{t("customers.modalInactiveDescription")}</p>
            </div>
        </form>
    );
};

export default BodyInactiveClientModal;