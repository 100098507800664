import React, { useState } from "react";
import "./RightModal.css";

const RightModal = ({title, body, visible, setOptionsVisible, footer}) =>{
       
    return(
            visible &&
            <div className="selection-oppacity">
                <section className="rightmodal-aside">
                    <div className="rightmodal-header">
                        <h3 className="rightmodal-title">{title}</h3>
                        <button className="rightmodal-close-button" onClick={()=>{setOptionsVisible(!visible)}}> <span className="material-symbols-outlined">close</span> </button>
                    </div>
                    <div className="rightmodal-body">
                        {body}
                    </div>
                    {footer &&
                        <div className="rightmodal-footer"> 
                            {footer}
                        </div>
                    }
                </section>
            </div>
    );

}
export default RightModal;