/**
 * @author Victor Duran 
 * @description (Ajustado) Componente para utilizar switch dentro de las pantallas de ver
 * @param String labelLeft - Texto que acompaña el input en la parte izquierda
 * @param String labelRigth - Texto que acompaña el input en la parte derecha
 * @param String id - identificador del elemento a modificar para el form
 * @param String name - name del elemento a modificar para el form
 * @version 1.0.0
 * @since 2024-10-03
 */

const SwitchObjectView = ({labelLeft, labelRight, id, name, value,}) =>{
    return(
        <div className="contai-checkbox">
            <p className="flex justify-content-center mx-1 p3ParagraphRegular14">{labelLeft}</p>
            <input type="checkbox" id={id} name={name} className="mi-checkbox" checked={value} />
            <label htmlFor={id} className="custom-checkbox"></label>
            <p className="flex justify-content-center mx-1 p3ParagraphRegular14">{labelRight}</p>
        </div>
    )
}
export default SwitchObjectView;