import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import './BodyNoteModal.css'
import HandlerApp from "../../../utils/handlerApp";
import { AppContext } from "../../../context/AppContext";
import { useFetch } from "../../../hooks/useFecth";

const BodyNoteModal = ({ onCreate, customerId}) => {
    const [t] = useTranslation("global");
    const [noteType, setNoteType] = useState("");
    const [description, setDescription] = useState("");
    const api = useFetch();
    const handlerApp = HandlerApp();
    const { app } = useContext(AppContext);

    const handleNoteTypeChange = (e) => {
        setNoteType(e.target.value);
    };

    const handleDescriptionChange = (e) => {
        setDescription(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault(); 
        const data = { noteType, description };
        await handleCreateNote(data); 
    };

    const handleCreateNote = async (data) => {
        handlerApp.setLoader(true);
        try {
            const payload = {
                customernote_type: data.noteType,
                customer_id: customerId,
                customernote_note: data.description
            };
    
    
            const response = await api.post("/customers/notes", payload);
    
            if (response.success) {
                handlerApp.showOk(response);
                onCreate(response.data);
            } else {
                handlerApp.handlerResponse(response);
            }
        } catch (error) {
            handlerApp.showError(t(error.message));
        } finally {
            handlerApp.setLoader(false);
        }
    };

    return (
        <form id="createNoteForm" onSubmit={handleSubmit}>
            <div className="form-group mt-2">
                <div className="select-container">
                    <div className={`select-placeholder ${noteType === "" ? "visible" : "hidden"}`}>
                        {t('storageComponent.storageType')}
                    </div>
                        <select 
                            id="noteType" 
                            value={noteType} 
                            onChange={handleNoteTypeChange}
                            className="form-control"
                            required
                        >
                            <option value="" disabled hidden>
                            </option>
                            <option value="Notes">{t('notes.title')}</option>
                            <option value="Medication">{t('notes.noteMedication')}</option>
                            <option value="Allergies">{t('notes.noteAllergies')}</option>
                        </select>
                    </div>
                    <div className="form-group mt-4">
                        <textarea 
                            id="description" 
                            value={description} 
                            onChange={handleDescriptionChange}
                            className="form-control"
                            rows="4"
                            placeholder={t('taxesComponent.description')}
                            required
                        />
                    </div>
                </div>
        </form>
    );
};

export default BodyNoteModal;