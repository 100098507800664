import HandlerApp from "../../../../../utils/handlerApp";
import DragAndDropChildren from '../../../../atoms/LineUp/DragAndDropChildren';
import { useFetch } from "../../../../../hooks/useFecth";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import { Divider } from "@mui/material";
import CentralModal from "../../../../atoms/CentralModal/CentralModal";
import ButtonPrimary from "../../../../atoms/Buttons/ButtonPrimary";
import ButtonOutline from "../../../../atoms/Buttons/ButtonOutline";

const BookingLineUpCategoriesScreen = () => {
    const api        = useFetch();
    const nav        = useNavigate();
    const handlerApp = HandlerApp();
    const [t]        = useTranslation("global");
    const [title]    = useState(t('servicesComponent.bookingLineUp'));
    const endpoint   = "services/categories";
    
    const [data, setData] = useState([]);
    const [optionsVisible, setOptionsVisible] = useState(false);

    

    useEffect(() => {
        sendRequest();
    }, [])

    const handleCancel = (e) => {
        e.preventDefault();
        nav("/services");
    }

    const sendRequest = () => {
        handlerApp.setLoader(true);
        api.get(endpoint)
        .then(response => {
            if(response.success){
                let d = response.data;
                setData(d);
                handlerApp.setLoader(false);
            }else{
                setData([])
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const handleUpdateForm = () => {
        handlerApp.setLoader(true);
        api.put('services/categories/booking_line_up', data)
        .then(response => {
            if (response.success) {
                handlerApp.showOk(response);
                handlerApp.setLoader(false);
                sendRequest();
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const closeModal = () => {
        setOptionsVisible(false);
    };

    return (
        <div className="app container">

            <div className="d-flex justify-content-between">
                <section className="section-title-page">
                    <h3 className="title-page ps-3">{t(title)}</h3>
                </section>
                <div className="d-flex justify-content-center align-items-center">
                    <div className="d-flex align-items-center">
                        <i className="fa-regular fa-xmark close-icon" onClick={() => setOptionsVisible(true)}/>
                        <p className="mb-0 close-icon" role="button" onClick={() => setOptionsVisible(true)}>
                        {t("cancel")}
                        </p>
                    </div>
                </div>
            </div>

            {/* TODO:AQUI VA UN NAVBAR */}
            
            <div>
                <DragAndDropChildren
                    data={data}
                    setData={setData}
                    keyString="servicecategory_id"
                    name="servicecategory_name"
                    keyOrder="servicecategory_order"
                    children="services"
                    childrenKeyString="servicelocation_id"
                    childrenName="service_name"
                    childrenKeyOrder="servicelocation_order"
                    childrenImage="service_image" 
                />
            </div>
            <Divider />
            <div className="row d-flex justify-content-end mt-4">
                <div className="d-flex justify-content-end">
                    <button className="btn btn-primary-yellow-1" onClick={handleUpdateForm}>
                        {t('save')}
                    </button>
                </div>
            </div>
            <CentralModal
                title={t("servicesComponent.titleCancelLineUp")}
                body={
                    <p className='text-description-cancel-line-up'>
                        {t("servicesComponent.descriptionClose")}
                    </p>
                } 
                footer={[
                    <ButtonOutline label={t('customers.view.noContinue')} onClick={closeModal}  />,
                    <p className="me-3"></p>,
                    <ButtonPrimary label={t('servicesComponent.cancelLineUp')} onClick={handleCancel} />
                ]}
                visible={optionsVisible} 
                setOptionsVisible={setOptionsVisible} 
            />
        </div>
    );
}

export default BookingLineUpCategoriesScreen;