import "./PaymentMethods.css"
import moment from "moment";
import DataTable from "react-data-table-component";
import RightModal from "../../atoms/RightModal/RightModal";
import HandlerApp from "../../../utils/handlerApp";
import NavLinkView from "../../Migrar/componentes/navbar/NavLinkView";
import CentralModal from "../../atoms/CentralModal/CentralModal";
import BodyNoteModal from "../../atoms/RightModal/BodyNoteModal";
import ButtonOutline from "../../atoms/Buttons/ButtonOutline";
import ButtonPrimary from "../../atoms/Buttons/ButtonPrimary";
import HeaderViewScreen from "../../Migrar/HeaderScreen/HeaderViewScreen";
import CardComponentPay from "../../atoms/Cards/CardMethodPay/CardComponentPay";
import BodyBlockClientModal from "../../atoms/RightModal/BodyBlockClientModal";
import BodyUnlockClientModal from "../../atoms/RightModal/BodyUnlockClientModal";
import BodyActiveClientModal from "../../atoms/RightModal/BodyActiveClientModal";
import BodyDeleteClientModal from "../../atoms/RightModal/BodyDeleteClientModal";
import TransactionDetailModal from "../../molecules/toRender/TransactionDetailModal";
import BodyReportAttitudModal from "../../atoms/RightModal/BodyReportActtitudModal";
import BodyInactiveClientModal from "../../atoms/RightModal/BodyInactiveClientModal";
import { useFetch } from "../../../hooks/useFecth";
import { useState } from "react";
import { DatePicker } from "@mui/x-date-pickers";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

const PaymentMethods = () => {

    const [t]        = useTranslation("global");
    const title      = t("customers.viewClient");
    const endpoint   = 'customers';
    const api        = useFetch();
    const nav        = useNavigate();
    const handlerApp = HandlerApp();

    const params     = useParams();
    const id         = params?.id;

    const [endDate, setEndDate]                             = useState(null);
    const [startDate, setStartDate]                         = useState(null);
    const [openModal, setOpenModal]                         = useState(false);
    const [modalType, setModalType]                         = useState(null);
    const [filterText, setFilterText]                       = useState('');
    const [selectedRow, setSelectedRow]                     = useState({});
    const [optionsVisible, setOptionsVisible]               = useState(false);
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [customerActive, setCustomerActive] = useState(localStorage.getItem('customer_active'));
    const [customerBlock, setCustomerBlock]  = useState(localStorage.getItem('customer_blocked'));

    //TODO: Se debe cambiar popr informacion del backend
    const [data, setData] = useState([
        {
            id: 1,
            date: '2024-07-23 00:48:28.233389+00',
            type: 'Appoinment',
            value: 80,
            transaction_type: 'CREDIT',
            name: 'John Doe',
            phone: '123-456-7890',
            email: 'johndoe@example.com',
            card_type: 'Credit',
            last4: '1234',
            transactionId: '1234567890123',
            aproveId: '123456789012345'
        },
        {
            id: 2,
            date: '2024-07-24 00:48:28.233389+00',
            type: 'Sale',
            value: 80,
            transaction_type: 'GIFT',
            name: 'Jane Smith',
            phone: '234-567-8901',
            email: 'janesmith@example.com',
            card_type: 'Debit',
            last4: '2345',
            transactionId: '2345678901234',
            aproveId: '234567890123456'
        },
        {
            id: 3,
            date: '2024-07-25 00:48:28.233389+00',
            type: 'Sale',
            value: 80,
            transaction_type: 'UPI',
            name: 'Alice Johnson',
            phone: '345-678-9012',
            email: 'alicejohnson@example.com',
            card_type: 'Credit',
            last4: '3456',
            transactionId: '3456789012345',
            aproveId: '345678901234567'
        },
        {
            id: 4,
            date: '2024-07-26 00:48:28.233389+00',
            type: 'Sale',
            value: 80,
            transaction_type: 'STORE',
            name: 'Bob Brown',
            phone: '456-789-0123',
            email: 'bobbrown@example.com',
            card_type: 'Debit',
            last4: '4567',
            transactionId: '4567890123456',
            aproveId: '456789012345678'
        },
        {
            id: 5,
            date: '2024-07-27 00:48:28.233389+00',
            type: 'Sale',
            value: 80,
            transaction_type: 'STRIPE',
            name: 'Carol White',
            phone: '567-890-1234',
            email: 'carolwhite@example.com',
            card_type: 'Credit',
            last4: '5678',
            transactionId: '5678901234567',
            aproveId: '567890123456789'
        }
    ]);

    const [cards, setCards] = useState([{
        id: 1,
        number: 1234,
        type: 'MC',
        nombre: 'Axis Bank'
    },
    {
        id: 2,
        number: 5678,
        type: 'MC',
        nombre: 'Axis Bank'
    },
    {
        id: 3,
        number: 9012,
        type: 'MC',
        nombre: 'Axis Bank'
    }])


    const createNote = () => {
        setModalType('createNote');
        setOptionsVisible(true);
    };

    const reportAttitude = () => {
        setModalType('reportAttitude');
        setOptionsVisible(true);
    };

    const blockClient = () => {
        setModalType('blockClient');
        setOptionsVisible(true);
    };

    const activeClient = () => {
        setModalType('activeClient');
        setOptionsVisible(true);
    };

    const closeModal = () => {
        setOptionsVisible(false);
    };

    const deleteClient = () => {
        setModalType('deleteClient');
        setOptionsVisible(true);
    };

    const inactiveClient = () => {
        setModalType('inactiveClient');
        setOptionsVisible(true);
    };

    const unlockClient = () => {
        setModalType('unlockClient');
        setOptionsVisible(true);
    };

    const actions = [
        {name: t("customers.actionView.edit"), endpoint: `/${endpoint}/create`, icon: "fa-pen"},
        {name: t("customers.actionView.createNote"), function: createNote, icon: "fa-note-sticky"},
        {name: t("customers.actionView.messageClient"), endpoint: `/${endpoint}/categories/create`, icon: "fa-message"},
        {name: t("customers.actionView.callClient"), endpoint: `/${endpoint}/categories/create`, icon: "fa-phone"},
        {name: t("customers.actionView.createGiftcard"), endpoint: `/${endpoint}/categories/create`, icon: "fa-gift-card"},
        {name: t("customers.actionView.createAppointment"), endpoint: `/${endpoint}/categories/create`, icon: "fa-calendar-plus"},
        {name: t("customers.actionView.createSale"), endpoint: `/${endpoint}/categories/create`, icon: "fa-cash-register"},
        {name: t("customers.actionView.addFamily"), endpoint: `/${endpoint}/${id}/create/known`, icon: "fa-user-plus"},
        {name: t("customers.view.reportAttitude"),  function: reportAttitude, icon: "fa-face-zipper"},
        {name: customerActive == 1 ? t("customers.view.inactiveClient") : t("customers.view.activeClient"),  function: customerActive == 1 ? inactiveClient : activeClient, icon: "fa-play"},
        {name: t("customers.actionView.delete"), function: deleteClient,  icon: "fa-trash", red: true},
        {name: customerBlock == 1 ? t("customers.actionView.unlockClient") : t("customers.actionView.blockClient"), function: customerBlock == 1 ? unlockClient : blockClient, icon: "fa-ban", red: true},
    ];

    const nav_actions = [
        {name: t("menuCustomer.overview"),    to: `/customers/${id}/overview`},
        {name: t("menuCustomer.profile"),     to: `/customers/${id}/profile`},
        {name: t("menuCustomer.appointment"), to: `/customer/appointments/${id}`},
        {name: t("menuCustomer.payment"),     to: `/payment-methods/${id}`},
        {name: t("menuCustomer.products"),    to: `/customers/products/${id}`}
    ];

    const columns = [
        {
            id: 'transactions_date',
            name: t('paymentMethods.date'),
            selector: row => row.date ? row.date.format('DD/MM/YYYY') : '',
            sortable: true
        },
        {
            id: 'transactions_type',
            name: t('paymentMethods.type'),
            selector: row => row.type,
            sortable: true
        },
        {
            id: 'transactions_value',
            name: t('paymentMethods.value'),
            selector: row => handlerApp.formatCurrency(row.value),
            sortable: true
        },
        {
            name: t("action"),
            cell: (row) => {
                return (
                    <span className="col-10">
                        <ButtonOutline
                            label={<span >
                                <i className="fa-regular fa-eye me-2"></i>
                                {t('paymentMethods.viewDetails')}
                            </span>}
                            onClick={() => { setOpenModal(true); setSelectedRow(row) }}
                            type={false}
                        />
                    </span>)
            },
            ignoreRowClick: true,
            button: true,
            minWidth: "160px"
        }
    ];

    const processedData = data.map(item => ({
        ...item,
        date: moment(item.date)
    }));

    const filteredItems = processedData?.filter(
        item => {
            const matchesTextFilter = item.id && item.id.toString().toLowerCase().includes(filterText.toLowerCase())
                || item.type.toLowerCase().includes(filterText.toLowerCase())
                || item.transaction_type.includes(filterText)
            const itemDate = item.date;
            const startDateMoment = startDate ? moment(startDate) : null;
            const endDateMoment = endDate ? moment(endDate) : null;

            const isWithinDateRange = (!startDateMoment || itemDate.isSameOrAfter(startDateMoment))
                && (!endDateMoment || itemDate.isSameOrBefore(endDateMoment));


            return matchesTextFilter && isWithinDateRange;
        }
    );

    const buttonClass = (filter) => {
        return `button-selector-filter-payment button-color-payment ${filterText === filter && 'selected-button-payment'}`;
    };

    const handleCreateNote = (noteData) => {
        setOptionsVisible(false);
    };

    const handleCreateNoteClick = () => {
        const formElement = document.getElementById('createNoteForm');
        if (formElement) {
            formElement.requestSubmit();
        }
    };

    const handleReportAttitudeClick = () => {
        const formElement = document.getElementById('reportAttitudeForm');
        if (formElement) {
            formElement.requestSubmit();
        }
    };

    const handleActivateClientClick = () => {
        const formElement = document.getElementById('activateClientForm');
        if (formElement) {
            formElement.requestSubmit();
        }
    };

    const handleBlockClientClick = () => {
        const formElement = document.getElementById('blockClientForm');
        if (formElement) {
            formElement.requestSubmit();
        }
    };

    const handleReportAttitude = async (reportData) => {
        handlerApp.setLoader(true);
        try {
            const dataToSend = {
                customer_actitude: reportData.customer_actitude
            };
                
            const response = await api.put(`customers/${id}/actitude`, dataToSend);
    
            if (response.success) {
                handlerApp.showOk({ message: t('customers.reportSuccess') });
            } else {
                handlerApp.handlerResponse(response); 
            }
        } catch (error) {
            handlerApp.showError(t(error.message)); 
        } finally {
            handlerApp.setLoader(false);
            setOptionsVisible(false); 
        }
    };

    const handleActivateClient = async () => {
        handlerApp.setLoader(true);
        try {
            const payload = {
                customer_active: 1
            };
    
            const response = await api.put(`customers/${id}/active`, payload);
    
            if (response.success) {
                handlerApp.showOk({ message: t('customers.clientActivated') });
                localStorage.setItem('customer_active', 1);
                setCustomerActive(1)
            } else {
                handlerApp.handlerResponse(response);
            }
        } catch (error) {
            handlerApp.showError(t(error.message));
        } finally {
            handlerApp.setLoader(false);
            setOptionsVisible(false);
        }
    };

    const handleBlockClient = async (blockData) => {
        handlerApp.setLoader(true);
        try {
            const dataToSend = {
                customer_blocked: 1,
                ...blockData
            };
    
            const response = await api.put(`customers/${id}/blocked`, dataToSend);
    
            if (response.success) {
                handlerApp.showOk({ message: t('customers.blockSuccess') });
                localStorage.setItem('customer_blocked', 1);
                setCustomerBlock(1)
            } else {
                handlerApp.handlerResponse(response); 
            }
        } catch (error) {
            handlerApp.showError(t(error.message)); 
        } finally {
            handlerApp.setLoader(false);
            setOptionsVisible(false); 
        }
    };

    const handleDeleteClient = async () => {
        handlerApp.setLoader(true);
        try {
            const response = await api.deleteMethod(`customers/${id}`);
    
            if (response.success) {
                handlerApp.showOk({ message: t('customers.clientDelete') });
                nav("/" + endpoint);
            } else {
                handlerApp.handlerResponse(response);
            }
        } catch (error) {
            handlerApp.showError(t(error.message));
        } finally {
            handlerApp.setLoader(false);
            setOptionsVisible(false);
        }
    };

    const handleDeleteClientClick = () => {
        const formElement = document.getElementById('deleteClientForm');
        if (formElement) {
            formElement.requestSubmit();
        }
    };

    const handleInactivateClient = async () => {
        closeModal();
        handlerApp.setLoader(true);
        try {
            const payload = {
                customer_active: 0
            };
    
            const response = await api.put(`customers/${id}/active`, payload);
    
            if (response.success) {
                handlerApp.showOk({ message: t('customers.clientInactivated') });
                localStorage.setItem('customer_active', 0);
                setCustomerActive(0);
            } else {
                handlerApp.handlerResponse(response);
            }
        } catch (error) {
            handlerApp.showError(t(error.message));
        } finally {
            handlerApp.setLoader(false);
            setOptionsVisible(false);
        }
    };

    const handleInactivateClientClick = () => {
        const formElement = document.getElementById('inactiveClientForm');
        if (formElement) {
            formElement.requestSubmit();
        }
    };

    const handleUnlockClient = async () => {
        closeModal();
        handlerApp.setLoader(true);
        try {
            const payload = {
                customer_blocked : 0
            };
    
            const response = await api.put(`customers/${id}/unlock`, payload);
    
            if (response.success) {
                handlerApp.showOk({ message: t('customers.clientUnlock') });
                localStorage.setItem('customer_blocked', 0);
                setCustomerBlock(0);
            } else {
                handlerApp.handlerResponse(response);
            }
        } catch (error) {
            handlerApp.showError(t(error.message));
        } finally {
            handlerApp.setLoader(false);
            setOptionsVisible(false);
        }
    };

    const handleUnlockClientClick = () => {
        const formElement = document.getElementById('unlockClientForm');
        if (formElement) {
            formElement.requestSubmit();
        }
    };

    return (
        <>
            <RightModal
                title={t("modalDetailPaymentMethods.detail")}
                body={<TransactionDetailModal data={selectedRow} />}
                footer={
                    <div className="d-flex w-100 gap-2">
                        <ButtonOutline label={
                            <section className="d-flex justify-content-center align-items-center gap-1">
                                <i className="fa-regular fa-circle-xmark icon-size-payment"></i>
                                {t("modalDetailPaymentMethods.void")}
                            </section>} />
                        <ButtonPrimary label={
                            <section className="d-flex justify-content-center align-items-center gap-1">
                                <i className="fa-solid fa-arrow-rotate-left icon-size-payment color-icon-payment"></i>
                            {t("modalDetailPaymentMethods.refund")}
                        </section>} />
                    </div>
                }
                visible={openModal}
                setOptionsVisible={setOpenModal}
            />
            <div className="app container">
                <HeaderViewScreen title={title} actions={actions}/>

                <NavLinkView actions={nav_actions} active={3}/>

                <section className="filters">
                    <h4>{t('paymentMethods.cards')}</h4>
                    {cards.map(card => {
                        return (
                            <CardComponentPay 
                                name={card.nombre}
                                numberCard={card.number}
                                type={card.type}
                                handleFunction={()=>{}}
                                id={card.id}
                            />
                        )
                    })}
                </section>
                <section className="filters">
                    <h4>{t('paymentMethods.transactions')}</h4>
                    <section className="col-md-12 my-4 container-buttons-payment">
                        <button className={buttonClass('CREDIT')} onClick={() => setFilterText('CREDIT')}>{t('paymentMethods.credit')}</button>
                        <button className={buttonClass('GIFT')} onClick={() => setFilterText('GIFT')}>{t('paymentMethods.gift')}</button>
                        <button className={buttonClass('UPI')} onClick={() => setFilterText('UPI')}>UPI</button>
                        <button className={buttonClass('STORE')} onClick={() => setFilterText('STORE')}>{t('paymentMethods.store')}</button>
                        <button className={buttonClass('STRIPE')} onClick={() => setFilterText('STRIPE')}>{t('paymentMethods.stripe')}</button>
                    </section>
                    <div className="row col-md-6">
                        <div className="col-md-6">
                            <DatePicker
                                format="DD/MM/YYYY"
                                label={t('paymentMethods.from')}
                                onChange={(e) => setStartDate(e)} />
                        </div>
                        <div className="col-md-6">
                            <DatePicker
                                format="DD/MM/YYYY"
                                label={t('paymentMethods.end')}
                                onChange={(e) => setEndDate(e)} />
                        </div>
                    </div>
                </section>
                <DataTable
                    columns={columns}
                    data={filteredItems}
                    pagination
                    paginationResetDefaultPage={resetPaginationToggle}
                    persistTableHead
                />

            </div>

            {modalType === 'createNote' && (
                <RightModal 
                    title={t("customers.actionView.createNote")} 
                    body={<BodyNoteModal onCreate={handleCreateNote} customerId={data.customer_id}/>} 
                    visible={optionsVisible} 
                    setOptionsVisible={setOptionsVisible} 
                    footer={<ButtonPrimary label={t('create')} onClick={handleCreateNoteClick}/>}
                />
            )}

            {modalType === 'reportAttitude' && (
                <CentralModal 
                    title={t("customers.view.reportAttitude")} 
                    body={<BodyReportAttitudModal onSubmit={handleReportAttitude} />} 
                    visible={optionsVisible} 
                    setOptionsVisible={setOptionsVisible} 
                    footer={<ButtonPrimary label={t('report')} onClick={handleReportAttitudeClick} />}
                />
            )}

            {modalType === 'blockClient' && (
                <RightModal 
                    title={t("customers.actionView.blockClient")} 
                    body={<BodyBlockClientModal onSubmit={handleBlockClient} />} 
                    visible={optionsVisible} 
                    setOptionsVisible={setOptionsVisible} 
                    footer={<ButtonPrimary label={t('block')} onClick={handleBlockClientClick}/>}
                />
            )}

            {modalType === 'activeClient' && (
                <CentralModal 
                    title={t("customers.view.activeClient")} 
                    body={<BodyActiveClientModal onSubmit={handleActivateClient} />} 
                    visible={optionsVisible} 
                    setOptionsVisible={setOptionsVisible} 
                    footer={[
                        <ButtonOutline label={t('customers.view.noContinue')} onClick={closeModal}  />,
                        <p className="me-3"></p>,
                        <ButtonPrimary label={t('customers.view.yesActive')} onClick={handleActivateClientClick} />
                    ]}
                />
            )}

            {modalType === 'deleteClient' && (
                <CentralModal 
                    title={t("customers.view.deleteClient")} 
                    body={<BodyDeleteClientModal onSubmit={handleDeleteClient} closeModal={closeModal}/>} 
                    visible={optionsVisible} 
                    setOptionsVisible={setOptionsVisible} 
                    footer={[
                        <ButtonOutline label={t('customers.view.noContinue')} onClick={closeModal}  />,
                        <p className="me-3"></p>,
                        <ButtonPrimary label={t('customers.view.yesDelete')} onClick={handleDeleteClientClick} />
                    ]}
                />
            )}

            {modalType === 'inactiveClient' && (
                <CentralModal 
                    title={t("customers.view.inactiveClient")} 
                    body={<BodyInactiveClientModal onSubmit={handleInactivateClient} />} 
                    visible={optionsVisible} 
                    setOptionsVisible={setOptionsVisible} 
                    footer={[
                        <ButtonOutline label={t('customers.view.noContinue')} onClick={closeModal}  />,
                        <p className="me-3"></p>,
                        <ButtonPrimary label={t('customers.view.yesInactive')} onClick={handleInactivateClientClick} />
                    ]}
                />
            )}

            {modalType === 'unlockClient' && (
                <CentralModal 
                    title={t("customers.actionView.unlockClient")} 
                    body={<BodyUnlockClientModal onSubmit={handleUnlockClient} />} 
                    visible={optionsVisible} 
                    setOptionsVisible={setOptionsVisible} 
                    footer={[
                        <ButtonOutline label={t('customers.view.noContinue')} onClick={closeModal}  />,
                        <p className="me-3"></p>,
                        <ButtonPrimary label={t('customers.view.yesUnlock')} onClick={handleUnlockClientClick} />
                    ]}
                />
            )}
        </>
    );
}

export default PaymentMethods;