import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router';
import router from './router/Router';

import "./assets/css/Template.css";
import "./assets/css/TemplateDark.css";
import "./assets/css/fontawesome/css/all.min.css";
import "./components/Migrar/assets/reset-styles.css";
import "./components/Migrar/assets/barber-styles.css";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <RouterProvider router={router} />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
