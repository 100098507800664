import { useEffect, useState } from "react";
import HandlerApp from "../../../../utils/handlerApp";
import RightModal from "../../../atoms/RightModal/RightModal";
import ButtonPrimary from "../../../atoms/Buttons/ButtonPrimary";
import ButtonOutline from "../../../atoms/Buttons/ButtonOutline";
import HeaderViewScreen from "../../HeaderScreen/HeaderViewScreen";
import BodyBillingPayModal from "../../../atoms/RightModal/BodyBillingPayModal";
import { useFetch } from "../../../../hooks/useFecth";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./BillingDetailsAndInvoicesScreen.css";
import CardPaymentMethod from "../../../atoms/Cards/CardMethodPay/CardPaymentMethod";


/**
 * @author Vicente Bolivar
 * @version 1.0.0
 * @description pantalla de configuracion de pagos 
*/

const ManagePaymentMothodScreen = () => {
    const endpoint = "settings/services-charges"; //definir endpoint correspondiente cuando este creado
    const parent = "Settings";
    const [t] = useTranslation("global");
    const title = t('billing.managePayment');
    const api = useFetch();
    const nav = useNavigate();
    const handlerApp = HandlerApp();
    const [data, setData] = useState([]);
    const [defaultCard, setDefaultCard] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);

    useEffect(() => {
        sendRequest();
    }, []);

    const closeModal = () => {
        setIsModalVisible(false);
    };

    const handleCreate = () => {
        setIsModalVisible(true);
    };

    const handleSetDefault = (cardId) => {
        setDefaultCard(cardId);
    };

    const sendRequest = () => {
        handlerApp.setLoader(true);
        api.get(endpoint)
        .then(response => {
            if (response.success) {
                let dataResponse = response.data;
                setData(dataResponse);
                handlerApp.setLoader(false);
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        });
    }


    return (
        <div className="app container">
            <div className="d-flex justify-content-between">
                <HeaderViewScreen title={title} actions={""}/>
            </div>
            <span className="light descriptionServiceCharges">{t("billing.managePaymentDescription")}</span>
            <div className="row mt-4">
                <div className="col-md-6">
                    <div className="settingsCard-section border p-3 mb-3">
                    <div className="paddingCardPay">
                        <CardPaymentMethod
                            brand={"VisaLight"}
                            last4={'5678'}
                            isDefault={defaultCard === 1} 
                            setDefault={() => handleSetDefault(1)}
                            buttonDelete={() => console.log("Eliminar tarjeta 1")}
                        />
                    </div>
                        <button 
                            className='buttonOutline w-auto me-2 pb-1 pt-1' 
                            onClick={handleCreate}
                        >
                            <i className="fa-regular fa-plus pe-2"></i>
                            <span className="w-text">{t("billing.buttonCreatePay")}</span>
                        </button>
                    </div>
                </div>
            </div> 


            <RightModal
                title={ t("billing.buttonCreatePay")}
                body={<BodyBillingPayModal />}
                visible={isModalVisible}
                setOptionsVisible={setIsModalVisible}
                footer={[
                <ButtonOutline label={t("cancel")} onClick={closeModal} />,
                    <p className="me-3"></p>,
                <div className="buttonBilling mt-1">
                    <ButtonPrimary
                        label={t("save")}
                        onClick={() => console.log("Save action")}
                    />
                </div>
                ]}
            />
    </div>
  );
};

export default ManagePaymentMothodScreen;