import './DragAndDropInternal.css';
import Stack from '@mui/material/Stack';
import { CSS } from "@dnd-kit/utilities";
import { useSortable } from "@dnd-kit/sortable";
import { useCallback } from "react";
import { DndContext, closestCenter } from "@dnd-kit/core";
import { SortableContext, verticalListSortingStrategy, arrayMove } from "@dnd-kit/sortable";

/**
 * @author Victor Duran 
 * @description Componente interno de ordenamiento este se debe implementar junto a su padre (DragAndDropChildren)
 * @param array internalData - Informacion que se va a ordenar
 * @param callback setInternalData - Me permite actualizar la información
 * @param string keyString - identificador unico (primary key de la información que se va a ordenar)
 * @param string name - Campo que se va a visualizar en el componente
 * @param string keyOrder - Campo que me indica el orden de la información (Es el campo en BD en el cual se guarda el ordenamiento)
 * @param string image - Imagen que se visualiza en el componente
 * @param string index - posición del padre
 * @param callback  setData - Me permite actualizar la información del padre
 * @param string  field - nombre que me identifica el array de objeto dentro de la información principal
 * @since 23-09-2024
 */
const DragAndDropInternal = ({ internalData, setInternalData, keyString, name, image, keyOrder,index,setData,field }) => {

    const handleDragEnd = useCallback((event) => {
        const { active, over } = event;
    
        if (active.id !== over.id) {
            setInternalData((prevInternalData) => {
                const oldIndex = prevInternalData.findIndex((row) => row.id === active.id);
                const newIndex = prevInternalData.findIndex((row) => row.id === over.id);
                
                const newInternalData = arrayMove(prevInternalData, oldIndex, newIndex).map((item, idx) => ({
                    ...item,
                    [keyOrder]: idx + 1
                }));

                setData((prevData) => 
                    prevData.map((d, i) => 
                        i === index 
                        ? { ...d, [field]: newInternalData }
                        : d
                ));
        
                return newInternalData;
            });
        }
    }, [setData, index, field, keyOrder]);

    const DragComponent = ({ item,i }) => {

        const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: item[keyString] });
        const style = {
            transform: CSS.Transform.toString(transform),
            transition,
        };

        return (
            <section style={style} className="cardStyleInternalLineup cardColorLineup my-2">
                <Stack direction="row" spacing={2} style={{ alignItems: "center", padding: 10 }} ref={setNodeRef} {...attributes} {...listeners}>
                    <i class="fa-regular fa-bars icon-bars"/>
                    <section className='textContainerStyleLineup'>
                        <p className="textCardLineup">{i + 1}</p>
                    </section>
                    {
                        item[image] === null ?
                            <div className="d-flex justify-content-center align-items-center imageLineup" >
                                <i className="fa-regular fa-image-slash" style={{fontSize:30}}></i>
                            </div>
                             :
                            <img className="imageLineup" src={item[image]} alt="" />
                    }
                    <section className='textContainerStyleLineup'>
                        <p className="textCardLineup">{item[name]}</p>
                    </section>
                </Stack>
            </section>);
    }

    return (
        <div className="flex justify-center items-center">
            <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
                <SortableContext items={internalData} strategy={verticalListSortingStrategy}>
                    {internalData.map((item, i) => (
                        <DragComponent key={item.id} item={item} i={i} />
                    ))}
                </SortableContext>
            </DndContext>
        </div>
    );
}

export default DragAndDropInternal;