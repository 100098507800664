import React from 'react';
import './CardWithButtonRight.css';
import { useTranslation } from "react-i18next";

/**
 * @author Roberto Bolivar
 * @version 1.0.0
 * @param title title.
 * @param textTop primera informacion.
 * @param textBottom segunda informacion.
 * @param statusValue indica un estado.
 * @param handleView Recibe una funcion.
 * @param image imagen.
 * @param showImage Mostrar imagen.
 * @param description En este componente puedes designar o no una imagen, lo cuel controlas a traves showImage que se inicializa en true.
*/
const CardWithButtonRight = ({ title, textTop, status, statusValue, textBottom, handleView, image, showImage = true }) => {
    const [t] = useTranslation("global");

    return (
        <div className="apointmentCard col-12 mb-4 d-flex align-items-center justify-content-between">
            <div className="d-flex justify-content-between aling-items-center w-100 mx-3 pt-2 pb-2">
                <div className='d-flex mt-2 me-3 mb-3 ms-2'>
                    {showImage && (
                        <div className="d-flex align-items-end mb-3 me-2" style={{ display: showImage ? "block" : "none" }}>
                            {image ? (
                                <img src={image} alt={""} className="appoitmentCardImage" />
                            ) : (
                                <i class="fa-regular fa-user fa-2x appoitmentCardImage"></i>
                            )}
                        </div>
                    )}
                    <div className="ms-2">
                        <h6 className="card-title pt-3 p3ParagraphSemiBold14">{title}</h6>
                        <p className="text-info-card m-0">{textTop}</p>
                        <p className="text-info-card">{textBottom}</p>
                    </div>
                </div>

                <div className="d-flex flex-column align-items-end justify-content-between col-2 pt-2 pb-2">
                    <p className={`captionSemiBold10 status-container ${status}`}>{statusValue}</p>
                    <button className="customerViewButton px-3 d-flex align-items-center justify-content-center" onClick={handleView}>
                        <image class="fa-regular fa-eye "></image>
                        <p className="m-2 captionRegular12">{t('view')}</p>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CardWithButtonRight;
