import React from "react";
import "./CentralModal.css";

const CentralModal = ({title, body, footer, visible, setOptionsVisible}) =>{
    
    return(
            visible &&
        <div className="centralmodal-overlay">
            <section className="centralmodal-container">
                <div className="centralmodal-header">
                    <h3 className="centralmodal-title">{title}</h3>
                    <button className="centralmodal-close-button" onClick={()=>{setOptionsVisible(!visible)}}> 
                        <span className="material-symbols-outlined">close</span> 
                    </button>
                </div>
                <div className="centralmodal-body">
                    {body}
                </div>
                {footer &&
                    <div className="centralmodal-footer">
                        {footer}
                    </div>
                }
            </section>
        </div>
    );
}
export default CentralModal;