
import { TextField } from "@mui/material";
import { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import { useFetch } from "../../../hooks/useFecth";
import HandlerApp from "../../../utils/handlerApp";
import { useForm } from "react-hook-form";
import SwitchObject from "../../atoms/Inputs/SwitchObject";
import CentralModal from "../../atoms/CentralModal/CentralModal";
import ButtonPrimary from "../../atoms/Buttons/ButtonPrimary";
import ButtonOutline from "../../atoms/Buttons/ButtonOutline";

/**
 * @author Paula Melo
 * @since 2024-09-09
 * @description Formulario para crear/editar categorias de servicios
 * @version 1.0.0
 * @returns 
 */
const ServicesCategoriesForms = () => {
    const endpoint = "services/categories";
    const parent = "Services";
    const [t] = useTranslation("global");
    const [title, setTitle] = useState('servicesCategories.createServicesCategory');
    const api = useFetch();
    const nav = useNavigate();
    const handlerApp = HandlerApp();
    const { app } = useContext(AppContext);
    const { id } = useParams();
    const [data, setData] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    
    const { register, handleSubmit, formState: { errors }, setValue } = useForm()
    const [permissions, setPermissions] = useState([]);
    const [menus, setMenus] = useState([]);
    
    const [formData, setFormData] = useState({
        company_id:"",
        servicecategory_name: "",
        servicecategory_description: "",
        servicecategory_visibility: 0
    });


    useEffect(() => {
        setMenus(app.menus[parent]);
        setPermissions(app.permissions[endpoint])
        handlerApp.setLoader(false)
    }, [app])

    useEffect(() => {
        handlerApp.setLoader(true);
        api.get(endpoint, {
            action: "getParamsUpdate"
        })
        .then(response => {
            if (response.success) {
                if (id !== undefined) {
                    setTitle('servicesCategories.updateServicesCategory')
                    sendRequest();
                } else {
                    handlerApp.setLoader(false);
                }
            } else {
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.setLoader(false);
            handlerApp.showError(t(error.message));
        })
    }, [])

    const handleDependencies = (data) => {
        let d = filterData(data, formData);
        setFormData(d);
    }

    const filterData = (data, formData) => {
        let finalData = {};
        for (let key in formData) {
            finalData[key] = data[key];
            setValue(key, data[key]);
        }
        return finalData;
    }

    const sendRequest = () => {
        api.get(endpoint + "/" + id,)
        .then(response => {
            if (response.success) {
                let data = response.data[0];
                handleDependencies(data);
                handlerApp.setLoader(false);
            } else {
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const handleForm = (e, name = null, dependency = null) => {
        let key = (name !== null) ? name : e.target.name;
        setFormData({
            ...formData,
            [key]: e.target.value
        });
        setValue(key, e.target.value);
        if (dependency !== null) {
            dependency(e.target.value);
        }
    }

    const handleCancel = (e) => {
        e.preventDefault();
        nav(-1);
    }

    const handleSubmitForm = (data) => {
        handlerApp.setLoader(true);
        let body = {
            ...formData,
        }
        api.post(endpoint, body)
        .then(response => {
            if (response.success) {
                handlerApp.showOk(response);
                handlerApp.setLoader(false);
                nav("/" + endpoint);
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const handleUpdateForm = (data) => {
        handlerApp.setLoader(true);
        let body = {
            ...data,
        }
        api.put(endpoint + "/" + id, body)
        .then(response => {
            if (response.success) {
                handlerApp.showOk(response);
                handlerApp.setLoader(false);
                nav("/" + endpoint);
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }
    const handleCheck = (e, name = null, dependency = null) => {
        let key = (name !== null) ? name : e.target.name;
        setFormData({
            ...formData,
            [key]: (e.target.checked) ? 1 : 0
        });
        setValue(key, (e.target.checked) ? 1 : 0 );
    }


    return (
        <>
        <CentralModal
            title={id ? t("cancelEdit") : t("cancelCreate")}
            visible={openModal}
            setOptionsVisible={setOpenModal}
            body={id ? t("servicesCategories.cancelEdition"):t("servicesCategories.cancelCreation")}
            footer={<div className="modalfooter-buttons w-100">
                <ButtonOutline label={t("nocancel")} onClick={()=>setOpenModal(false)}/>
                <ButtonPrimary label={t("yescancel")} onClick={handleCancel}/>
            </div>}
        />
            <div className="app container">
                <div className="d-flex justify-content-between border-bottom mb-4">
                    <section className="section-title-page">
                        <h3 className="title-page ps-3">{t(title)}</h3>
                    </section>
                    <div className="d-flex">
                        <span className="material-symbols-outlined w-icon" role="button" onClick={handleCancel} >close</span><p className="me-5" role="button" onClick={()=>setOpenModal(true)}>{t("cancel")}</p>
                    </div>
                </div>
                <div className="section-form">
                    <form onSubmit={handleSubmit((id === undefined) ? handleSubmitForm : handleUpdateForm)}>
                        <div className="row">
                            <div className="col-md-6 mb-2">
                                <TextField label={t("name")} variant="outlined" size="small" className="barberlytics-textfield"
                                    {...register("servicecategory_name", { required: true, maxLength: 40 })}
                                    value={formData.servicecategory_name}
                                    onChange={handleForm}
                                    error={errors.servicecategory_name != null}
                                    helperText={
                                        errors.servicecategory_name?.type === 'required' ? t("errors.required") :
                                            errors.servicecategory_name?.type === 'maxLength' ? t("errors.maxLength") : ""}
                                />
                                <TextField className="barberlytics-textfield" name="servicecategory_description" onChange={handleForm} value={formData.servicecategory_description} label={t('description')} multiline rows={3} size="small" />
                            </div>
                            <div className="col-md-12 mb-2  ">
                                <p>{t("visibleDescription")}</p>
                                <SwitchObject labelLeft={t("private")} labelRight={t("public")} value={formData.servicecategory_visibility} id={"servicecategory_visibility"} name="servicecategory_visibility" onChange={handleCheck}/>
                            </div>
                            <div className="row d-flex justify-content-end border-top mt-4">
                                <div className="d-flex justify-content-end mt-4">
                                    {id === undefined && <button className="btn btn-primary-yellow-1" type="submit">{t('submit')}</button>}
                                    {id && <button className="btn btn-primary-yellow-1" type="submit">{t('update')}</button>}
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default ServicesCategoriesForms;