
import { TextField } from "@mui/material";
import { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import { useFetch } from "../../../hooks/useFecth";
import { DatePicker } from "@mui/x-date-pickers";
import HandlerApp from "../../../utils/handlerApp";
import { useForm } from "react-hook-form";
import BoldSelectValidated from "../../../utils/BoldSelectValidated";
import moment from "moment/moment";
import NavbarEditBarbers from "../Barbers (users)/NavbarEditBarbers";
import BarberlyticsFilesUpload from "../../../utils/BarberlyticsFilesUpload";
import SwitchObject from "../../atoms/Inputs/SwitchObject";

const UsersForms = () => {
    const endpoint = "team/users";
    const parent = "Team";
    const [t] = useTranslation("global");
    const [title, setTitle] = useState('personComponent.createUser');
    const api = useFetch();
    const nav = useNavigate();
    const [data, setData] = useState([]);
    const handlerApp = HandlerApp();
    const { app } = useContext(AppContext);
    const { id, profile_id } = useParams();
    const params = useLocation();

    const [images, setImages] = useState([]); 
    const handleImages = (e) => {
        setImages(e)
    }

    // parametros
    const [profile, setProfile] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [barberlevel, setBarberLevel] = useState([]);
    const [tip, setTip] = useState([]);

    // values
    const [company, setCompany] = useState("");
    const [locations, setLocations] = useState("");

    //fechas
    const [birthdate, setBirthdate] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    //ocultar campos
    const [showFieldBarber, setShowFieldBarber] = useState(false);


    // formulario
    // estado inicial de formulario
    const [formData, setFormData] = useState({
        user_username: "",
        user_photo: "",
        company_id: "",
        location_id: "",
        profile_id: "",
        barberlevel_id: "",
        person_firstname: "",
        person_secondname: "",
        person_surname: "",
        person_secondsurname: "",
        person_email: "",
        person_birthdate: "",
        userbarber_start_date: "",
        userbarber_end_date: "",
        userbarber_post_booking: "",
        userbarber_tip: "",
        userbarber_interval_schedule: "",
        userbarber_walkin_restriction: ""
    });
    const { register, handleSubmit, formState: { errors }, setValue } = useForm()

    // permisos
    const [permissions, setPermissions] = useState([]);
    const [menus, setMenus] = useState([]);

    useEffect(() => {
        setMenus(app.menus[parent]);
        setPermissions(app.permissions[endpoint])
    }, [app])

    useEffect(() => {
        if (startDate?.hasOwnProperty('_d')) {
            setFormData({ ...formData, userbarber_start_date: startDate?.format('YYYY-MM-DD') });
        }
    }, [startDate]);

    useEffect(() => {
        if (endDate?.hasOwnProperty('_d')) {
            setFormData({ ...formData, userbarber_end_date: endDate?.format('YYYY-MM-DD') });
        }
    }, [endDate]);

    useEffect(() => {
        if (formData.profile_id != "" && formData.profile_id == 5 || formData.profile_id == 6) {
            setShowFieldBarber(true)
        } else {
            setShowFieldBarber(false)
        }
    }, [formData.profile_id]);

    useEffect(() => {
        getParamsUpdate();
        if(params.state?.profile_id !== undefined){
            setFormData(
                {
                    ...formData, profile_id: params.state.profile_id
                }
            )
        }
    }, [])

    const getParamsUpdate = () =>{
        handlerApp.setLoader(true);
        api.get(endpoint, {
            action: "getParamsUpdate"
        })
            .then(response => {
                if (response.success) {
                    setLocations(handlerApp.getResultFromObject(response.data,'locations'));
                    if (id !== undefined) {
                        setTitle('personComponent.updateUser')
                        sendRequest();
                    } else {
                        handlerApp.setLoader(false);
                    }
                } else {
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.setLoader(false);
                handlerApp.showError(t(error.message));
            })
    }

    const handleDependencies = (data) => {
        setCompany(data.company_id);
        setLocations(data.location_id);
        setBarberLevel(data.barberlevel_id);
        let d = filterData(data, formData);

        if (d.person_birthdate != null) {
            setBirthdate(moment(d.person_birthdate));
        }

        if (d.userbarber_start_date != null) {
            setStartDate(moment(d.userbarber_start_date));
        }

        if (d.userbarber_end_date != null) {
            setEndDate(moment(d.userbarber_end_date));
        }

        setFormData(d);
    }

    const filterData = (data, formData) => {
        let finalData = {};
        for (let key in formData) {
            finalData[key] = data[key];
            setValue(key, data[key]);
        }
        return finalData;
    }

    const sendRequest = () => {
        api.get(endpoint + "/" + id,)
            .then(response => {
                if (response.success) {
                    let data = response.data[0];
                    handleDependencies(data);
                    handlerApp.setLoader(false);
                } else {
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError(t(error.message));
                handlerApp.setLoader(false);
            })
    }

    const handleForm = (e, name = null, dependency = null) => {
        let key = (name !== null) ? name : e.target.name;
        setFormData({
            ...formData,
            [key]: e.target.value
        });
        setValue(key, e.target.value);
        if (dependency !== null) {
            dependency(e.target.value);
        }
    }

    const handleCancel = (e) => {
        e.preventDefault();
        nav(-1);
    }

    // save data
    const handleSubmitForm = (data) => {
        let body ={
            ...data,
            person_birthdate: birthdate,
            user_photo: images,
            profile_id: formData.profile_id
        }
        handlerApp.setLoader(true);
        api.post(endpoint, body)
            .then(response => {
                if (response.success) {
                    handlerApp.showOk(response);
                    handlerApp.setLoader(false);
                    nav("/" + endpoint);
                } else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError(t(error.message));
                handlerApp.setLoader(false);
            })
    }

    const handleUpdateForm = (data) => {
        handlerApp.setLoader(true);
        let body ={
            ...data,
            user_photo: images,
            profile_id: formData.profile_id
        }
        api.put(endpoint + "/" + id, body)
            .then(response => {
                if (response.success) {
                    handlerApp.showOk(response);
                    handlerApp.setLoader(false);
                    nav("/" + endpoint);
                } else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError(t(error.message));
                handlerApp.setLoader(false);
            })
    }
    // dependencias

    const handleSelectLocation = (val) => {
        if (val != null) {
            setCompany(val);
            setLocations([]);
        }
    }

    useEffect(() => {
        if (company != null) {
            getLocation();
        }
    }, [company])

    const getLocation = () => {
        if (company !== undefined && company !== "") {
            handlerApp.setLoader(true);
            api.get(endpoint, {
                action: "getLocation",
                company: company
            })
                .then(response => {
                    if (response.success) {
                        setLocations(response.data);
                        handlerApp.setLoader(false);
                    } else {
                        handlerApp.setLoader(false);
                        handlerApp.handlerResponse(response);
                    }
                })
                .catch(error => {
                    handlerApp.showError(t(error.message));
                    handlerApp.setLoader(true);
                })
        }
    }


    return (
        <div className="app container">
            <div className="d-flex justify-content-between">
                <section className="section-title-page">
                    <h3 className="title-page ps-3">{t(title)}</h3>
                </section>
                <div className="d-flex">
                    <span className="material-symbols-outlined w-icon" role="button" onClick={handleCancel} >close</span><p className="me-5" role="button" onClick={handleCancel}>{t("cancel")}</p>
                </div>
            </div>
            <NavbarEditBarbers />
            <div className="section-form">
                <form onSubmit={handleSubmit((id === undefined) ? handleSubmitForm : handleUpdateForm)}>
                    <div className="row">
                        <div className="col-md-12 mb-2">
                            <div className="circle-image">
                                <BarberlyticsFilesUpload  returnImages={handleImages} multiple={false} value={formData.user_photo}/>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 mb-4">
                            <TextField label={t("personComponent.firstName")} variant="outlined" size="small" className="barberlytics_text-field"
                                {...register("person_firstname", { required: true, maxLength: 60 })}
                                value={formData.person_firstname}
                                onChange={handleForm}
                                error={errors.person_firstname != null}
                                helperText={
                                    errors.person_firstname?.type === 'required' ? t("errors.required") :
                                        errors.person_firstname?.type === 'maxLength' ? t("errors.maxLength") : ""}
                            />
                        </div>
                        <div className="col-12 col-md-6 mb-4">
                            <TextField label={t("personComponent.secondName")} variant="outlined" size="small" className="barberlytics_text-field"
                                {...register("person_secondname", {})}
                                value={formData.person_secondname}
                                onChange={handleForm}
                                error={errors.person_secondname != null}
                                helperText={
                                    errors.person_secondname?.type === 'required' ? t("errors.required") :
                                        errors.person_secondname?.type === 'maxLength' ? t("errors.maxLength") : ""}
                            />
                        </div>
                        <div className="col-12 col-md-6 mb-4">
                            <TextField label={t("personComponent.surName")} variant="outlined" size="small" className="barberlytics_text-field"
                                {...register("person_surname", { required: true, maxLength: 60 })}
                                value={formData.person_surname}
                                onChange={handleForm}
                                error={errors.person_surname != null}
                                helperText={
                                    errors.person_surname?.type === 'required' ? t("errors.required") :
                                        errors.person_surname?.type === 'maxLength' ? t("errors.maxLength") : ""}
                            />
                        </div>
                        <div className="col-12 col-md-6 mb-4">
                            <TextField label={t("personComponent.secondSurname")} variant="outlined" size="small" className="barberlytics_text-field"
                                {...register("person_secondsurname", {})}
                                value={formData.person_secondsurname}
                                onChange={handleForm}
                                error={errors.person_secondsurname != null}
                                helperText={
                                    errors.person_secondsurname?.type === 'required' ? t("errors.required") :
                                        errors.person_secondsurname?.type === 'maxLength' ? t("errors.maxLength") : ""}
                            />
                        </div>
                        <div className="col-12 col-md-6 mb-4">
                            <TextField label={t("phone")} variant="outlined" size="small" className="barberlytics_text-field"
                                {...register("user_username", { required: true, maxLength: 60 })}
                                value={formData.user_username}
                                onChange={handleForm}
                                error={errors.user_username != null}
                                helperText={
                                    errors.user_username?.type === 'required' ? t("errors.required") :
                                        errors.user_username?.type === 'maxLength' ? t("errors.maxLength") : ""}
                            />
                        </div>
                        <div className="col-12 col-md-6 mb-4">
                            <TextField label={t("email")} variant="outlined" size="small" className="barberlytics_text-field"
                                {...register("person_email", { required: true, maxLength: 80 })}
                                value={formData.person_email}
                                onChange={handleForm}
                                error={errors.person_email != null}
                                helperText={
                                    errors.person_email?.type === 'required' ? t("errors.required") :
                                        errors.person_email?.type === 'maxLength' ? t("errors.maxLength") : ""}
                            />
                        </div>                   
                        <div className="col-md-6 mb-2">
                            <BoldSelectValidated
                                title={t('locationComponent.location')}
                                value={formData.location_id}
                                name="location_id"
                                onChange={handleForm}
                                options={locations}
                                register={register}
                                errors={errors}
                                required={false}
                            />
                        </div>
                        <div className="row d-flex justify-content-end">
                            <div className="col-md-3 d-flex justify-content-end">
                                {id === undefined && <button className="btn btn-primary-yellow-1" type="submit">{t('submit')}</button>}
                                {id && <button className="btn btn-primary-yellow-1" type="submit">{t('update')}</button>}
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default UsersForms;