import { useTranslation } from "react-i18next";
import { useFetch } from "../../../../hooks/useFecth";
import HandlerApp from "../../../../utils/handlerApp";
import { NavLink, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import HeaderScreen from "../../HeaderScreen/HeaderScreen";
import { AppContext } from "../../../../context/AppContext";
import TableCompanies from "./TableCompanies";

const Companies = ()=>{
    const { app }       = useContext(AppContext);

    const endpoint      = "companies";
    const parent        = "Company";
    const prefix        = "company";

    const api           = useFetch();
    const handlerApp    = HandlerApp();
    const [t]           = useTranslation("global");
    const nav           = useNavigate();

    const title         = t('companyComponent.companies');
    const description   = t('companyComponent.description');
    const actions       = [];

    const [data, setData]               = useState([]);
    const [menus,setMenus]              = useState([]);
    const [permissions,setPermissions]  = useState([]);

    useEffect(()=>{
        setMenus(app.menus[parent]);
        setPermissions(app.permissions[endpoint]);
    },[app])

    useEffect(() => {
        sendRequest();
    }, [])

    const sendRequest = ()=>{
        handlerApp.setLoader(true);
        api.get(endpoint)
        .then(response => {
            if(response.success){
                // setFilters({...filters,
                //     typeIdentification: handlerApp.getFiltersFromData(response.data,'company_identification_type'),
                // });
                setData(response.data);
                handlerApp.setLoader(false);
            }else{
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }


    return(
        <div className="app container">
            <HeaderScreen title={title} description={description} actions={actions}/>
            <nav className="child-menu">
                { menus?.map( item =>{
                    return <NavLink key={item.menu_children} to={"/"+item.menu_children_action} activeclassname="active" end>{item.menu_children}</NavLink>
                })}
            </nav>
            <TableCompanies data={data} setData={setData} endpoint={endpoint} permissions={permissions} prefix={prefix} setRefresh={sendRequest} />
        </div>
    )
}
export default Companies;