import env from "../../../../env-local";
import HandlerApp from "../../../../utils/handlerApp";
import HeaderScreen from "../../HeaderScreen/HeaderScreen";
import NavbarFilter from "../../componentes/filter/NavbarFilter";
import TableCustomers from "./TableCustomers";
import { useFetch } from "../../../../hooks/useFecth";
import { AppContext } from "../../../../context/AppContext";
import { useTranslation } from "react-i18next";
import { useContext, useEffect, useState } from "react";

const NewCustomers = ()=>{
    const [t]           = useTranslation("global");
    const api           = useFetch();
    const handlerApp    = HandlerApp();

    const endpoint      = "customers";
    const parent        = "Customers";
    const title         = t('customers.customers');
    const description   = t('customers.descriptionScreen');

    const {app}                             = useContext(AppContext);
    const [data, setData]                   = useState([]);
    const [dataFiltered, setDataFiltered]   = useState([]);
    const [menus,setMenus]                  = useState([]);
    const [permissions, setPermissions]     = useState([]);

    useEffect(() => {
        setMenus(app.menus[parent]);
        setPermissions(app.permissions["customers"])
    }, [app])

    useEffect(() => {
        sendRequest();
    }, [])

    const handleResponse = (response) => {
        const contentType = response.headers.get("Content-Type");
        if (contentType && contentType.includes("application/json") || contentType.includes("text/html")){
            return response.json();
        } else if (contentType && (contentType.includes("application/vnd.ms-excel"))){
            return response.blob();
        } else {
            throw new Error("Tipo de respuesta no soportado");
        }
    }

    const downloadCustomers = () => {
       handlerApp.setLoader(true);
        fetch(
            env.urlBackend+`/download-customers`,{
            method: "GET",
            headers: {
                "Authorization": localStorage.getItem('token'),
                "Profile": localStorage.getItem("profile"),
                "Company": localStorage.getItem("company"),
                "Location": localStorage.getItem("location"),
                'Content-Type': 'application/vnd.ms-excel',
            },
        })
        .then(res => handleResponse(res) )
        .then(response => {
            handlerApp.setLoader(false);
            if (response instanceof Blob) {
                const url = URL.createObjectURL(response);
                const a = document.createElement("a");
                a.href = url;
                a.download = endpoint;
                document.body.appendChild(a);
                a.click();
                URL.revokeObjectURL(url);
            } else {
                handlerApp.handlerResponse(response);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }
    
    const actions = [
        {name: t("customers.action.create"), endpoint: `/${endpoint}/create`, icon: "fa-user-plus"},
        {name: t("customers.action.importClients"), endpoint: `/${endpoint}/import`, icon: "fa-arrow-up-to-line"},
        {name: t("customers.action.exportClients"), function: downloadCustomers, icon: "fa-arrow-down-to-line"}
    ];

    const filter_options = [
        { id: 1, name:t("customerType.all"),       value: "All clients"},
        { id: 2, name:t("customerType.new"),       value: "New",          description: t('customers.descriptionNewClient')},
        { id: 3, name:t("customerType.retention"), value: "Retention",    description: t('customers.descriptionRetention')},
        { id: 3, name:t("customerType.repeat"),    value: "Repeat",       description: t('customers.Repeatclient')},
        { id: 3, name:t("customerType.vip"),       value: "VIP",          description: t('customers.vipDescription')},
        { id: 3, name:t("customerType.preChurn"),  value: "Pre-churn",    description: t('customers.descriptionPreChurn')},
        { id: 3, name:t("customerType.churn"),     value: "Churn",        description: t('customers.descriptionChurn')},
        { id: 3, name:t("customerType.recovered"), value: "Recovered",    description: t('customers.recoveredDescription')},
        { id: 3, name:t("customerType.block"),     value: "Block",        description: t('customers.blockDescription')},
    ];
    
    const sendRequest = () => {
        handlerApp.setLoader(true);
        api.get(endpoint)
        .then(response => {
            if (response.success) {
                setData(response.data);
                setDataFiltered(response.data);
                handlerApp.setLoader(false);
            } else {
                setData([]);
                setDataFiltered([]);
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const filteredItems = dataFiltered?.filter(
        item => item.customer_id && item.customer_id.toString().toLowerCase().includes(filterText.toLowerCase()) 
            || item.customer_fullname.toLowerCase().includes(filterText.toLowerCase())
            || item.customer_phone?.toLowerCase().includes(filterText.toLowerCase())
            || item.location_name?.toLowerCase().includes(filterText.toLowerCase())
            || item.customer_email?.toLowerCase().includes(filterText.toLowerCase())
    );

    return(
        <div className="app container">
            <HeaderScreen title={title} description={description} actions={actions}/>

            <NavbarFilter actions={filter_options} data={data} setDataFiltered={setDataFiltered} field="customer_type"/>

            <section className="filters">
                <div className="d-flex">
                    <h4> {t('customers.list')}</h4>
                    <span className="light numberClients">{data.length} {t("customers.clients")}</span>
                </div>
                <div className="row justify-content-end">
                    <div className="col-md-3 w-40">
                        <div className="input-icon input-search">
                            <input type="text" onChange={e => setFilterText(e.target.value)} value={filterText} placeholder={t("searchIn") + ` ${data.length} ${t("customers.clients")}`}></input>
                            {filterText !== "" && <span className="material-symbols-outlined clear" onClick={e => { setFilterText(""); setResetPaginationToggle(!resetPaginationToggle) }}>cancel</span>}
                            <span className="material-symbols-outlined">search</span>
                        </div>
                    </div>
                </div>
            </section>
            <TableCustomers data={filteredItems} endpoint={endpoint} permissions={permissions} prefix={"customer"} setRefresh={sendRequest} />
        </div>
    )
}

export default NewCustomers;