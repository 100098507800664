import React, { useContext } from "react";
import InputText from "../../Migrar/componentes/Inputs/InputText/InputText";
import './BodyNoteModal.css'
import { useForm } from "react-hook-form";
import { AppContext } from "../../../context/AppContext";
import { useTranslation } from "react-i18next";

/**
 * @author Vicente Bolivar
 * @version 1.0.0
 * @description body para modal de  pagos en la pantalla billing and invoices
*/

const BodyBillingPayModal = () => {
    const [t] = useTranslation("global");
    const { app } = useContext(AppContext);
    const { register, handleSubmit, formState: { errors }, setValue, control } = useForm()



    return (
        <form id="billingForm" onSubmit={handleSubmit}>
            <div className="form-group">
                    <div className="form-group mt-1">
                        <InputText
                            label={t("billing.creditNumber")}
                            name="billing_creditNumber"
                            errors={errors}
                            rules={{ required: true, maxLength: 80}}
                            register={register}
                            control={control}
                        />
                    </div>
                    <div>
                        <InputText
                            label={t("billing.cardHolder")}
                            name="billing_holder"
                            errors={errors}
                            rules={{ required: true, maxLength: 80 }}
                            register={register}
                            control={control}
                        />
                    </div>
                    <div>
                        <InputText
                            label={t("billing.expirationDate")}
                            name="billing_expiration"
                            errors={errors}
                            rules={{ required: true, maxLength: 80 }}
                            register={register}
                            control={control}
                        />
                    </div>
                    <div>
                        <InputText
                            label={t("billing.cvv")}
                            name="billing_cvv"
                            errors={errors}
                            rules={{ required: true, maxLength: 80 }}
                            register={register}
                            control={control}
                        />
                    </div>
                </div>
        </form>
    );
};

export default BodyBillingPayModal;