import RoundImage from "../../componentes/RoundImage";
import HandlerApp from "../../../../utils/handlerApp";
import InfoClient from "../../componentes/InfoClient";
import RightModal from "../../../atoms/RightModal/RightModal";
import NavLinkView from "../../componentes/navbar/NavLinkView";
import CentralModal from "../../../atoms/CentralModal/CentralModal";
import ButtonPrimary from "../../../atoms/Buttons/ButtonPrimary"
import BodyNoteModal from "../../../atoms/RightModal/BodyNoteModal";
import ButtonOutline from "../../../atoms/Buttons/ButtonOutline";
import CardCustomerInfo from "../../../atoms/Cards/Customer/CardWithAlert/CardCustomerInfo";
import HeaderViewScreen from "../../HeaderScreen/HeaderViewScreen";
import SwitchObjectView from "../../componentes/Inputs/SwitchObjectView";
import BodyBlockClientModal from "../../../atoms/RightModal/BodyBlockClientModal";
import BodyActiveClientModal from "../../../atoms/RightModal/BodyActiveClientModal";
import BodyDeleteClientModal from "../../../atoms/RightModal/BodyDeleteClientModal";
import BodyReportAttitudModal from "../../../atoms/RightModal/BodyReportActtitudModal";
import BodyInactiveClientModal from "../../../atoms/RightModal/BodyInactiveClientModal";
import { useFetch } from "../../../../hooks/useFecth";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BodyUnlockClientModal from "../../../atoms/RightModal/BodyUnlockClientModal";


const CustomerProfile = ()=>{
    const [t]           = useTranslation("global");
    const title         = t("customers.viewClient");
    const endpoint      = "customers";
    const api           = useFetch();
    const handlerApp    = HandlerApp();
    const nav           = useNavigate();
    
    const params        = useParams();
    const id            = params?.id;

    const [optionsVisible, setOptionsVisible] = useState(false);
    const [modalType, setModalType]           = useState(null);
    const [data, setData]                     = useState([]);
    const [family, setFamily]                 = useState([]);

    useEffect(()=>{
        sendRequest();
    }, [])

    const createNote = () => {
        setModalType('createNote');
        setOptionsVisible(true);
    };

    const reportAttitude = () => {
        setModalType('reportAttitude');
        setOptionsVisible(true);
    };

    const blockClient = () => {
        setModalType('blockClient');
        setOptionsVisible(true);
    };

    const activeClient = () => {
        setModalType('activeClient');
        setOptionsVisible(true);
    };

    const closeModal = () => {
        setOptionsVisible(false);
    };

    const deleteClient = () => {
        setModalType('deleteClient');
        setOptionsVisible(true);
    };

    const inactiveClient = () => {
        setModalType('inactiveClient');
        setOptionsVisible(true);
    };

    const unlockClient = () => {
        setModalType('unlockClient');
        setOptionsVisible(true);
    };

    const actions = [
        {name: t("customers.actionView.edit"), endpoint: `/customers/edit/${id}`, icon: "fa-pen"},
        {name: t("customers.actionView.createNote"), function: createNote, icon: "fa-note-sticky"},
        {name: t("customers.actionView.messageClient"), endpoint: `/${endpoint}/categories/create`, icon: "fa-message"},
        {name: t("customers.actionView.callClient"), endpoint: `/${endpoint}/categories/create`, icon: "fa-phone"},
        {name: t("customers.actionView.createGiftcard"), endpoint: `/${endpoint}/categories/create`, icon: "fa-gift-card"},
        {name: t("customers.actionView.createAppointment"), endpoint: `/${endpoint}/categories/create`, icon: "fa-calendar-plus"},
        {name: t("customers.actionView.createSale"), endpoint: `/${endpoint}/categories/create`, icon: "fa-cash-register"},
        {name: t("customers.actionView.addFamily"), endpoint: `/${endpoint}/${id}/create/known`, icon: "fa-user-plus"},
        {name: t("customers.view.reportAttitude"),  function: reportAttitude, icon: "fa-face-zipper"},
        {name: data.customer_active == 1 ? t("customers.view.inactiveClient") : t("customers.view.activeClient"),  function: data.customer_active == 1 ? inactiveClient : activeClient, icon: "fa-play"},
        {name: t("customers.actionView.delete"), function: deleteClient, icon: "fa-trash", red: true},
        {name: data.customer_blocked == 1 ? t("customers.actionView.unlockClient") : t("customers.actionView.blockClient"), function: data.customer_blocked == 1 ? unlockClient : blockClient, icon: "fa-ban", red: true},
    ];

    const nav_actions = [
        {name: t("menuCustomer.overview"),    to: `/customers/${id}/overview`},
        {name: t("menuCustomer.profile"),     to: `/customers/${id}/profile`},
        {name: t("menuCustomer.appointment"), to: `/customer/appointments/${id}`},
        {name: t("menuCustomer.payment"),     to: `/payment-methods/${id}`},
        {name: t("menuCustomer.products"),    to: `/customers/products/${id}`}
    ];


    const handleCreateNote = () => {
        setOptionsVisible(false);
    };

    const sendRequest = ()=>{
        handlerApp.setLoader(true);
        api.get(endpoint + "/" + id)
        .then(response => {
            if (response.success) {
                let data = response.data[0];
                setFamily(data.family);
                setData(data);

                localStorage.setItem('customer_active',data.customer_active);
                localStorage.setItem('customer_blocked',data.customer_blocked);
                handlerApp.setLoader(false);
            } else {
                setFamily([]);
                setData([]);
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const handleCreateNoteClick = () => {
        const formElement = document.getElementById('createNoteForm');
        if (formElement) {
            formElement.requestSubmit();
        }
    };

    const handleReportAttitudeClick = () => {
        const formElement = document.getElementById('reportAttitudeForm');
        if (formElement) {
            formElement.requestSubmit();
        }
    };

    const handleActivateClientClick = () => {
        const formElement = document.getElementById('activateClientForm');
        if (formElement) {
            formElement.requestSubmit();
        }
    };

    const handleBlockClientClick = () => {
        const formElement = document.getElementById('blockClientForm');
        if (formElement) {
            formElement.requestSubmit();
        }
    };

    const handleReportAttitude = async (reportData) => {
        closeModal();
        handlerApp.setLoader(true);
        try {
            const dataToSend = {
                customer_actitude: reportData.customer_actitude
            };
                
            const response = await api.put(`customers/${id}/actitude`, dataToSend);
    
            if (response.success) {
                handlerApp.showOk({ message: t('customers.reportSuccess') });
                sendRequest();
            } else {
                handlerApp.handlerResponse(response); 
            }
        } catch (error) {
            handlerApp.showError(t(error.message)); 
        } finally {
            handlerApp.setLoader(false);
            setOptionsVisible(false); 
        }
    };

    const handleActivateClient = async () => {
        closeModal();
        handlerApp.setLoader(true);
        try {
            const payload = {
                customer_active: 1
            };
    
            const response = await api.put(`customers/${id}/active`, payload);
    
            if (response.success) {
                handlerApp.showOk({ message: t('customers.clientActivated') });
                sendRequest();
            } else {
                handlerApp.handlerResponse(response);
            }
        } catch (error) {
            handlerApp.showError(t(error.message));
        } finally {
            handlerApp.setLoader(false);
            setOptionsVisible(false);
        }
    };

    const handleBlockClient = async (blockData) => {
        closeModal();
        handlerApp.setLoader(true);
        try {
            const dataToSend = {
                customer_blocked: 1,
                ...blockData
            };
    
            const response = await api.put(`customers/${id}/blocked`, dataToSend);
    
            if (response.success) {
                handlerApp.showOk({ message: t('customers.blockSuccess') });
                sendRequest();
            } else {
                handlerApp.handlerResponse(response); 
            }
        } catch (error) {
            handlerApp.showError(t(error.message)); 
        } finally {
            handlerApp.setLoader(false);
            setOptionsVisible(false); 
        }
    };

    const handleDeleteClient = async () => {
        handlerApp.setLoader(true);
        try {
            const response = await api.deleteMethod(`customers/${id}`);
    
            if (response.success) {
                handlerApp.showOk({ message: t('customers.clientDelete') });
                nav("/" + endpoint);
            } else {
                handlerApp.handlerResponse(response);
            }
        } catch (error) {
            handlerApp.showError(t(error.message));
        } finally {
            handlerApp.setLoader(false);
            setOptionsVisible(false);
        }
    };

    const handleDeleteClientClick = () => {
        const formElement = document.getElementById('deleteClientForm');
        if (formElement) {
            formElement.requestSubmit();
        }
    };

    const handleInactivateClient = async () => {
        closeModal();
        handlerApp.setLoader(true);
        try {
            const payload = {
                customer_active: 0
            };
    
            const response = await api.put(`customers/${id}/active`, payload);
    
            if (response.success) {
                handlerApp.showOk({ message: t('customers.clientInactivated') });
                sendRequest();
            } else {
                handlerApp.handlerResponse(response);
            }
        } catch (error) {
            handlerApp.showError(t(error.message));
        } finally {
            handlerApp.setLoader(false);
            setOptionsVisible(false);
        }
    };

    const handleInactivateClientClick = () => {
        const formElement = document.getElementById('inactiveClientForm');
        if (formElement) {
            formElement.requestSubmit();
        }
    };

    const handleUnlockClient = async () => {
        closeModal();
        handlerApp.setLoader(true);
        try {
            const payload = {
                customer_blocked : 0
            };
    
            const response = await api.put(`customers/${id}/unlock`, payload);
    
            if (response.success) {
                handlerApp.showOk({ message: t('customers.clientUnlock') });
                sendRequest();
            } else {
                handlerApp.handlerResponse(response);
            }
        } catch (error) {
            handlerApp.showError(t(error.message));
        } finally {
            handlerApp.setLoader(false);
            setOptionsVisible(false);
        }
    };

    const handleUnlockClientClick = () => {
        const formElement = document.getElementById('unlockClientForm');
        if (formElement) {
            formElement.requestSubmit();
        }
    };

    return(
        <div className="app container">
            <HeaderViewScreen title={title} actions={actions}/>

            <NavLinkView actions={nav_actions} active={1}/>

            <section className="container-customer d-flex justify-content-between mb-4">
                <div className="info-content d-flex align-items-center">
                    <RoundImage src={data.customer_image} icon={"fa-user"}/>
                    <div className="info-client mx-3 d-flex flex-column">
                        <div className="p1ParagraphSemiBold18"> {data.customer_fullname} </div>
                        <div className="d-flex customer-chips col-lg-4 col-md-4 col-sm-6 col-7 captionSemiBold10"> {handlerApp.ChipCustomer(data.customer_type)} </div>
                    </div>
                </div>

                <SwitchObjectView 
                    labelRight={t("customers.paymentOnlineOnly")} 
                    value={data.customer_payment_only_online} 
                    id={"customer_payment_only_online"} 
                    name="customer_payment_only_online" 
                />
            </section>

            <h4 className="title-profile-customers">{t('customers.view.contactInformation')}</h4>

            <section className="bg-card mb-4">
                <InfoClient icon="fa-phone" title={t('customers.view.phone')} value={data.customer_phone} />
                <InfoClient icon="fa-envelope" title={t('customers.view.email')} value={data.customer_email} />
            </section>

            <h4 className="title-profile-customers">{t('customers.view.location')}</h4>

            <section className="bg-card mb-4">
                <InfoClient icon="fa-earth-americas" title={t('customers.view.country')} value={data.country_name} />
                <InfoClient icon="fa-flag" title={t('customers.view.state')} value={data.state_name} />
                <InfoClient icon="fa-city" title={t('customers.view.city')} value={data.city_name} />
                <InfoClient icon="fa-location-dot" title={t('customers.view.address')} value={data.customer_address} />
                <InfoClient icon="fa-mailbox" title={t('customers.view.zipcode')} value={data.customer_zipcode} />
            </section>

            <section className="bg-card mb-4">
                <InfoClient icon="fa-cake-candles" title={t('customers.view.birthdate')} value={data.customer_birthdate} />
                <InfoClient icon="fa-face-diagonal-mouth" title={t('customers.view.actitude')} value={data.customer_actitude} />
                <InfoClient icon="fa-calendar" title={t('customers.view.creationdate')} value={handlerApp.getFormatDate(data.created_at)} />
            </section>

            {
                (family) &&
                <>
                    <h4 className="title-profile-customers">{t('customers.view.familyAndFriends')}</h4>
                    {
                        family?.map(x => (
                            <CardCustomerInfo
                              name={x.customer_fullname}
                              members={x.customerknown_relationship}
                              status={x.customer_type}
                              reporte={(x.customer_actitude != null || x.customer_notes != null ) ? true : false}
                              image={x.customer_image}
                            />
                          ))
                    }
                </>
            }

            {modalType === 'createNote' && (
                <RightModal 
                    title={t("customers.actionView.createNote")} 
                    body={<BodyNoteModal onCreate={handleCreateNote} customerId={data.customer_id}/>} 
                    visible={optionsVisible} 
                    setOptionsVisible={setOptionsVisible} 
                    footer={<ButtonPrimary label={t('create')} onClick={handleCreateNoteClick}/>}
                />
            )}

            {modalType === 'reportAttitude' && (
                <CentralModal 
                    title={t("customers.view.reportAttitude")} 
                    body={<BodyReportAttitudModal onSubmit={handleReportAttitude} />} 
                    visible={optionsVisible} 
                    setOptionsVisible={setOptionsVisible} 
                    footer={<ButtonPrimary label={t('report')} onClick={handleReportAttitudeClick} />}
                />
            )}

            {modalType === 'blockClient' && (
                <RightModal 
                    title={t("customers.actionView.blockClient")} 
                    body={<BodyBlockClientModal onSubmit={handleBlockClient} />} 
                    visible={optionsVisible} 
                    setOptionsVisible={setOptionsVisible} 
                    footer={<ButtonPrimary label={t('block')} onClick={handleBlockClientClick}/>}
                />
            )}

            {modalType === 'activeClient' && (
                <CentralModal 
                    title={t("customers.view.activeClient")} 
                    body={<BodyActiveClientModal onSubmit={handleActivateClient} />} 
                    visible={optionsVisible} 
                    setOptionsVisible={setOptionsVisible} 
                    footer={[
                        <ButtonOutline label={t('customers.view.noContinue')} onClick={closeModal}  />,
                        <p className="me-3"></p>,
                        <ButtonPrimary label={t('customers.view.yesActive')} onClick={handleActivateClientClick} />
                    ]}
                />
            )}

            {modalType === 'deleteClient' && (
                <CentralModal 
                    title={t("customers.view.deleteClient")} 
                    body={<BodyDeleteClientModal onSubmit={handleDeleteClient} closeModal={closeModal}/>} 
                    visible={optionsVisible} 
                    setOptionsVisible={setOptionsVisible} 
                    footer={[
                        <ButtonOutline label={t('customers.view.noContinue')} onClick={closeModal}  />,
                        <p className="me-3"></p>,
                        <ButtonPrimary label={t('customers.view.yesDelete')} onClick={handleDeleteClientClick} />
                    ]}
                />
            )}

            {modalType === 'inactiveClient' && (
                <CentralModal 
                    title={t("customers.view.inactiveClient")} 
                    body={<BodyInactiveClientModal onSubmit={handleInactivateClient} />} 
                    visible={optionsVisible} 
                    setOptionsVisible={setOptionsVisible} 
                    footer={[
                        <ButtonOutline label={t('customers.view.noContinue')} onClick={closeModal}  />,
                        <p className="me-3"></p>,
                        <ButtonPrimary label={t('customers.view.yesInactive')} onClick={handleInactivateClientClick} />
                    ]}
                />
            )}

            {modalType === 'unlockClient' && (
                <CentralModal 
                    title={t("customers.actionView.unlockClient")} 
                    body={<BodyUnlockClientModal onSubmit={handleUnlockClient} />} 
                    visible={optionsVisible} 
                    setOptionsVisible={setOptionsVisible} 
                    footer={[
                        <ButtonOutline label={t('customers.view.noContinue')} onClick={closeModal}  />,
                        <p className="me-3"></p>,
                        <ButtonPrimary label={t('customers.view.yesUnlock')} onClick={handleUnlockClientClick} />
                    ]}
                />
            )}
        </div>
    );
}

export default CustomerProfile;