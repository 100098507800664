import { useEffect, useState, useContext } from "react";
import EmptyState from "../../componentes/emptyState/EmptyState";
import HandlerApp from "../../../../utils/handlerApp";
import HeaderViewScreen from "../../HeaderScreen/HeaderViewScreen";
import CardEditAndDelete from "../../../atoms/Cards/CardEditAndDelete";
import { useFetch } from "../../../../hooks/useFecth";
import { AppContext } from "../../../../context/AppContext";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./ServicesChargesScreen.css";

/**
 * @author Vicente Bolivar
 * @version 1.0.0
 * @description - pantalla para listar cargos de servicios
*/

const ServicesChargesScreen = () => {
    const endpoint = "settings/services-charges";
    const parent = "Settings";
    const [t, i18n] = useTranslation("global");
    const title = t('serviceCharges.servicesCharges');
    const api = useFetch();
    const nav = useNavigate();
    const [data, setData] = useState([]);
    const handlerApp = HandlerApp();
    const [permissions, setPermissions] = useState([]);
    const [menus, setMenus] = useState([]);
    const { app } = useContext(AppContext);

    useEffect(() => {
        setMenus(app.menus[parent]);
        setPermissions(app.permissions[endpoint]);
    }, [app]);

    useEffect(() => {
        sendRequest();
    }, []);


    const getSubtitle = (fixedValue, percentageValue) => {
        const fixed = fixedValue > 0 ? `$${fixedValue}` :'' ;
        const percentage = percentageValue > 0 ? `${percentageValue}%` : '';
    
        if (fixed && percentage) {
            return `${fixed} + ${percentage} ${t("serviceCharges.chargeAdded")}`;
        } else if (fixed) {
            return `${fixed} ${t("serviceCharges.chargeAdded")}`;
        } else if (percentage) {
            return `${percentage} ${t("serviceCharges.chargeAdded")}`;
        } else {
            return ` ${t("serviceCharges.chargeAddedEmpty")}`;
        }
    };

    const handleCreateClick = () => {
        nav('/settings/services-charges/create');
    };

    const sendRequest = () => {
        handlerApp.setLoader(true);
        api.get(endpoint)
        .then(response => {
            if (response.success) {
                let dataResponse = response.data;
                setData(dataResponse);
                handlerApp.setLoader(false);
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        });
    }

    const handleDelete = (id) => {
        handlerApp.setLoader(true);
        api.deleteMethod(endpoint + '/' + id)
        .then(response => {
            if (response.success) {
                handlerApp.showOk(response);
                sendRequest();
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }


    return (
        <div className="app container">
            <div className="d-flex justify-content-between">
                <HeaderViewScreen title={title} actions={""}/>
                <button className="btn btn-primary-yellow-1 b-createServices" 
                    type="button"
                    onClick={handleCreateClick}
                >
                    <i className="fa fa-plus me-2"></i>
                    <span className="font-weight-bold">{t("serviceCharges.create")}</span>
                </button>
            </div>
            <span className="light descriptionServiceCharges pb-4">{t("serviceCharges.description")}</span>
            
            {data.length === 0 ? (
                <EmptyState
                    text={t("serviceCharges.emptyStateText")}
                    buttonText={t("serviceCharges.textButton")}
                    ImageSource={"/img/empty/serviceCharges.png"}
                    onPressAction={handleCreateClick}
                    icon={"fa-plus"}
                />
            ) : (
                <div className="service-charges-list">
                    {data.map((charge) => (
                        <CardEditAndDelete 
                            key={charge.charge_id}
                            title={charge.charge_name} 
                            subtitle={getSubtitle(charge.charge_fixed_value, charge.charge_percentage_value)}
                            text={"texto segundario para la tarjeta si lo necesita"}
                            navigate={`/settings/services-charges/${charge.charge_id}`} 
                            showButtonEdit={true}
                            showButtonDelete={true}
                            onFunctionDelete={() => handleDelete(charge.charge_id)}
                            ButtonText1={t("permissions.edit")} 
                            ButtonText2={t("permissions.delete")}
                        />
                    ))}
                </div>
            )}
        </div>
    )
}

export default ServicesChargesScreen;