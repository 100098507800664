import './BodyReportAttitudModal.css'
import React from "react";
import { useTranslation } from "react-i18next";

const BodyDeleteClientModal = ({ onSubmit, closeModal }) => {
    const [t] = useTranslation("global");

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit();
        closeModal();
    };

    return (
        <form id="deleteClientForm" onSubmit={handleSubmit}>
            <div className="form-group mt-2">
                <p>{t("customers.modalDeleteDescription")}</p>
            </div>
        </form>
    );
};

export default BodyDeleteClientModal;