import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import BoldSelect from "../../../../utils/BoldSelect";
import HandlerApp from "../../../../utils/handlerApp";
import RightModal from "../../../atoms/RightModal/RightModal";
import ButtonPrimary from "../../../atoms/Buttons/ButtonPrimary";
import ButtonOutline from "../../../atoms/Buttons/ButtonOutline";
import BodyBillingModal from "../../../atoms/RightModal/BodyBillingModal";
import HeaderViewScreen from "../../HeaderScreen/HeaderViewScreen";
import CardComponentPay from "../../../atoms/Cards/CardMethodPay/CardComponentPay";
import { useFetch } from "../../../../hooks/useFecth";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./BillingDetailsAndInvoicesScreen.css";


/**
 * @author Vicente Bolivar
 * @version 1.0.0
 * @description pantalla de configuracion de billing and invoices 
*/

const BillingDetailsAndInvoicesScreen = () => {
    const endpoint                              = "settings/billing-details";
    const [t]                                   = useTranslation("global");
    const api                                   = useFetch();
    const nav                                   = useNavigate();
    const handlerApp                            = HandlerApp();
    const title                                 = t('billing.billingDetailsAndInvoices');
    const [data, setData]                       = useState([]);
    const [isModalVisible, setIsModalVisible]   = useState(false);
    const [isSubmit, setIsSubmit]               = useState(false);
    const [filters,setFilters]                  = useState({
        type: [],
        month: [],
        year: []
    });


    useEffect(() => {
        sendRequest();
    }, []);

    const closeModal = () => {
        setIsModalVisible(false);
    };

    const handleEdit = () => {
        setIsModalVisible(true);
    };

    const updateModal = () => {
        sendRequest();
        setIsSubmit(false);
    }
    const sendRequest = () => {
        handlerApp.setLoader(true);
        api.get(endpoint)
        .then(response => {
            if (response.success) {
                let dataResponse = response.data.map(item => {
                    let transactions = JSON.parse(item.transactions);
                    return { ...item, transactions };
                });
                setData(dataResponse);
                handlerApp.setLoader(false);
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        });
    };
    

    const columns = [
        {
            id: 'bill_concept',
            name: t('billing.type'),
            selector: row => row.bill_concept,
            sortable: true,
        },
        {
            id: 'bill_total',
            name: t('billing.total'),
            selector: row => `$${(row.bill_total / 100).toFixed(2)}`,
            sortable: true,
        },
        {
            id: 'bill_payment_date',
            name: t('billing.date'),
            selector: row => new Date(row.bill_payment_date).toLocaleDateString(),
            sortable: true,
        },
        {
            id: 'action',
            name: t('action'),
            selector: row => row.action,
            sortable: true,
            width: "300px"
        }
    ];
    
    const totalTransactions = data.reduce((acc, item) => acc + item.transactions.length, 0);
    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const filteredItems = data?.filter(
        item => item?.billing_id && 
            (
                item.bill_concept.toLowerCase().includes(filterText.toLowerCase()) ||
                item.bill_total.toString().includes(filterText.toLowerCase()) ||
                item.bill_payment_date.toLowerCase().includes(filterText.toLowerCase())
            )
    );

    const allTransactions = data.flatMap(item => 
        item.transactions.map(transaction => ({
            ...item,
            ...transaction
        }))
    );

    return (
        <div className="app container">
            <div className="d-flex justify-content-between">
                <HeaderViewScreen title={title} actions={""}/>
            </div>
            <span className="light descriptionServiceCharges">{t("billing.description")}</span>
            <div className="row mt-4">
                <div className="col-md-6">
                    <div className="settingsCard-section border p-3 mb-3">
                        <h5 className="paddingSubTitleAddres">{t('billing.billingAddress')}</h5>
                        <p className="settingsDescriptionBilling">{t('billing.billingAddressDescription')}</p>
                        <hr className="divisorBilling"/>
                        <div className="settingsCard-option">
                            <div className="text-addres">
                                <span>{data[0]?.settingbilling_business_name}</span>
                                <p className="settingsDescriptionBilling">{`${data[0]?.settingbilling_address}, ${data[0]?.city_name}, ${data[0]?.state_name}, ${data[0]?.settingbilling_zipcode}`}</p>
                            </div>
                        </div>
                        <button 
                            className='buttonOutline w-auto me-2 pb-1 pt-1' 
                            onClick={handleEdit}
                        >
                            <i className="fa-regular fa-pen pe-2"></i>
                            <span className="w-text">{t("billing.buttonEditDetails")}</span>
                        </button>
                    </div>
                </div>

                <div className="col-md-6">
                    <div className="settingsCard-section border p-3 mb-3">
                        <h5 className="paddingSubTitleAddres">{t('billing.defaultPayment')}</h5>
                            <p className="settingsDescriptionBilling">{t('billing.defaultPaymentDescription')}</p>
                        <hr className="divisorBilling"/>
                        <div className="paddingCardPay">
                            <CardComponentPay
                                numberCard={"5555555555555555"}
                                type={"visa"}
                                id={""}
                            />
                        </div>
                        <button 
                            className='buttonOutline w-auto me-2 pb-1 pt-1' 
                            onClick={() => nav("/settings/manage-payment")}
                        >
                            <i className="fa-regular fa-pen pe-2"></i>
                            <span className="w-text">{t("billing.buttonEditPay")}</span>
                        </button>
                    </div>
                </div>
            </div> 
            <hr className="divisorBilling"/>
            <div className="d-flex align-items-center">
                <h3 className="titleListOfInvoices pt-2">{t("billing.titleListOfInvoices")}</h3>
                <span className="ps-3 pb-2 settingsDescriptionBilling">{totalTransactions} {t("billing.documents")}</span>
            </div>
            <span className="light descriptionServiceCharges pb-3">{t("billing.listOfInvoicesDescription")} "fecha del mes"</span>
            <section>
                <div className="row">
                     <div className="col-md-2">
                        <BoldSelect title={t("storageComponent.storageType")} name="type" onChange={ e => setFilterText(e.target.value)} value="" options={filters.type}></BoldSelect>
                    </div>
                    <div className="col-md-2">
                        <BoldSelect title={t("billing.year")} name="year" onChange={ e => setFilterText(e.target.value)} value="" options={filters.year}></BoldSelect>
                    </div>
                    <div className="col-md-2">
                        <BoldSelect title={t("billing.month")} name="month" onChange={ e => setFilterText(e.target.value)} value="" options={filters.month}></BoldSelect>
                    </div>
                </div>
            </section>
            <DataTable
                columns={columns}
                data={allTransactions}
                pagination
                paginationResetDefaultPage={resetPaginationToggle}
                persistTableHead
            />           
            <RightModal
                title={t("billing.editBillingDetails")}
                body={<BodyBillingModal isSubmit={isSubmit} updateModal={updateModal} setIsModalVisible={setIsModalVisible}/>}
                visible={isModalVisible}
                setOptionsVisible={setIsModalVisible}
                footer={[
                <ButtonOutline label={t("cancel")} onClick={closeModal} />,
                    <p className="me-3"></p>,
                <div className="buttonBilling mt-1">
                <ButtonPrimary
                    label={t("save")}
                    onClick={() => setIsSubmit(handlerApp.randonNumber())}
                />
                </div>
                ]}
            />
    </div>
  );
};

export default BillingDetailsAndInvoicesScreen;