import { useEffect, useState, useContext } from "react";
import DataTable from "react-data-table-component";
import HandlerApp from "../../../utils/handlerApp";
import BoldSelect from "../../../utils/BoldSelect";
import EmptyState from "../../../emptyState";
import notFound from "../../../assets/img/image-not-found.webp";
import { NavLink, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../../context/AppContext";
import { useFetch } from "../../../hooks/useFecth";

const Products = () => {
    const endpoint = "inventory/products";
    const parent = "Inventory";
    const prefix = "product";
    const [t, i18n] = useTranslation("global");
    const handlerApp = HandlerApp();
    const { app } = useContext(AppContext);
    const nav = useNavigate();
    const api = useFetch();
    const title = t('productComponent.products');
    const [data, setData] = useState([]);
    const [permissions, setPermissions] = useState([]);
    const [menus, setMenus] = useState([]);
    const [productCount, setProductCount] = useState(0);
    const [filters, setFilters] = useState({
        location: [],
        category: []
    });

    useEffect(() => {
        setMenus(app.menus[parent]);
        setPermissions(app.permissions[endpoint]);
    }, [app]);

    useEffect(() => {
        sendRequest();
    }, []);

    const handlerAction = (id, action) => {
        switch (action) {
            case 'create':
                nav("/" + endpoint + "/create");
                break;
            case 'view':
                nav("/" + endpoint + "/view/" + id);
                break;
            case 'update':
                nav("/" + endpoint + "/edit/" + id);
                break;
            case 'delete':
                deleteRow(id);
                break;
            case 'inactive':
                optionRequest(id, action);
                break;
            case 'active':
                optionRequest(id, action);
                break;
            case 'refresh':
                sendRequest();
                break;
        }
    }

    const deleteRow = (id) => {
        handlerApp.setLoader(true);
        api.deleteMethod(endpoint + '/' + id)
        .then(response => {
            handlerApp.showOk(response);
            sendRequest();
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        });
    }

    const optionRequest = (id, action) => {
        handlerApp.setLoader(true);
        let body = {
            action: 'updateStatus',
            status: (action === 'active') ? 1 : 0
        }
        api.put(endpoint + "/" + id, body)
        .then(response => {
            handlerApp.showOk(response);
            sendRequest();
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        });
    }

    const columns = [
        {
            id: 'product_name',
            name: t('name'),
            cell: (row) => {
                return (row.images !== null) ?
                    <img className="bold-image-table" src={row.images[0].image_url} alt={row.product_name} />
                    : <img className="bold-image-table" src={notFound} alt="Image not found" />
            },
            sortable: false,
        },
        {
            id: 'location_name',
            name: t('locationComponent.location'),
            selector: row => row.location_name,
            sortable: true
        },
        {
            id: 'category_name',
            name: t('categoryComponent.category'),
            selector: row => row.category_name,
            sortable: true
        },
        {
            id: 'productstorage_quantity',
            name: t('transactionsComponent.amount'),
            selector: row => row.productstorage_quantity,
            sortable: true,
            width: "90px"
        },
        {
            id: 'product_combo',
            name: t('storageComponent.storageType'),
            selector: row => row.storage_name,
            sortable: true,
            width: "110px"
        },
        {
            id: 'storage_name',
            name: t('storageComponent.storage'),
            selector: row => row.storage_name,
            sortable: true,
            width: "100px"
        },
        {
            id: 'product_price',
            name: t('productComponent.price'),
            selector: row => row.product_price,
            sortable: true,
            width: "90px"
        },
        {
            id: 'product_active',
            name: t('status'),
            selector: (row) => { return (row.product_active) ? t('active') : t("inactive") },
            sortable: true,
            width: "90px"
        },
        {
            name: t('action'),
            cell: (row) => { return handlerApp.actions(row, permissions, 'product', handlerAction) },
            ignoreRowClick: true,
            button: true,
            minWidth: "160px"
        }
    ];

    const sendRequest = () => {
        handlerApp.setLoader(true);
        api.get(endpoint)
        .then(response => {
            if (response.success) {
                let dataResponse = response.data;
                dataResponse = dataResponse?.map(e => {
                    e.product_price = handlerApp.formatCurrency(e.product_price);
                    return e;
                })
                setData(dataResponse);
                setProductCount(dataResponse.length);
                handlerApp.setLoader(false);
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        });
    }

    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const filteredItems = data?.filter(
        item => item?.product_id &&
            (
                item.product_id.toString().includes(filterText.toLowerCase())
                || item.product_name.toString().toLowerCase().includes(filterText.toLowerCase())
                || item.storage_name.toString().toLowerCase().includes(filterText.toLowerCase())
                || item.product_price.toString().toLowerCase().includes(filterText.toLowerCase())
                || item.product_active.toString().toLowerCase().includes(filterText.toLowerCase())
                || item.productstorage_quantity.toString().toLowerCase().includes(filterText.toLowerCase())
            )
    );


    return (
        <div className="app container">
            <div className="d-flex justify-content-between">
                <section className="section-title-page">
                    <h3 className="title-page">{title}</h3>
                    <p className="light">{t('productComponent.slogan')}</p>
                </section>
                <div className="dropdown">
                    {handlerApp.returnDropDownActions(permissions, handlerAction)}
                </div>
            </div>

            <nav className="child-menu">
                {menus?.map(item => {
                    return <NavLink key={item.menu_children} to={"/" + item.menu_children_action} activeclassname="active">{item.menu_children}</NavLink>
                })}
            </nav>

            <section className="filters">
                <div className="d-flex ai-center pb-3">
                    <h4 className="">{t("productComponent.productsList")}</h4>
                    <span className="ms-2 pb-1 light">{productCount} {t("productComponent.products")}</span>
                </div>

                <div className="row">
                    <div className="col-md-4">
                        <BoldSelect title={t("storageComponent.location")} name="location" onChange={e => setFilterText(e.target.value)} value="" options={filters.location}></BoldSelect>
                    </div>
                    <div className="col-md-4">
                        <BoldSelect title={t("categoryComponent.category")} name="category" onChange={e => setFilterText(e.target.value)} value="" options={filters.category}></BoldSelect>
                    </div>
                    <div className="col-md-3">
                        <div className="input-icon input-search">
                            <input type="text" onChange={e => setFilterText(e.target.value)} value={filterText} placeholder={t("search")}></input>
                            {filterText !== "" && <span className="material-symbols-outlined clear" onClick={e => { setFilterText(""); setResetPaginationToggle(!resetPaginationToggle) }}>cancel</span>}
                            <span className="material-symbols-outlined">search</span>
                        </div>
                    </div>
                </div>
            </section>
            
            {filteredItems.length === 0 ? (
                <div className="d-flex justify-content-center align-items-center flex-column">
                    <img className="empty-state-image pt-4" src={EmptyState.products} alt="No data"/>
                    <p className="empty-state-text text-center">{t("productComponent.emptyStateText")}</p>
                    <button className="btn btn-primary-yellow-1" type="button">
                        {t("productComponent.createProducts")}
                    </button>
                </div>
            ) : (
                <DataTable
                    columns={columns}
                    data={filteredItems}
                    pagination
                    paginationResetDefaultPage={resetPaginationToggle}
                    selectableRows
                    persistTableHead
                />
            )}
        </div>
    )
}

export default Products;