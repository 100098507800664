import React from 'react';
import './AppointmentStatus.css';
import { useTranslation } from "react-i18next";

/**
 * A component that renders a timeline of a customer's appointments.
 * @author Vicente Bolivar
 * @version 1.0.0
 * @since 17/09/2024
 * @description Time line. It's used in the customer's profile.
 * @param {Array} states - An array of objects with the following properties:
 *   - `name`: The name of the appointment status.
 *   - `date`: The date of the appointment.
 *   - `time`: The time of the appointment.
 *   - `client`: The name of the client.
 *   - `barber`: The name of the barber.
 *   - `paymentMethod`: The payment method used.
 */
const AppointmentStatus = ({ states }) => {
    const [t] = useTranslation("global");

    return (
        <div className="timeline">
            <div className='pb-2'>
                <span className='tittleTimeLine'>{t('customers.timeLine')}</span>
            </div>
            {states.map((state, index) => (
                <div key={index} className={`timeline-item ${index === states.length - 1} ${index == 0 && 'timeline-item-first'}`}>
                    <div className={(state.date) ? 'status-point active' : 'status-point'}></div>
                    <div className="timeline-content">
                        <p className="m-0 state-name">{state.name}</p>
                        <p className="m-0">{state.date && `${state.date}`}</p>
                        <p className="m-0">{state.time && `${state.time}`}</p>
                        <p className="m-0">{state.client && `${t('calendar.customer')}: ${state.client}`}</p>
                        <p className="m-0">{state.barber && `${t('userBarbersComponent.barber')}: ${state.barber}`}</p>
                        <p className="m-0">{state.paymentMethod && `Payment method: ${state.paymentMethod}`}</p>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default AppointmentStatus;