import { useTranslation } from "react-i18next";
import HandlerApp from "../../../../utils/handlerApp";
import { useFetch } from "../../../../hooks/useFecth";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import DataTable from "react-data-table-component";


const TableCompanies = ({data, endpoint, permissions, prefix, setRefresh})=>{
    const [t]                       = useTranslation("global");
    const handlerApp                = HandlerApp();
    const api                       = useFetch();
    const nav                       = useNavigate();

    const columns = [
        {
            id: 'company_name',
            name: t('companyComponent.company'),
            selector: row => row.company_name,
            sortable: true
        },
        {
            id: 'company_identification',
            name: t('companyComponent.identification'),
            selector: row => row.company_identification,
            sortable: true
        },
        {
            id: 'company_phone',
            name: t('phone'),
            selector: row => row.company_phone,
            sortable: true
        },
        {
            id: 'company_active',
            name: t('active'),
            width: "100px",
            cell: (row) => { return handlerApp.ChipActive(row.company_active) },
            sortable: true
        },
        {
            name: t("action"),
            cell:(row) => { return handlerApp.actions2(row,permissions,prefix,handlerAction)},
            ignoreRowClick: true,
            width: "120px"
        }
    ];

    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

    const filteredItems = data?.filter(
        item => item.company_id && item.company_id.toString().toLowerCase().includes(filterText.toLowerCase()) 
            || item.company_name.toLowerCase().includes(filterText.toLowerCase())
            || item.company_phone?.toLowerCase().includes(filterText.toLowerCase())
            || item.company_identification?.toLowerCase().includes(filterText.toLowerCase())
    );

    // Manejador de eventos.
    const handlerAction=(id, action)=>{
        switch(action){
            case 'create':
                nav("/"+endpoint+"/create");
            break;
            case 'view':
                nav("/"+endpoint+"/view/"+id);
            break;
            case 'update':
                nav("/"+endpoint+"/edit/"+id);
            break;
            case 'delete':
                deleteRow(id, action);
            break;
            case 'inactive':
                optionRequest(id, action);
            break;
            case 'active':
                optionRequest(id, action);
            break;
            case 'refresh':
                setRefresh();
            break;
            default:
            break;
        }
    }

    const deleteRow= (id)=>{
        handlerApp.setLoader(true);
        api.deleteMethod(endpoint+'/'+id)
        .then(response =>{
            if(response.success){
                handlerApp.showOk(response);
                setRefresh();
            }else{
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error =>{
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const optionRequest = (id,action)=>{
        let body = {
            action: 'updateStatus',
            status: (action === 'active')? 1:0
        }
        handlerApp.setLoader(true);
        api.put(endpoint+"/"+id,body)
        .then(response => {
            if(response.success){
                handlerApp.showOk(response);
                setRefresh();
            }else{
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    return(
        <>
            <section className="filters">
                <h4>
                    {t('companyComponent.listCompanies')}
                    <span className="counter-data-table">{data.length} {(data.length>1) ? t('companyComponent.companies') : t('companyComponent.company') }</span>
                </h4>
                <div className="d-flex justify-content-end">
                    <div className="d-flex col-md-3">
                        <div className="input-icon input-search bg-transparent">
                            <input type="text" onChange={e => setFilterText(e.target.value)} value={filterText} placeholder={t("searchIn")}></input>
                            {filterText !== "" && <span className="material-symbols-outlined clear" onClick={e=>{setFilterText(""); setResetPaginationToggle(!resetPaginationToggle)}}>cancel</span>}
                            <span className="material-symbols-outlined">search</span>
                        </div>
                        <div className="col-md-3 d-flex justify-content-end">
                            <div className="btn-table-actions">
                                <button className="btn btn-primary-yellow-1" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    {t('search')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <DataTable
                columns={columns}
                data={filteredItems}
                pagination
                paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                persistTableHead
            />
        </>
    )
}
export default TableCompanies;