import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import './BodyReportAttitudModal.css';

const CustomSelect = ({ value, onChange }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [t] = useTranslation("global");

    const options = [
        { value: "Annoying", label: t('customers.view.annoying'), icon: "fa-regular fa-face-angry" },
        { value: "Dificult", label: t('customers.view.dificult'), icon: "fa-regular fa-face-meh" },
        { value: "Rude", label: t('customers.view.rude'), icon: "fa-regular fa-face-zipper" },
        { value: "Violent", label: t('customers.view.violent'), icon: "fa-light fa-face-eyes-xmarks" }
    ];

    const handleSelect = (optionValue) => {
        onChange(optionValue);
        setIsOpen(false);
    };

    const selectedOption = options.find(opt => opt.value === value);

    return (
        <div className="custom-select">
            <div className="custom-select-trigger" onClick={() => setIsOpen(!isOpen)}>
                {selectedOption ? (
                    <>
                        <i className={selectedOption.icon}></i> {selectedOption.label}
                    </>
                ) : t('customers.view.selectAttitude')}
            </div>
            {isOpen && (
                <ul className="custom-options">
                    {options.map(option => (
                        <li key={option.value} onClick={() => handleSelect(option.value)}>
                            <i className={option.icon}></i> {option.label}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default CustomSelect;
