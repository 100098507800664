import HandlerApp from "../../utils/handlerApp";
import { NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
import { Logout } from "../../utils/BoldLogin";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const MainHeader = ({toggleAside, locations, handleLocation, locationLabel}) => {
    
    const logout                    = Logout();
    const handlerApp                = HandlerApp();
    const nav                       = useNavigate();
    const [t, i18n]                 = useTranslation("global");

    const [language,setLanguage]    = useState("");
    
    useEffect(()=>{
        if(localStorage.getItem("language")){
            i18n.changeLanguage(localStorage.getItem("language"))
            setLanguage(localStorage.getItem("language"));
        }
    },[])

    useEffect(()=>{
        if(language){
            i18n.changeLanguage(language)
            localStorage.setItem('language',language);
        }
    },[language])

    const handleLogout = () => {
        logout.closeSession();
    }
    const handleAccount =()=>{
        let user = handlerApp.getUser();
        nav('/configuration/profile/'+user);
    }
    const handleDarkMode = ()=>{
        let html = document.querySelector("html");
        if(html.classList.contains("dark")){
            localStorage.setItem("theme", "light");
        }else{
            localStorage.setItem("theme", "dark");
        }
        html.classList.toggle("dark");
    }
    return (
        <div className="container-menu d-flex align-items-center justify-content-between">
            <div className="d-flex">
                <span onClick={toggleAside} className="material-symbols-outlined" role="button">menu</span>
                <div className="title-dashboard">{t('home')}</div>
            </div>
            <div className="actions-dashboard">
                <div className="btn-action-dash dropdown">
                    <button className="btn-action-dash dropdown-toggle btn-location" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <span className="location-label">{locationLabel}</span>
                    </button>
                    <ul className="dropdown-menu">
                        <li>
                            <button className="dropdown-item" onClick={()=>{handleLocation("")}}>Global</button>
                        </li>
                        {
                            locations.map(e=>{
                                return (<li key={e.location_id}>
                                    <button className="dropdown-item" onClick={()=>{handleLocation(e.location_id)}}>{e.location_name}</button>
                                </li>)
                            })
                        }
                    </ul>
                </div>
                <button className="btn-action-dash">
                    <i className="fa-regular fa-bell"></i>
                </button>
                <div className="btn-action-dash dropdown">
                    <button className="btn-action-dash dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <i className="fa-regular fa-globe"></i>
                    </button>
                    <ul className="dropdown-menu">
                        <li>
                            <button className={language === "en" ? 'dropdown-item selected': 'dropdown-item'} onClick={()=>{setLanguage("en")}}>EN</button>
                        </li>
                        <li>
                            <button className={language === "es" ? 'dropdown-item selected': 'dropdown-item'} onClick={()=>{setLanguage("es")}}>ES</button>
                        </li>
                    </ul>
                </div>
                <div className="btn-action-dash dropdown">
                    <button className="btn-action-dash dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <i className="fa-regular fa-circle-user"></i>
                    </button>
                    <ul className="dropdown-menu">
                        <li>
                            <NavLink to="/">
                                <button  className="dropdown-item">{t('home')}</button>
                            </NavLink>
                            <button className="dropdown-item dropdown-item-icon-left" onClick={handleDarkMode}>
                                <i className="fa-regular fa-moon"></i>
                                <span>{t("darkMode")}</span>
                            </button>
                            <button className="dropdown-item" onClick={handleAccount}>{t("myAccount")}</button>
                            <button className="dropdown-item" onClick={handleLogout}>{t("logout")}</button>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}
export default MainHeader;