import React, { useContext, useEffect, useState } from "react";
import HandlerApp from "../../../../utils/handlerApp";
import CommissionScreen from "./CommissionsScreen.css"
import { set, useForm } from "react-hook-form";
import { useFetch } from "../../../../hooks/useFecth";
import { AppContext } from "../../../../context/AppContext";
import { useTranslation } from "react-i18next";
import { Checkbox, Divider } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

const CommissionsScreen = ()=>{
    const { register, handleSubmit, formState: { errors }, setValue, control } = useForm()

    const [t]           = useTranslation("global");
    const api           = useFetch();
    const handlerApp    = HandlerApp();
    const nav           = useNavigate();

    const endpoint      = "commissions";
    const params        = useParams();

    const {app}                                 = useContext(AppContext);
    const [data, setData]                       = useState([]);
    const [permissions, setPermissions]         = useState([]);
    const [selectedValue, setSelectedValue]     = useState([]);
    const [id, setId]                           = useState();


    const [form, setForm] = useState({
        settingcomission_deduct_discounts: 0,
        settingcomission_deduct_taxes: 0,
        settingcomission_deduct_service_cost: 0,
        settingcomission_deduct_product_cost: 0
    });

    useEffect(() => {
        sendRequest();
    }, [])

    const sendRequest = () => {
        handlerApp.setLoader(true);
        api.get(endpoint)
        .then(response => {
            if (response.success) {
                let data = response.data[0];
                setData(data);
                setId(data.settingcomission_id);
                filterData(data)
                handlerApp.setLoader(false);
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const filterData = (data) => {
        let f = handlerApp.filterDataForm(data, form, setValue);
        setForm(f);
    }

    const handleSubmitForm = (data) => {
        const formData = {
            ...data,
            settingcomission_deduct_discounts: data.settingcomission_deduct_discounts ? 1 : 0,
            settingcomission_deduct_taxes: data.settingcomission_deduct_taxes ? 1 : 0,
            settingcomission_deduct_service_cost: data.settingcomission_deduct_service_cost ? 1 : 0,
            settingcomission_deduct_product_cost: data.settingcomission_deduct_product_cost ? 1 : 0,
          };
        handlerApp.setLoader(true);
        api.post(endpoint, formData)
        .then(response => {
            if (response.success) {
                handlerApp.showOk(response);
                handlerApp.setLoader(false);
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }

        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const handleUpdateForm = (data) => {
        const formData = {
            ...data,
            settingcomission_deduct_discounts: data.settingcomission_deduct_discounts ? 1 : 0,
            settingcomission_deduct_taxes: data.settingcomission_deduct_taxes ? 1 : 0,
            settingcomission_deduct_service_cost: data.settingcomission_deduct_service_cost ? 1 : 0,
            settingcomission_deduct_product_cost: data.settingcomission_deduct_product_cost ? 1 : 0,
        };
        handlerApp.setLoader(true);
        api.put(endpoint + "/" + id, formData)
        .then(response => {
            if (response.success) {
                handlerApp.showOk(response);
                handlerApp.setLoader(false);
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    return(
        <form onSubmit={handleSubmit((id === undefined) ? handleSubmitForm : handleUpdateForm)}>
            <div className="app container">
                <div className="d-flex align-items-center m-4">
                    <i className="textCommissions marginless fa-solid fa-arrow-left pb-3 pt-2 px-2" role="button" onClick={()=>{nav(-1)}}></i>
                    <h3 className="mx-3">{t('commission.commissions')}</h3>
                </div>
                <p className="textCommissions ms-4">{t('commission.commissionsDetails')}</p>
                
                <Divider />
                <div className="d-flex justify-content-between m-4">
                    <div className="col-lg-6 col-md-12 col-sm-12  commissionsContainer border border-1 border-solid rounded">
                        <div className="col-12 m-3">
                            <p className="textCommissions m-0">
                                <Checkbox
                                    size="medium"
                                    {...register("settingcomission_deduct_discounts")}
                                    checked={form.settingcomission_deduct_discounts }
                                    onChange={(e)=>{
                                        console.log(e.target.checked)
                                        setForm({
                                            ...form,
                                            settingcomission_deduct_discounts: e.target.checked
                                        })}}
                                />
                                {t('commission.deductDiscounts')}
                                <br />
                            </p>
                            <p className="textCommissions col-10">{t('commission.deductDiscountsDescription')}</p>
                        </div>
                        <div className="col-12 m-3">
                            <p className="textCommissions m-0">
                                <Checkbox
                                    size="medium"
                                    {...register("settingcomission_deduct_taxes")}
                                    checked={form.settingcomission_deduct_taxes }
                                    onChange={(e)=>{
                                        console.log(e.target.checked)
                                        setForm({
                                            ...form,
                                            settingcomission_deduct_taxes: e.target.checked
                                        })}}
                                />
                                {t('commission.deductTaxes')}
                                <br />
                            </p>
                            <p className="textCommissions col-10">{t('commission.deductTaxesDescription')}</p>
                        </div>
                        <div className="col-12 m-3">
                            <p className="textCommissions m-0">
                                <Checkbox
                                    size="medium"
                                    {...register("settingcomission_deduct_service_cost")}
                                    checked={form.settingcomission_deduct_service_cost }
                                    onChange={(e)=>{
                                        console.log(e.target.checked)
                                        setForm({
                                            ...form,
                                            settingcomission_deduct_service_cost: e.target.checked
                                        })}}
                                />
                                {t('commission.deductServiceCost')}
                                <br />
                            </p>
                            <p className="textCommissions col-10">{t('commission.deductServiceDescription')}</p>
                        </div>
                        <div className="col-12 m-3">
                            <o className="textCommissions m-0">
                                <Checkbox
                                    size="medium"
                                    {...register("settingcomission_deduct_product_cost")}
                                    checked={form.settingcomission_deduct_product_cost }
                                    onChange={(e)=>{
                                        console.log(e.target.checked)
                                        setForm({
                                            ...form,
                                            settingcomission_deduct_product_cost: e.target.checked
                                        })}}
                                />
                                {t('commission.deductProductCost')}
                                <br />
                            </o>
                            <p className="textCommissions col-10">{t('commission.deductProductDescription')}</p>
                        </div>
                    </div>
                </div>
                <Divider />
                <div className="row d-flex justify-content-end">
                    <div className="d-flex justify-content-end mt-3">
                        <button className="btn btn-primary-yellow-1 col-1" type="submit">Save</button>
                    </div>
                </div>
            </div>
        </form>
    )
}

export default CommissionsScreen;