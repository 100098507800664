import React from 'react';
import "./ViewMultiField.css";


const MultilineText = ({ label, value }) => {
  return(
    <div className="multifield-view">
        <span className="label-multifield">{label}</span>
        <p className="value-multifield col-6" row={3}>{value}</p>
    </div>
)
};

export default MultilineText;