import "./InputPhone.css";
import { TextField } from "@mui/material"
import { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

/**
 * @author Daniel Bolivar - Github debb94 - daniel.bolivar.freelance@gmail.com
 * @since 2024-08-15
 * @version 2.0
 * @param name Nombre de campo - key
 * @param label Etiqueta a mostrar del campo 
 * @param register register de react-hook-form
 * @param errors manejador de errores
 * @param control manejador de formulario
 * @param onChange callback - funcion ejecutada por los cambios en el componenete, retorna (value, name)
 * @param value - valor por defecto del componente
 * @param setValue - Funcion para setear el valor del componente en el formulario en memoria
 */
const InputPhone = ({name, label, register, errors, control, value, setValue})=>{
    const [t] = useTranslation("global");
    const [openOptions,setOpenOptions] = useState(false);
    const [country, setCountry] = useState("+1");
    const [image, setImage] = useState();

    const options = [
        {
            value: "+1",
            label: "Estados Unidos",
            image: "/assets/countries/Estados-Unidos.png"
        },
        {
            value: "+57",
            label: "Colombia",
            image: "/assets/countries/Colombia.png"
        },
        {
            value: "+58",
            label: "Venezuela",
            image: "/assets/countries/Venezuela.png"
        },
    ]

    useEffect(()=>{
        if(country){
            setterImage(country);
            if(value?.length > 1){
                if(value.substring(0,2) === "+1"){
                    setValue(name,country+value.substring(2));
                }
                if(value.substring(0,2) !== "+1"){
                    setValue(name,country+value.substring(3));
                }
            }else{
                setValue(name,country);
            }
        }
    },[country])

    useEffect(()=>{
        if(value !== undefined && value !== null){
            if(value.length > 0){
                if(value.substring(0,2) === "+1"){
                    setterImage("+1");
                    setCountry("+1");
                    setValue(name, value.substring(2));
                }
                if(value.substring(0,2) !== "+1"){
                    setterImage(value?.substring(0,3));
                    setCountry(value?.substring(0,3));
                    setValue(name, value.substring(3));
                }
            }
        }
    },[value])

    const setterImage = (prefix)=>{
        let img = options.filter(e=> e.value == prefix);
        setImage(img[0].image);
    }

    return(
            <>
            <div className="container-bold-input-phone">
                <Controller
                    control={control}
                    name={name}
                    render={({ field: { onChange, onBlur, value } }) => {
                        return (
                            <>
                                <div className="container-bold-selector">
                                    <div className="bold-selector">
                                        <div className="bold-select-selected" onClick={()=>{setOpenOptions(!openOptions)}}>
                                            <img src={image}/>
                                            <div style={{paddingTop: 5}}>
                                                { (!openOptions) ? <ExpandMoreIcon/> : <ExpandLessIcon/>}
                                            </div>
                                        </div>
                                        <div className={ (openOptions) ? "bold-selector-options show" : "bold-selector-options"}>
                                            {options.map( (e,index) =>{
                                                return <div className="bold-selector-option" key={index} onClick={()=>{
                                                    setCountry(e.value);
                                                    setOpenOptions(!openOptions);
                                                }}>
                                                    <img src={e.image}/>
                                                    <label>{e.label}</label>
                                                </div>
                                            })}
                                        </div>
                                    </div>
                                </div>
                                <TextField
                                    className="text-view bold-input-phone"
                                    label={label}
                                    variant="outlined"
                                    {...register(name)}
                                    onChange={(e) => {
                                        const newValue = e.target.value;
                                        let len = newValue.length;
                                        if (len >= country.length && /^\+[1-9]{1,2}\d{0,10}$/.test(newValue)) {
                                            onChange(newValue);
                                        }
                                    }}
                                    size="small"
                                    value={value || ''}
                                    InputLabelProps={{
                                        shrink: Boolean(value) || Boolean(value === 0) // Esto mueve el label hacia arriba si hay un valor en el campo
                                    }}
                                    error={Boolean(errors[name])}
                                    helperText={
                                        errors[name]?.type === 'required' ? t("errors.required") :
                                        errors[name]?.type === 'maxLength' ? t("errors.maxLength") : ""
                                    }
                                />
                            </>
                        )
                    }}
                />
            </div>
            </>
    )
}

export default InputPhone;