import './NavLinkView.css';
import React from 'react';
import { NavLink } from "react-router-dom";

const NavLinkView = ({actions,active}) => {

    return (
        <section className="section_nav-header">
            <nav className="child-menu">
                { actions?.map((x,i) =>{
                    return(
                        <NavLink key={i} className={`${active  === i? 'active-top-nav-link-view' : ''}`} to={x.to} >
                            {x.name}
                        </NavLink>  
                    )                  
                })}
            </nav>
        </section>
    );
};



export default NavLinkView;