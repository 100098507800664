import HandlerApp from "../../../../utils/handlerApp";
import HeaderEditScreen from "../../HeaderScreen/HeaderEditScreen";
import InputText from "../../componentes/Inputs/InputText/InputText";
import InputPhone from "../../componentes/Inputs/InputPhone/InputPhone";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFetch } from "../../../../hooks/useFecth";
import { useNavigate, useParams } from "react-router-dom";

const CompaniesForm = ()=>{
    const endpoint              = "companies";
    const [t]                   = useTranslation("global");
    const title                 = t('companyComponent.createCompany');
    const api                   = useFetch();
    const handlerApp            = HandlerApp();
    const nav                   = useNavigate();

    const params                = useParams();
    const id                    = params?.id;

    const { register, handleSubmit, formState: { errors }, setValue, control } = useForm()

    const [formData, setFormData] = useState({
        company_name                : "",
        company_short_name          : "",
        company_identification      : "",
        company_website             : "",
        company_phone               : "",
        company_ext                 : "",
        company_barbers             : ""
    });

    useEffect(() => {
        sendRequest();
    }, [])

    const sendRequest = () => {
        handlerApp.setLoader(true);
        api.get(`${endpoint}/${id}`)
        .then(response => {
            if (response.success) {
                let data = response.data[0];
                filterData(data);
                handlerApp.setLoader(false);
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const filterData = (data) => {
        handlerApp.filterDataForm(data, formData, setValue);
    }

     // save data
     const handleSubmitForm = (data) => {
        handlerApp.setLoader(true);
        api.post(endpoint, data)
        .then(response => {
            if (response.success) {
                handlerApp.showOk(response);
                handlerApp.setLoader(false);
                nav("/" + endpoint);
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const handleUpdateForm = (data) => {
        handlerApp.setLoader(true);
        api.put(endpoint + "/" + id, data)
        .then(response => {
            if (response.success) {
                handlerApp.showOk(response);
                handlerApp.setLoader(false);
                nav("/" + endpoint);
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    return(
        <div className="app container">
            <HeaderEditScreen title={title}/>
            <div className="section-forms-location mt-4">
                <form onSubmit={handleSubmit((id === undefined) ? handleSubmitForm : handleUpdateForm)}>
                    <section className="formularios row np-row">
                        <div className="col-md-6">
                            <div className="col-md-12 mb-2">
                                <InputText
                                    label={t("companyComponent.legalName")}
                                    name="company_name"
                                    errors={errors}
                                    rules={{ required: true, maxLength: 80 }}
                                    register={register}
                                    control={control}
                                />
                            </div>
                            <div className="col-md-12 mb-2">
                                <InputText
                                    label={t("companyComponent.shortName")}
                                    name="company_short_name"
                                    errors={errors}
                                    rules={{ required: true, maxLength: 120 }}
                                    register={register}
                                    control={control}
                                />
                            </div>
                            <div className="col-md-12 mb-2">
                                <InputText
                                    label={t("companyComponent.taxId")}
                                    name="company_identification"
                                    errors={errors}
                                    rules={{ required: true, maxLength: 30 }}
                                    register={register}
                                    control={control}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">    
                            <div className="col-md-12 mb-2">
                                <InputPhone
                                    label={t("phone")}
                                    name="company_phone"
                                    value={formData.company_phone}
                                    setValue={setValue}
                                    register={register}
                                    errors={errors}
                                    control={control}
                                    onChange={(e)=>{console.log(e)}}
                                />
                            </div>
                            <div className="col-md-12 mb-2">
                                {/* TODO:  cambiar por input de numero */}
                                <InputText
                                    label={t("companyComponent.companyBarbers")}
                                    name="company_barbers"
                                    errors={errors}
                                    rules={{ required: true, maxLength: 30 }}
                                    register={register}
                                    control={control}
                                />
                            </div>
                        </div>
                    </section>
                    <div className="row d-flex justify-content-end">
                        <div className="d-flex justify-content-end me-5">
                            {id === undefined && <button className="btn btn-primary-yellow-1" type="submit">{t('create')}</button>}
                            {id && <button className="btn btn-primary-yellow-1" type="submit">{t('update')}</button>}
                        </div>
                     </div>
                    
                </form>
            </div>
        </div>
    )
}

export default CompaniesForm;