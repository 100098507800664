import './CustomersImportInside.css'
import env from "../../../../env-local";
import ButtonLink from '../../../atoms/Buttons/ButtonLink';
import UploadFile from '../../../../utils/UploadFile/UploadFile';
import ButtonOutline from '../../../atoms/Buttons/ButtonOutline';
import ButtonPrimary from '../../../atoms/Buttons/ButtonPrimary';
import CreatingUsers from '../../../../assets/img/creatingUsers.svg'
import UploadFileCard from '../../../atoms/Cards/CardUploadFile/UploadFileCard';
import { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Step, StepButton, StepLabel, Stepper } from '@mui/material';

const CustomersImportInside = () => {

    const [t] = useTranslation('global');
    const nav = useNavigate()

    const [file, setFile]               = useState(null);
    const [completed, setCompleted]     = useState({});
    const [activeStep, setActiveStep]   = useState(0);
    const [stateScreen, setStateScreen] = useState(0); //Estado del segundo paso: 0 = cargue, 1 = cargue exitoso, 2 = cargue fallido
    const [uploadFile, setUploadFile]   = useState(null);

    const handleStep = (step) => {
        completedStep(step)
        setActiveStep(step);
    }

    const completedStep = (index, value) => {
        if (value === null) {
            setCompleted({ ...completed, [index]: true })
        } else {
            setCompleted({ ...completed, [index]: value })
        }
    }

    const CustomStepIcon = ({ active, completed, icon }) => {
        return (
            <div className='stepper-div' style={{
                height: 30,
                color: completed ? '#02B15A' : active ? '#0252B0' : '#000000',
                backgroundColor: completed ? '#D4F2E3' : active ? '#D1D9E3' : '#EEEEEE',
            }}>
                {
                    completed ? 
                        <i className="fa-light fa-check" color='#02B15A'></i> 
                    : 
                    active ?
                        <span className='stepper-text min-width'>{`${t('step')} ${icon}`}</span>
                    :
                        <span className='stepper-text'>{icon}</span>

                }
            </div>
        );
    };

    const HandleDowloadInstructive = () => {
        //TODO: Crear la funcion de descarga del instructivo o enlace para ir a instructivo.
    }

    const HandleDowloadTemplate = () => {
        //TODO: Crear la funcion de descarga de la plantilla
        let b = document.querySelector(".app.container");
        let element = document.createElement("a");
        element.href=`${env.urlBackend}/templates/customer.xlsx`;
        element.className="d-none";
        b.appendChild(element);
        element.click();
    }

    const HandleDonwloadErrors = () => {
        //TODO: Crear la funcion de descarga del reporte de errores
    }
    

    const handleUploadFile = (file) => {
        if(file !== null){
            setStateScreen(1);
            setUploadFile(file);
            setFile({
                filename: file.name,
                size: file.size/1024
            });
        }
    }

    const deleteFile = ()=>{
        setFile(null);
        setUploadFile(null);
    }

    const uploadFileToBackend = async ()=>{
        const formData = new FormData();
        formData.append('file', uploadFile);
        try {
            const response = await fetch(`${env.urlBackend}/customers/massive/upload-customers`, {
                method: 'POST',
                body: formData,
                headers: {
                    'Authorization': localStorage.getItem('token'),
                    'Company': localStorage.getItem('company'),
                    'Location': localStorage.getItem('location'),
                    'Profile': localStorage.getItem('profile')
                },
            });
            const result = await response.json();
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    }

    return (
        <div className="app container">
            {/* <!-- titulo --> */}
            <div className="d-flex justify-content-between">
                <section className="section-title-page">
                    <h3 className="title-page">{t('customers.clientsImport.title')}</h3>
                </section>
                {
                    activeStep <= 2 &&
                    <ButtonLink
                        onClick={() => nav(-1)}
                        label={<section><i className="fa-solid fa-x me-2"></i>{t("cancel")}</section>} 
                    />
                }
            </div>
            <section className='container-box'>
                <div className='container-content'>
                    <Stepper className="mb-4 stepper" activeStep={activeStep} alternativeLabel>
                        <Step key="Step 1" completed={completed[0]}>
                            <StepButton className="stepButton" color="inherit" onClick={() => { handleStep(0) }}>
                                <StepLabel StepIconComponent={CustomStepIcon}></StepLabel>
                            </StepButton>
                        </Step>
                        <Step key="Step 2" completed={completed[1]}>
                            <StepButton className="stepButton" color="inherit" onClick={() => { handleStep(1) }}>
                                <StepLabel StepIconComponent={CustomStepIcon}></StepLabel>
                            </StepButton>
                        </Step>
                        <Step key="Step 3">
                            <StepButton className="stepButton" color="inherit" onClick={() => { handleStep(2) }}>
                                <StepLabel StepIconComponent={CustomStepIcon}></StepLabel>
                            </StepButton>
                        </Step>
                    </Stepper>
                    {activeStep == 0 &&
                        <div>
                            <h1 className='title-screen color-text'>{t('customers.clientsImport.downloadTemplate')}</h1>
                            <p className='text-screen color-text'>{t('customers.clientsImport.downloadTemplateText')}</p>
                            <section className='d-flex gap-4'>
                                <ButtonOutline
                                    label={<section><i className="fa-regular fa-person-chalkboard me-2"></i>{t("customers.clientsImport.instructions")}</section>}
                                    onClick={HandleDowloadInstructive} />
                                <ButtonPrimary
                                    label={<section><i className="fa-solid fa-file-arrow-down me-2"></i>{t("customers.clientsImport.downloadTemplate")}</section>}
                                    onClick={HandleDowloadTemplate} />
                            </section>
                        </div>
                    }
                    {activeStep == 1 &&
                        <div>
                            {
                            (file === null) ?
                                <div>
                                    <h1 className='title-screen color-text'>{t('customers.clientsImport.uploadTemplate')}</h1>
                                    <p className='text-screen color-text'>{t('customers.clientsImport.uploadTemplateText')}</p>
                                    <div className="col-md-12">
                                        <UploadFile returnFiles={handleUploadFile} multiple={false} accepts=".xlsx, .xls"/>
                                    </div>
                                </div>
                            :
                                <div>
                                    <h1 className='title-screen color-text'>{t('customers.clientsImport.uploadTemplate')}</h1>
                                    <p className='text-screen color-text'>{t('customers.clientsImport.uploadTemplateText')}</p>
                                        <div className="col-md-12 mb-2">
                                            {/* TODO: Poner los datos del archivo real */}
                                            <UploadFileCard
                                                title={file.filename}
                                                subtitle={`Uploaded Document (${file.size.toFixed(2)} Kb)`}
                                                functionOnClick={deleteFile} />
                                        </div>
                                </div>
                            }
                            {/* si hay error */}
                            {
                                stateScreen == 2 &&
                                <div>
                                    <h1 className='title-screen color-text'>{t('customers.clientsImport.checkInfo')}</h1>
                                    <section className='d-flex'>
                                        <div className='container-failed me-3 mt-2'>
                                            <i className="fa-solid fa-xmark"></i>
                                        </div>
                                        <p className='text-screen color-text'>{t('customers.clientsImport.checkInfoText')}</p>
                                    </section>
                                    <section className='d-flex gap-4'>
                                        <ButtonOutline
                                            label={<section><i className="fa-regular fa-person-chalkboard me-2"></i>{t("customers.clientsImport.instructions")}</section>}
                                            onClick={HandleDowloadInstructive} />
                                        <ButtonPrimary
                                            label={<section><i className="fa-solid fa-file-arrow-down me-2"></i>{t("customers.clientsImport.downloadErrors")}</section>}
                                            onClick={HandleDonwloadErrors} />
                                    </section>
                                </div>
                            }
                        </div>
                    }
                    {activeStep == 2 &&
                        <div>
                            <h1 className='title-screen color-text'>{t('customers.clientsImport.creatingUsers')}</h1>
                            <p className='text-screen color-text'>{t('customers.clientsImport.creatingUsersText')}</p>
                            <img src={CreatingUsers} className='image-create-users'/>
                        </div>
                    }
                </div>
            </section>
            <footer className='footer-buttons'>
                <span className='container-button'>
                    {
                        activeStep >= 1 &&
                        <ButtonLink
                            onClick={() => handleStep(activeStep - 1)}
                            label={<section><i className="fa-solid fa-arrow-left me-2"></i>{t("back")}</section>} 
                        />
                    }
                </span>
                <section className='container-button'>
                    { 
                        activeStep == 0 &&
                        <ButtonPrimary
                            label={t('next')}
                            onClick={() =>{
                                handleStep(activeStep+1);
                            }}
                        />
                    }
                    { 
                        activeStep == 1 &&
                        <ButtonPrimary
                            label={t('customers.clientsImport.createClients')}
                            onClick={() =>{
                                handleStep(activeStep+1);
                                uploadFileToBackend();
                            }}
                        />
                    }
                    { 
                        activeStep == 2 &&
                        <ButtonPrimary
                            label={t('exit')}
                            onClick={() =>{
                                nav("/customers");
                            }}
                        />
                    }
                </section>
            </footer>
        </div>
    )
}

export default CustomersImportInside
