import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import './settingsScreen.css';
import { useEffect, useState } from "react";
import BoldSelectValidated from "../../../../utils/BoldSelectValidated";
import { useForm } from "react-hook-form";
import ButtonPrimary from "../../../atoms/Buttons/ButtonPrimary";

/**
 * @author Vicente Bolivar
 * @version 1.0.0
 * @param description pantalla de settings
*/

const SettingsScreen = () => {
    const [t] = useTranslation("global");
    const title = t('settings.settings');
    const [profile, setProfile] = useState(null);
    const [selectedValue, setSelectedValue] = useState([]);
    const [timeFormat, setTimeFormat]   = useState([]);
    const { register, handleSubmit, formState: { errors }, setValue, control } = useForm()

    
    useEffect(() => {
        const storedProfile = localStorage.getItem('profile');
        if (storedProfile) {
            setProfile(parseInt(storedProfile, 10));
        }
    }, []);

    const handleSelectChange = (value) => {
        setSelectedValue(value);
    };

    return (
        <div className="app container">
            <div className="d-flex justify-content-between">
                <section className="section-title-page">
                    <h3 className="title-page">{title}</h3>
                </section>
            </div>
            <div className="row mt-4">
                {profile === 1 || profile === 2 ? (
                    <>
                        <div className="col-md-6">
                            <div className="settings-section border p-3 mb-3">
                                <h5>{t('settings.accountSetup')}</h5>
                                <NavLink to="/settings/business-details" className="no-link-style">
                                    <div className="settings-option">
                                        <div className="option-text-settings">
                                            <span>{t('settings.businessDetails')}</span>
                                            <p className="settingsDescription">{t('settings.businessDetailsDescription')}</p>
                                        </div>
                                            <i className="fa-regular fa-chevron-right me-3 ms-2 chevronColor"></i>
                                    </div>
                                </NavLink>
                                <hr />
                                <NavLink to="/settings/billing-details" className="no-link-style">
                                    <div className="settings-option">
                                        <div className="option-text-settings">
                                            <span>{t('settings.billingDetails')}</span>
                                            <p className="settingsDescription">{t('settings.billingDetailsDescription')}</p>
                                        </div>
                                            <i className="fa-regular fa-chevron-right me-3 ms-2 chevronColor"></i>
                                    </div>
                                </NavLink>
                                <hr />
                                <NavLink to="/online-booking" className="no-link-style">
                                    <div className="settings-option">
                                        <div className="option-text-settings">
                                            <span>{t('settings.onlineBooking')}</span>
                                            <p className="settingsDescription">{t('settings.onlineBookingDescription')}</p>
                                        </div>
                                            <i className="fa-regular fa-chevron-right me-3 ms-2 chevronColor"></i>
                                    </div>
                                </NavLink>
                                <hr />
                                <NavLink to="/business-closed" className="no-link-style">
                                    <div className="settings-option">
                                        <div className="option-text-settings">
                                            <span>{t('settings.businessClosed')}</span>
                                            <p className="settingsDescription">{t('settings.businessClosedDescription')}</p>
                                        </div>
                                            <i className="fa-regular fa-chevron-right me-3 ms-2 chevronColor"></i>
                                    </div>
                                </NavLink>

                                <hr />
                                <NavLink to="/blocked-time" className="no-link-style">
                                    <div className="settings-option">
                                        <div className="option-text-settings">
                                            <span>{t('settings.blockedTime')}</span>
                                            <p className="settingsDescription">{t('settings.blockedTimeDescription')}</p>
                                        </div>
                                            <i className="fa-regular fa-chevron-right me-3 ms-2 chevronColor"></i>
                                    </div>
                                </NavLink>

                            </div>

                            <div className="settings-section border p-3 mb-3">
                                <h5>{t('settings.team')}</h5>
                                <NavLink to="/team-permission" className="no-link-style">
                                    <div className="settings-option">
                                        <div className="option-text-settings">
                                            <span>{t('settings.permission')}</span>
                                            <p className="settingsDescription">{t('settings.permissionDescription')}</p>
                                        </div>
                                            <i className="fa-regular fa-chevron-right me-3 ms-2 chevronColor"></i>
                                    </div>
                                </NavLink>

                                <hr />
                                <NavLink to="/team-commission" className="no-link-style">
                                    <div className="settings-option">
                                        <div className="option-text-settings">
                                            <span>{t('settings.payroll')}</span>
                                            <p className="settingsDescription">{t('settings.payrollDescription')}</p>
                                        </div>
                                            <i className="fa-regular fa-chevron-right me-3 ms-2 chevronColor"></i>
                                    </div>
                                </NavLink>

                                <hr />
                                <NavLink to="/team-commission" className="no-link-style">
                                    <div className="settings-option">
                                        <div className="option-text-settings">
                                            <span>{t('settings.commission')}</span>
                                            <p className="settingsDescription">{t('settings.commissionDescription')}</p>
                                        </div>
                                        
                                            <i className="fa-regular fa-chevron-right me-3 ms-2 chevronColor"></i>
                                    </div>
                                </NavLink>

                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="settings-section border p-3 mb-3">
                                <h5>{t('settings.sales')}</h5>
                                <NavLink to="/sales-taxes" className="no-link-style">
                                    <div className="settings-option">
                                        <div className="option-text-settings">
                                            <span>{t('settings.taxes')}</span>
                                            <p className="settingsDescription">{t('settings.taxesDescription')}</p>
                                        </div>
                                            <i className="fa-regular fa-chevron-right me-3 ms-2 chevronColor"></i>
                                    </div>
                                </NavLink>
                                <hr />
                                <NavLink to="/credit-card" className="no-link-style">
                                    <div className="settings-option">
                                        <div className="option-text-settings">
                                            <span>{t('settings.creditCard')}</span>
                                            <p className="settingsDescription">{t('settings.creditCardDescription')}</p>
                                        </div>
                                            <i className="fa-regular fa-chevron-right me-3 ms-2 chevronColor"></i>
                                    </div>
                                </NavLink>
                                <hr />
                                <NavLink to="/sales-tips" className="no-link-style">
                                    <div className="settings-option">
                                        <div className="option-text-settings">
                                            <span>{t('settings.tips')}</span>
                                            <p className="settingsDescription">{t('settings.tipsDescription')}</p>
                                        </div>
                                            <i className="fa-regular fa-chevron-right me-3 ms-2 chevronColor"></i>
                                    </div>
                                </NavLink>
                                <hr />
                                <NavLink to="/sales-pricing" className="no-link-style">
                                <div className="settings-option">
                                    <div className="option-text-settings">
                                        <span>{t('settings.pricing')}</span>
                                        <p className="settingsDescription">{t('settings.pricingDescription')}</p>
                                    </div>
                                        <i className="fa-regular fa-chevron-right me-3 ms-2 chevronColor"></i>
                                </div>
                                </NavLink>
                            </div>

                            <div className="settings-section border p-3 mb-3">
                                <h5>{t('settings.notifications')}</h5>
                                <NavLink to="/notifications-center" className="no-link-style">
                                    <div className="settings-option">
                                        <div className="option-text-settings">
                                            <span>{t('settings.notificationsCenter')}</span>
                                            <p className="settingsDescription">{t('settings.notificationsCenterDescription')}</p>
                                        </div>
                                            <i className="fa-regular fa-chevron-right me-3 ms-2 chevronColor"></i>
                                    </div>
                                </NavLink>
                            </div>

                            <div className="settings-section border p-3 mb-3">
                                <h5>{t('settings.clientSatisfaction')}</h5>
                                <NavLink to="/client-satisfaction" className="no-link-style">
                                    <div className="settings-option">
                                        <div className="option-text-settings">
                                            <span>{t('settings.clientSatisfactionMail')}</span>
                                            <p className="settingsDescription">{t('settings.clientSatisfactionMailDescription')}</p>
                                        </div>
                                            <i className="fa-regular fa-chevron-right me-3 ms-2 chevronColor"></i>
                                    </div>
                                </NavLink>
                            </div>
                        </div>
                    </>
                ) : profile === 3 ? (
                    <>
                        <div className="col-md-6">
                            <div className="settings-section border p-3 mb-3">
                                <h5>{t('settings.accountSetup')}</h5>
                                <NavLink to="/business-details" className="no-link-style">
                                    <div className="settings-option">
                                        <div className="option-text-settings">
                                            <span>{t('settings.businessDetails')}</span>
                                            <p className="settingsDescription">{t('settings.businessDetailsDescription')}</p>
                                        </div>
                                            <i className="fa-regular fa-chevron-right me-3 ms-2 chevronColor"></i>
                                    </div>
                                </NavLink>
                                <hr />
                                <NavLink to="/billing-details" className="no-link-style">
                                    <div className="settings-option">
                                        <div className="option-text-settings">
                                            <span>{t('settings.billingDetails')}</span>
                                            <p className="settingsDescription">{t('settings.billingDetailsDescription')}</p>
                                        </div>
                                            <i className="fa-regular fa-chevron-right me-3 ms-2 chevronColor"></i>
                                    </div>
                                </NavLink>
                                <hr />
                                <NavLink to="/online-booking" className="no-link-style">
                                    <div className="settings-option">
                                        <div className="option-text-settings">
                                            <span>{t('settings.onlineBooking')}</span>
                                            <p className="settingsDescription">{t('settings.onlineBookingDescription')}</p>
                                        </div>
                                            <i className="fa-regular fa-chevron-right me-3 ms-2 chevronColor"></i>
                                    </div>
                                </NavLink>
                                <hr />
                                <NavLink to="/business-closed" className="no-link-style">
                                    <div className="settings-option">
                                        <div className="option-text-settings">
                                            <span>{t('settings.businessClosed')}</span>
                                            <p className="settingsDescription">{t('settings.businessClosedDescription')}</p>
                                        </div>
                                            <i className="fa-regular fa-chevron-right me-3 ms-2 chevronColor"></i>
                                    </div>
                                </NavLink>

                                <hr />
                                <NavLink to="/blocked-time" className="no-link-style">
                                    <div className="settings-option">
                                        <div className="option-text-settings">
                                            <span>{t('settings.blockedTime')}</span>
                                            <p className="settingsDescription">{t('settings.blockedTimeDescription')}</p>
                                        </div>
                                            <i className="fa-regular fa-chevron-right me-3 ms-2 chevronColor"></i>
                                    </div>
                                </NavLink>

                            </div>

                            <div className="settings-section border p-3 mb-3">
                                <h5>{t('settings.team')}</h5>
                                <NavLink to="/team-permission" className="no-link-style">
                                    <div className="settings-option">
                                        <div className="option-text-settings">
                                            <span>{t('settings.permission')}</span>
                                            <p className="settingsDescription">{t('settings.permissionDescription')}</p>
                                        </div>
                                            <i className="fa-regular fa-chevron-right me-3 ms-2 chevronColor"></i>
                                    </div>
                                </NavLink>

                                <hr />
                                <NavLink to="/team-commission" className="no-link-style">
                                    <div className="settings-option">
                                        <div className="option-text-settings">
                                            <span>{t('settings.payroll')}</span>
                                            <p className="settingsDescription">{t('settings.payrollDescription')}</p>
                                        </div>
                                            <i className="fa-regular fa-chevron-right me-3 ms-2 chevronColor"></i>
                                    </div>
                                </NavLink>

                                <hr />
                                <NavLink to="/team-commission" className="no-link-style">
                                    <div className="settings-option">
                                        <div className="option-text-settings">
                                            <span>{t('settings.commission')}</span>
                                            <p className="settingsDescription">{t('settings.commissionDescription')}</p>
                                        </div>
                                        
                                            <i className="fa-regular fa-chevron-right me-3 ms-2 chevronColor"></i>
                                    </div>
                                </NavLink>

                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="settings-section border p-3 mb-3">
                                <h5>{t('settings.sales')}</h5>
                                <NavLink to="/sales-taxes" className="no-link-style">
                                    <div className="settings-option">
                                        <div className="option-text-settings">
                                            <span>{t('settings.taxes')}</span>
                                            <p className="settingsDescription">{t('settings.taxesDescription')}</p>
                                        </div>
                                            <i className="fa-regular fa-chevron-right me-3 ms-2 chevronColor"></i>
                                    </div>
                                </NavLink>
                                <hr />
                                <NavLink to="/credit-card" className="no-link-style">
                                    <div className="settings-option">
                                        <div className="option-text-settings">
                                            <span>{t('settings.creditCard')}</span>
                                            <p className="settingsDescription">{t('settings.creditCardDescription')}</p>
                                        </div>
                                            <i className="fa-regular fa-chevron-right me-3 ms-2 chevronColor"></i>
                                    </div>
                                </NavLink>
                                <hr />
                                <NavLink to="/sales-tips" className="no-link-style">
                                    <div className="settings-option">
                                        <div className="option-text-settings">
                                            <span>{t('settings.tips')}</span>
                                            <p className="settingsDescription">{t('settings.tipsDescription')}</p>
                                        </div>
                                            <i className="fa-regular fa-chevron-right me-3 ms-2 chevronColor"></i>
                                    </div>
                                </NavLink>
                                <hr />
                                <NavLink to="/sales-pricing" className="no-link-style">
                                <div className="settings-option">
                                    <div className="option-text-settings">
                                        <span>{t('settings.pricing')}</span>
                                        <p className="settingsDescription">{t('settings.pricingDescription')}</p>
                                    </div>
                                        <i className="fa-regular fa-chevron-right me-3 ms-2 chevronColor"></i>
                                </div>
                                </NavLink>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="d-flex align-items-center">
                                        <div className="option-text-settings">
                                            <span>{t('settings.timeFormat')}</span>
                                            <p className="settingsDescription">{t('settings.timeFormatDescription')}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 pt-3">
                                    <div className="settings-section border p-4 mb-3">
                                        <div>
                                            <BoldSelectValidated
                                                title={t('settings.timeFormat')} 
                                                name="time" 
                                                options={timeFormat}
                                                onChange={handleSelectChange}
                                                register={register}
                                                errors={errors}
                                                required={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="containerButtonSaveSettings">
                                <div className="withButtonSaveSettings">
                                    <ButtonPrimary
                                        label={t("save")}
                                        onClick={() => (console.log("guarda"))}
                                        type="submit"
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default SettingsScreen;